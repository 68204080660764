/** @format */

import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, take, takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';

import { Activity, ActivityLinkField, Process } from '@app/models';
import { ActivitiesService, PhaseCounts } from 'app/activities/activities.service';
import { TranslateService } from 'app/_services/translate.service';
import { RPCService } from 'app/_services/rpc.service';
import { V3ActivityViewContextService } from 'app/v3-activity/v3-activity-view-context.service';
import { stripUndefined } from 'app/_helpers/util';
import { ActivityFieldValue } from 'app/_models/v3-activity.model';
import { CoreService } from 'app/_services/core.service';

interface ActivityGroup {
    _id: string;
    name: string;
    activities: Activity[];
}

interface SearchFilter { targetWorkflowId: string; targetFieldId: string; sourceFieldValue?: ActivityFieldValue }

@Component({
    selector: 'app-activity-selector',
    templateUrl: './activity-selector.component.html',
    styleUrls: ['./activity-selector.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: { scope: 'activities-shared', alias: 'activities-shared' },
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivitySelectorComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('activitySelect', { static: true }) activitySelect: MatSelect;

    @Input() options: any;
    @Input() activity?: Activity;
    @Input() fieldId?: any;
    @Input() focused = false;
    @Input() selectAll: MatCheckboxChange;
    @Input() showDropDown: boolean;
    @Input() viewContext?: V3ActivityViewContextService;

    @Output() results = new EventEmitter<ActivityLinkField | ActivityLinkField[]>();
    @Output() clearActivity = new EventEmitter<boolean>();

    activityGroupsCtrl: UntypedFormControl = new UntypedFormControl();
    activityGroupsFilterCtrl: UntypedFormControl = new UntypedFormControl();
    filteredActivityGroups: ReplaySubject<ActivityGroup[]> = new ReplaySubject<ActivityGroup[]>(1);
    phaseCounts: PhaseCounts;
    activitySearchArray: any[] = [];
    processIds: any[] = [];
    processes: Process[] = [];
    selectedActivities: any[] = [];

    private onDestroy = new Subject<void>();

    constructor(
        private activitiesService: ActivitiesService,
        private cdr: ChangeDetectorRef,
        private translocoService: TranslocoService,
        private rpc: RPCService,
        private core: CoreService,
    ) {}

    ngOnInit() {
        const workspaceId = this.viewContext?.workflow.value?.cid || this.core.network.value?._id;
        if (!workspaceId) {
            console.error('Workspace id not defined!');
            return;
        }

        this.processes = Object.values(this.core.processes.value?.[workspaceId] || {});
        if (this.options.filterByProcess !== undefined) {
            const sourceList: string[] = this.options.filterByProcess;
            this.processes = this.processes.filter(process => {
                const index = sourceList.findIndex(processId => process._id === processId);
                return index > -1;
            });
        }

        void this.init();

        if (this.viewContext) {
            this.viewContext.fieldUpdated.pipe(takeUntil(this.onDestroy)).subscribe({
                next: fieldId => {
                    if (!this.viewContext) {
                        return;
                    }

                    const field = this.viewContext.template.value?.fields?.find(({ id }) => id === this.fieldId);
                    if (!field?.modifier?.activityFieldFilters?.length) {
                        return;
                    }

                    let update = false;
                    for (const { sourceFieldId } of field.modifier.activityFieldFilters) {
                        if (sourceFieldId !== fieldId) {
                            continue;
                        }

                        update = true;
                        break;
                    }

                    if (!update) {
                        return;
                    }

                    this.filterActivityGroups(this.activityGroupsFilterCtrl.value);
                },
            });
        }
    }

    ngAfterViewInit() {
        if (this.focused) {
            this.activitySelect.stateChanges.pipe(take(2)).subscribe({
                next: () => {
                    this.activitySelect.open();
                    if (this.focused) {
                        this.focused = false;
                    }

                    this.activityGroupsCtrl.markAsPristine();
                    this.activityGroupsCtrl.markAsTouched();
                    this.cdr.detectChanges();
                },
            });
        }
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    selectActivity(event: any) {
        const selected = event.options[0].selected;
        const selectedActivity = event.options[0].value;
        if (selected) {
            this.selectedActivities.push(selectedActivity);
        } else {
            this.selectedActivities.splice(
                this.selectedActivities.findIndex(activity => activity._id === selectedActivity._id),
                1
            );
        }
        this.results.emit([...this.selectedActivities]);
    }

    getProcessPhaseName(phaseId: string): string {
        let processPhaseName = '';
        this.processes.forEach(process => {
            if (process.phases[phaseId]) {
                processPhaseName = `${process.name} - ${process.phases[phaseId].name}`;
            }
        });
        return processPhaseName;
    }

    resetSelector() {
        this.selectedActivities = [];
        this.activityGroupsCtrl.reset();
        this.activityGroupsCtrl.markAsTouched();
        this.clearActivity.emit(true);
    }

    onlyItem(activityLink: ActivityLinkField) {
        this.selectedActivities = [activityLink];
        this.activityGroupsCtrl.setValue([activityLink]);
        this.results.emit(this.activityGroupsCtrl.value);
    }

    emitResults(activityLink: ActivityLinkField): void {
        this.results.emit(activityLink);
    }

    trackById(index: number, item: Activity) {
        return item._id;
    }

    refreshSelectedActivity() {
        setTimeout(() => {
            this.init();
        }, 0);
    }

    compareFn(value1: Activity, value2: Activity): boolean {
        if (!value1?._id || !value2?._id) {
            return false;
        }

        return value1._id === value2._id;
    }

    activitySelected(id: string) {
        return this.selectedActivities.find(activity => activity._id === id);
    }

    private async init() {
        /* TODO: Figure out why this fails
           await this.translateService.translationLoaded('activities-shared'); */
        if (!this.options.placeholderLabel) {
            this.options.placeholderLabel = this.translocoService.translate('activities-shared.activity-selector.ts_option_placeholder');
        }

        if (!this.options.noEntriesFoundLabel) {
            this.options.noEntriesFoundLabel = this.translocoService.translate(
                'activities-shared.activity-selector.ts_no_entries_placeholder'
            );
        }

        if (this.options.initValue && this.options.initValue.name && this.options.initValue._id) {
            this.activityGroupsCtrl.setValue(this.options.initValue);
            this.emitResults(this.activityGroupsCtrl.value);
        }

        if (this.options.initValueArray?.length) {
            this.selectedActivities = [...this.options.initValueArray];
            this.activityGroupsCtrl.setValue(this.options.initValueArray);
            this.emitResults(this.activityGroupsCtrl.value);
        }

        if (this.options.required) {
            this.activityGroupsCtrl.setValidators(Validators.required);

            if (!this.activityGroupsCtrl.value) {
                this.clearActivity.emit();
            }
        }

        this.activityGroupsCtrl.markAsTouched();
        this.activityGroupsCtrl.updateValueAndValidity();

        this.cdr.detectChanges();

        this.activityGroupsFilterCtrl.valueChanges
            .pipe(
                map(searchString => String(searchString).trim()),
                debounceTime(300),
                distinctUntilChanged(),
                takeUntil(this.onDestroy)
            )
            .subscribe({
                next: (searchString: string) => {
                    this.filterActivityGroups(searchString);
                    this.cdr.detectChanges();
                },
            });

        this.getActivities();
    }

    private getFieldFilters(): SearchFilter[] {
        if (!this.viewContext || !this.fieldId) {
            return [];
        }

        const field = this.viewContext.template.value?.fields?.find(({ id }) => id === this.fieldId);
        if (!field?.modifier?.activityFieldFilters?.length) {
            return [];
        }

        const filters: SearchFilter[] = [];
        const workflowId = this.viewContext.workflow.value?._id;
        if (!workflowId) {
            return [];
        }

        for (const { sourceFieldId, targetFieldId, targetWorkflowId } of field.modifier.activityFieldFilters) {
            if (!sourceFieldId) {
                continue;
            }

            let sourceFieldValue = this.viewContext.toSave?.activity?.fields?.[sourceFieldId] || this.viewContext.toSave?.options?.fields?.[sourceFieldId];
            if (sourceFieldValue === undefined) {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const { _id, code } = this.viewContext.activity.value?.fields[sourceFieldId]?.value || {};
                sourceFieldValue = _id || code;
            }

            if (!sourceFieldValue && typeof sourceFieldValue !== 'number' && typeof sourceFieldValue !== 'boolean') {
                continue;
            }

            const targetWorkflow = this.core.processById(targetWorkflowId);
            const targetField = targetWorkflow?.fields?.[targetFieldId];
            if (!targetField) {
                continue;
            }

            if (!this.fieldId) {
                continue;
            }

            const field = this.viewContext.workflow.value?.fields?.[this.fieldId];
            if (!field?.data?.includes(targetWorkflowId)) {
                continue;
            }

            filters.push({ targetWorkflowId, targetFieldId, sourceFieldValue });
        }

        return filters;
    }

    private getActivities() {
        this.processIds = [];
        this.processes.forEach(process => {
            this.processIds.push(process._id);
        });

        if (this.processIds.length === 0) {
            return;
        }

        this.rpc
            .request('activities.search', ['', this.processIds, stripUndefined({ fieldFilters: this.getFieldFilters() })])
            .pipe(take(1))
            .subscribe({
                next: (result: any) => {
                    this.activitySearchArray = result;

                    if (this.options.initValue) {
                        this.activitySearchArray.unshift(this.options.initValue);
                    }

                    this.cdr.detectChanges();
                },
            });
    }

    private filterActivityGroups(searchString: string) {
        this.rpc
            .request('activities.search', [searchString || '', this.processIds, stripUndefined({ fieldFilters: this.getFieldFilters() })])
            .pipe(take(1))
            .subscribe({
                next: (result: any) => {
                    this.activitySearchArray = result;
                    if (this.activityGroupsCtrl.value && this.activityGroupsCtrl.value._id && this.activityGroupsFilterCtrl.value === '') {
                        this.activitySearchArray.unshift(this.activityGroupsCtrl.value);
                    }

                    this.cdr.detectChanges();
                },
                error: (error: any) => console.error('searchActivities error:', error),
            });
    }
}
