/** @format */

import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Event, NavigationExtras, Params, Router } from '@angular/router';
import { Observable, take } from 'rxjs';

import { CoreService } from './core.service';
import { SideNavService } from './side-nav.service';
import { environment } from '@app/env';

@Injectable({
    providedIn: 'root',
})
export class RoutingService {
    constructor(
        private core: CoreService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private sideNav: SideNavService
    ) {}

    userRedirect = '';
    userRedirectInProgress = false;

    async toDefaultView(): Promise<void> {
        console.log('navigating to default view');

        const state = this.core.state.value;
        if (state !== 'authenticated') {
            console.log('not authenticated', state);
            switch (state) {
                case 'connecting':
                    // Loop until connected
                    void this.toDefaultView();
                    break;
                default:
                    void this.navigate([`/login`]);
                    break;
            }
            return;
        }

        const network = this.core.network.value;
        const user = this.core.user.value;

        if (!network || !user) {
            console.warn('no user or network defined');
            return;
        }

        if (this.userRedirect && !this.userRedirectInProgress) {
            console.log('redirecting to', this.userRedirect);
            this.userRedirectInProgress = true;
            await this.router.navigateByUrl(this.userRedirect);
            return;
        }

        const team = Object.values(this.core.teams.value?.[network._id] || {}).find(team => !!team.defaultView && !!team.members?.includes(user._id));
        let defaultViewValue = team?.defaultView?.value;
        if (defaultViewValue?.startsWith('/#/')) {
            defaultViewValue = defaultViewValue.replace('/#/', '');
        }

        if (defaultViewValue && team?.defaultView?.type === 'custom') {
            console.log('navigating to custom view', defaultViewValue);
            void this.router.navigateByUrl(`${defaultViewValue}`);
            return;
        }

        if (defaultViewValue && team?.defaultView?.type === 'app') {
            defaultViewValue = `apps/${defaultViewValue}`;
        }

        console.log('navigating to default view', defaultViewValue);
        void this.navigate([`/${defaultViewValue || 'discussions'}`]);
    }

    navigate(commands: any[], extras?: NavigationExtras) {
        return this.router.navigate(commands, extras);
    }

    get snapshot(): ActivatedRouteSnapshot {
        return this.activatedRoute.snapshot;
    }

    get queryParams(): Observable<Params> {
        return this.activatedRoute.queryParams;
    }

    getQueryParams(): Promise<Params> {
        return new Promise<Params>((resolve) => this.activatedRoute.queryParams.pipe(take(1)).subscribe({ next: params => resolve(params) }));
    }

    get url(): string {
        return this.router.url;
    }

    get events(): Observable<Event> {
        return this.router.events;
    }
}

export type ViewTypes = 'activities' | 'discussions' | 'events' | 'feed' | 'files' | 'people';
