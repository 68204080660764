/** @format */
/* ToDo: Delete when DigiOne changed url from /#/redirect/digione to /digione-login */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { Capacitor } from '@capacitor/core';
import { CoreService, SessionState } from 'app/_services/core.service';
import { RoutingService } from 'app/_services/routing.service';
import { OidcService, Provider } from 'app/_services/oidc.service';

@Component({
    selector: 'app-digione-login',
    templateUrl: './digione-login-page.component.html',
    styleUrls: ['./digione-login-page.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: {
                scope: 'public',
                alias: 'public',
            },
        },
    ],
})
export class DigiOneLoginPageComponent implements OnInit, OnDestroy {
    state: Observable<SessionState>;
    // ToDo: Implement mobile flow
    isMobile: boolean = Capacitor.isNativePlatform();
    saving = new BehaviorSubject<boolean>(false);
    digiOneLoginError = '';

    private onDestroy = new Subject<void>();
    private unsubscribe$ = new Subject<void>();

    constructor(
        private activatedRoute: ActivatedRoute,
        private core: CoreService,
        private route: RoutingService,
        private oidc: OidcService
    ) {}

    ngOnInit(): void {
        // Observable that resolves when state is loaded
        const stateLoaded$ = this.core.state.pipe(takeUntil(this.onDestroy));

        // Combine state and queryParams observables to process them together
        combineLatest([stateLoaded$, this.activatedRoute.queryParams])
            .pipe(takeUntil(this.onDestroy))
            .subscribe(([newState, params]) => {
                if (newState === 'authenticated' && this.core.user.value) {
                    // If user is authenticated, redirect to default view
                    void this.route.toDefaultView();
                } else if (params.code && params.state) {
                    // Handle callback if code and state exist
                    void this.handleCallback();
                } else {
                    // Trigger sign-in automatically if code and state are missing
                    console.log('No code or state detected. Triggering sign-in.');
                    void this.signIn('digione');
                }
            });
    }

    // This is only called if we have params.code && params.state in ngOnInit()
    private async handleCallback(): Promise<void> {
        this.saving.next(true);

        try {
            const success = await this.oidc.handleCallback();
            if (!success) {
                console.log('Login failed in handleCallback!');
                this.digiOneLoginError = 'Login failed!';

                // User will be redirected to default view if logged in
            }
        } catch (error) {
            console.error('Error handling callback:', error);
            this.digiOneLoginError = `Login failed: ${error}`;
        } finally {
            this.saving.next(false);
        }
    }

    async signIn(providerId: Provider): Promise<void> {
        this.saving.next(true);
        this.digiOneLoginError = '';

        try {
            await this.oidc.signIn(providerId);
        } catch (error) {
            console.error('Login error:', error);
            this.digiOneLoginError = `Login failed: ${error}`;
        } finally {
            this.saving.next(false);
        }
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();

        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    toDefaultView(): void {
        void this.route.toDefaultView();
    }
}
