/** @format */

import { Injectable } from '@angular/core';
import { merge } from 'rxjs';

import { EventsService } from 'app/events/events.service';
import { PermissionValueType } from 'app/_models/permission-type.model';
import { Discussion, Process, Team } from '@app/models';
import { CoreService } from './core.service';
import { TeamService } from './team.service';
import { Member, PermissionListMember } from 'app/_models/member.model';
import { RPCService } from './rpc.service';
import { GroupService } from './group.service';
import { HasPermissionPipe } from 'app/pipes/src/permission.pipe';

@Injectable({
    providedIn: 'root',
})
export class PermissionService {
    isNetworkAdmin = false;
    isNetworkOwner = false;
    isNetworkGuest = false;
    isNetworkInviter = false;
    isNetworkUser = false;

    constructor(
        private core: CoreService,
        private teams: TeamService,
        private events: EventsService,
        private rpc: RPCService,
        private groups: GroupService
    ) {
        merge(this.core.network, this.core.user).subscribe({
            next: () => {
                this.isNetworkAdmin = this.checkIfNetworkAdmin(this.core.user.value?.id);
                this.isNetworkOwner = this.checkIfNetworkOwner(this.core.user.value?.id);
                this.isNetworkGuest = this.checkIfNetworkGuest(this.core.user.value?.id);
                this.isNetworkInviter = this.checkIfNetworkInviter(this.core.user.value?.id);
                this.isNetworkUser = this.checkIfNetworkUser(this.core.user.value?.id);
            },
        });
    }

    isCalendarAdmin(userId: string, calendarId: string): boolean {
        const isNetworkAdmin = this.isNetworkAdmin;
        let isCalendarAdmin = false;

        if (isNetworkAdmin) {
            return true;
        }

        const calendar = this.core.calendars.value[this.core.network.value._id]?.[calendarId];

        if (!calendar) {
            return false;
        }

        if (!calendar.members || calendar.members.length === 0) {
            return true;
        }

        for (const member of calendar.members) {
            if (!member.permissions.includes('admin')) {
                continue;
            }

            isCalendarAdmin = this.isMember([member], userId);

            if (isCalendarAdmin) {
                break;
            }
        }

        return isCalendarAdmin;
    }

    isCalendarMember(userId: string, calendarId: string): boolean {
        const isNetworkAdmin = this.isNetworkAdmin;

        if (isNetworkAdmin) {
            return true;
        }

        const calendar = this.core.calendars.value[this.core.network.value._id]?.[calendarId];

        if (!calendar) {
            return false;
        }

        if (!calendar.members || calendar.members.length === 0) {
            return true;
        }

        return this.isMember(calendar.members, userId);
    }

    isFilesetAdmin(userId: string, filesetOwner: string): boolean {
        const isNetworkAdmin = this.isNetworkAdmin;

        if (isNetworkAdmin) {
            return true;
        } else if (userId === filesetOwner) {
            return true;
        }
        return false;
    }

    checkIfNetworkAdmin(userId: string, options?: { workspaceId?: string }): boolean {
        const network = options?.workspaceId ? this.core.networks.value[options.workspaceId] : this.core.network.value;

        if (!network) {
            return false;
        }

        return network.members.some(member => member.uid === userId && (member.owner || member.admin));
    }

    checkIfNetworkOwner(userId: string): boolean {
        const network = this.core.network.value;

        if (!network) {
            return false;
        }

        return network.members.some(member => member.uid === userId && member.owner);
    }

    checkIfNetworkGuest(userId: string, networkId?: string): boolean {
        networkId = networkId || this.core.network.value?._id;

        const network = this.core.networks.value[networkId];

        if (!network) {
            return false;
        }

        return network.members.some(member => member.uid === userId && member.guest);
    }

    checkIfNetworkInviter(userId: string): boolean {
        const network = this.core.network.value;

        if (!network) {
            return false;
        }

        return network.members.some(member => member.uid === userId && (member.inviter || member.owner || member.admin));
    }

    checkIfNetworkUser(userId: string): boolean {
        const network = this.core.network.value;

        if (!network) {
            return false;
        }

        return network.members.some(member => member.uid === userId && !member.guest && !member.admin && !member.owner && !member.inviter);
    }

    isProcessAdmin(userId: string, processId: string): boolean {
        let isProcessAdmin = false;

        const process = this.core.processById(processId);

        if (!process) {
            return false;
        }

        if (this.checkIfNetworkAdmin(userId, { workspaceId: process.cid })) {
            return true;
        }

        if (this.checkIfNetworkGuest(userId, process.cid)) {
            return false;
        }

        if (!process.members || process.members.length === 0) {
            return false;
        }

        for (const member of process.members) {
            if (!member.permissions.includes('admin')) {
                continue;
            }

            isProcessAdmin = this.isMember([member], userId);

            if (isProcessAdmin) {
                break;
            }
        }

        return isProcessAdmin;
    }

    viewOnlyPhase(userId: string, processId: string, phaseId?: string): boolean {
        const process = this.core.processById(processId);
        if (!process) {
            return true;
        }
        const firstPhaseId = phaseId ? phaseId : process.phasesOrder[0];
        if (!firstPhaseId) {
            return true;
        }

        if (this.checkIfNetworkAdmin(userId, { workspaceId: process.cid })) {
            return true;
        }

        return this.hasPhasePermission('edit', userId, processId, phaseId);
    }

    isProcessMember(userId: string, processId: string): boolean {
        if (this.checkIfNetworkAdmin(userId)) {
            return true;
        }

        const process = this.core.processById(processId);

        if (!process?.members || process?.members.length === 0) {
            return true;
        }

        return this.isMember(process.members, userId);
    }

    isTeamMember(team: Team, userId: string): boolean {
        let isMember = false;

        team.members.forEach(user => {
            if (user === userId) {
                isMember = true;
            }
        });

        return isMember;
    }

    isProcessPhaseMember(userId: string, processId: string, phaseId: string) {
        const process = this.core.processById(processId);

        if (!process) {
            console.log('Process not found with id:', processId);
            return false;
        }

        const phase = process.phases[phaseId];

        if (!phase) {
            console.log('This phase does not exist in process:', this.core.processById(processId).name, processId, phaseId);
            return false;
        }

        if (this.isNetworkAdmin || this.isProcessAdmin(userId, processId)) {
            return true;
        }

        if (!this.isProcessMember(userId, processId)) {
            return false;
        }

        if (!phase.members || phase.members.length === 0) {
            return true;
        }

        return this.isMember(phase.members, userId);
    }

    isProcessPhaseEditor(userId: string, processId: string, phaseId: string) {
        const process = this.core.processById(processId);

        if (!process) {
            return false;
        }

        if (this.isNetworkAdmin || this.isProcessAdmin(userId, processId)) {
            return true;
        }

        if (this.isNetworkGuest) {
            return process.enableGuestEditing;
        }

        if (this.hasPhasePermission('edit', userId, processId, phaseId)) {
            return true;
        }

        if (!this.isProcessMember(userId, processId)) {
            return false;
        }

        const phase = process.phases[phaseId];

        if (!phase) {
            return false;
        }

        if (!phase?.members || phase?.members.length === 0) {
            return true;
        }

        return phase.members.some(member => member.id.slice(5) === userId && member.permissions.includes('edit'));
    }

    /**
     *
     * Returns the first phase user can access from a process.
     *
     * @param userId
     * @param processId
     */
    accessiblePhase(userId: string, processId: string): string {
        let permission;
        const process = this.core.processById(processId);

        if (!process || process.phasesOrder.length < 1) {
            return undefined;
        }

        if (this.isProcessAdmin(userId, processId)) {
            return process.phases[process.phasesOrder[0]]._id;
        }

        process.phasesOrder.forEach(phaseId => {
            if (permission) {
                return;
            }
            if (this.hasPhasePermission('edit', userId, processId, phaseId)) {
                permission = phaseId;
            }
        });

        return permission;
    }

    workflowFirstPhasePermission(userId: string, processId: string) {
        const process = this.core.processById(processId);

        if (process?.enableUnlinkedMode) {
            return true;
        }
        return this.hasPhasePermission('edit', userId, processId);
    }

    hasProcessPermission(userId: string, processId: string, phaseId?: string): boolean {
        if (this.checkIfNetworkAdmin(userId)) {
            return true;
        }

        if (this.isProcessAdmin(userId, processId)) {
            return true;
        }

        const process = this.core.processById(processId);
        return process?.enableUnlinkedMode
            ? this.hasPhasePermission('edit', userId, processId, phaseId)
            : this.isProcessMember(userId, processId) && this.viewOnlyPhase(userId, processId);
    }

    hasPhasePermission(permission: PermissionValueType, userId: string, processId: string, selectedPhaseId?: string): boolean {
        const process = this.core.processById(processId);

        if (!process || process.phasesOrder.length < 1) {
            return false;
        }

        const phaseId = selectedPhaseId ? selectedPhaseId : process.phases[process.phasesOrder[0]]._id;

        const phase = process.phases[phaseId];

        if (this.isNetworkAdmin || this.isProcessAdmin(userId, processId)) {
            return true;
        }

        if (!phase || !phase?.members || phase?.members?.length === 0) {
            return true;
        }

        const userTeams = this.teams.getUserTeams(userId, { workspaceId: process.cid });
        const userGroups = this.groups.getUserGroups(userId, { workspaceId: process.cid });

        for (const member of phase.members) {
            const hasPermissionOrViewOnly = member.permissions.includes(permission) || permission === 'viewOnly';

            if (!hasPermissionOrViewOnly) {
                continue;
            }

            if (member.id.startsWith('network_')) {
                return true;
            }

            if (member.id.startsWith('user_') && member.id.slice(5) === userId) {
                return true;
            }

            if (!userTeams.length && !userGroups.length) {
                continue;
            }

            if (member.id.startsWith('team_') && userTeams.find(team => team._id === member.id.slice(5))) {
                return true;
            }

            const permissionPipe = new HasPermissionPipe(this.core);
            if (
                member.id.startsWith('group_') &&
                permissionPipe.transform(this.core.permission.phase.list(process.cid, process._id, phaseId))
            ) {
                return true;
            }
        }

        return false;
    }

    isMember(members: Member[], userId: string, options?: { workspaceId?: string }): boolean {
        let isMember = false;
        const userTeams = this.teams.getUserTeams(userId, options);
        const userGroups = this.groups.getUserGroups(userId, options);
        const cid = options?.workspaceId || this.core.network.value._id;

        members?.forEach(member => {
            if (member.id.startsWith('team_')) {
                if (userTeams.find(team => team._id === member.id.slice(5))) {
                    isMember = true;
                }
            } else if (member.id.startsWith('group_')) {
                if (userGroups.find(group => group._id === member.id.slice(6))) {
                    isMember = true;
                }
            } else if (member.id === `user_${userId}`) {
                isMember = true;
            } else if (member.id === `network_${cid}`) {
                isMember = true;
            }
        });

        return isMember;
    }

    getMemberPermissions(members: Member[], process?: Process): PermissionListMember[] {
        const memberPermissions: PermissionListMember[] = [];
        if (!members || members?.length < 0) {
            return [];
        }
        members.forEach(member => {
            memberPermissions.push({
                ...member,
                allowRemove: this.showRemovePermissions(member.id, members, process),
            });
        });
        return memberPermissions;
    }

    getPhaseMemberPermissions(members: Member[], process?: Process): PermissionListMember[] {
        const memberPermissions = this.getMemberPermissions(members, process);
        if (!memberPermissions || memberPermissions?.length < 0) {
            return [];
        }
        memberPermissions.forEach(member => {
            const index = member.permissions.findIndex(permission => permission === 'edit');
            if (index === -1) {
                member.permissions.push('viewOnly');
            }
        });
        return memberPermissions;
    }

    canAddActivities(userId: string, processId: string, phaseId?: string): boolean {
        if (this.checkIfNetworkGuest(userId)) {
            return false;
        }

        const hasProcessPermission =
            this.hasProcessPermission(userId, processId, phaseId) &&
            this.viewOnlyPhase(userId, processId, phaseId) &&
            this.workflowFirstPhasePermission(userId, processId);

        return hasProcessPermission || this.checkIfNetworkAdmin(userId) || this.checkIfNetworkOwner(userId);
    }

    async guestsAllowedInDiscussion(discussion: Discussion): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            let allowGuests = false;
            if (discussion?.linked_activity) {
                const process = this.core.processById(discussion.process);

                allowGuests = process?.allowGuests;
                resolve(allowGuests);
            } else if (discussion?.linked_event) {
                this.events.loadEvent(discussion.linked_event).subscribe({
                    next: async event => {
                        const calendars = this.core.calendars.value;
                        const networkId = this.core.network.value._id;

                        if (!networkId || !calendars) {
                            return void reject(new Error('Failed to check from cache!'));
                        }

                        const networkCalendars = calendars[networkId];
                        const calendarId = event.calendar_id;
                        const calendar = networkCalendars[calendarId];

                        allowGuests = calendar?.allowGuests;
                        resolve(allowGuests);
                    },
                });
            } else if (!discussion.linked_activity && !discussion.linked_event && !discussion.private) {
                resolve(allowGuests);
            }
        });
    }

    private showRemovePermissions(memberId: string, members: Member[], process?: Process): boolean {
        const userId = memberId.substr(-24);

        const adminFromTeams: string[] = [];
        const adminFromGroups: string[] = [];
        const userTeams = this.teams.getUserTeams(this.core.user.value.id);
        const userGroups = this.groups.getUserGroups(this.core.user.value.id);

        members?.forEach(member => {
            const teamId = member.id.slice(5);
            const isTeam = member.id.startsWith('team');
            const adminTeam = member.permissions.includes('admin');
            const userInTeam = userTeams?.find(team => team._id === teamId);
            if (isTeam && adminTeam && userInTeam && !adminFromTeams.includes(member.id)) {
                adminFromTeams.push(member.id);
            }
        });

        members?.forEach(member => {
            const groupId = member.id.slice(6);
            const isGroup = member.id.startsWith('group');
            const adminGroup = member.permissions.includes('admin');
            const userInGroup = userGroups?.find(group => group._id === groupId);
            if (isGroup && adminGroup && userInGroup && !adminFromGroups.includes(member.id)) {
                adminFromGroups.push(member.id);
            }
        });

        if (process && this.isProcessAdmin(this.core.user.value.id, process?._id)) {
            return true;
        }

        if (this.isNetworkAdmin || this.isNetworkOwner) {
            return true;
        }

        const networkPermissions = members.find(network => network.id === `network_${this.core.network.value._id}`);
        const userPermissions = members.find(member => member.id === `user_${this.core.user.value.id}`);
        const networkAdmin = networkPermissions?.permissions.includes('admin');
        const userAdmin = userPermissions?.permissions.includes('admin');

        // If user is only admin from this team, do not allow them to remove that team or its permissions
        if (adminFromTeams.length === 1 && adminFromTeams.includes(this.core.user.value.id) && !userAdmin && !networkAdmin) {
            return false;
        }

        if (userId === this.core.user.value.id) {
            // If current user, only show permissions if user has permissions from network or team
            return networkAdmin || !!adminFromTeams.length || !!adminFromGroups.length;
        } else if (memberId.startsWith('network')) {
            // Show network permissions if admin from elsewhere
            return userAdmin || !!adminFromTeams.length || !!adminFromGroups.length;
        }
        // Show other users/teams permissions if admin from anywhere
        return userAdmin || networkAdmin || !!adminFromTeams.length || !!adminFromGroups.length;
    }
}
