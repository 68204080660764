/** @format */

import { ActivatedRoute } from '@angular/router';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ElementRef,
    NgZone,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { debounceTime, filter, take, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

import { PersonalSettings } from '../../_models/personal-settings.model';
import { FeedPost } from '../../_models/feedPost.model';
import { RPCService } from '../../_services/rpc.service';
import { FeedService } from '../feed.service';
import { CoreService } from 'app/_services/core.service';
import { DialogHelperService } from 'app/_dialogs/dialog-helper.service';
import { FeedPostDialogComponent } from 'app/core/feed-post-dialog/feed-post-dialog.component';
import { FeedLoadPostsOptions } from '../../../../test/deps/hailer-api/shared/feed-types';
import { stripUndefined } from '../../../../test/deps/hailer-api/shared/util';
import { FormControl } from '@angular/forms';
import { FeedStaticService } from '../feed-static.service';

type TypeFilter = 'user' | 'activity'
@Component({
    selector: 'app-feed',
    templateUrl: './feed.component.html',
    styleUrls: ['./feed.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        FeedService,
        { provide: TRANSLOCO_SCOPE, useValue: 'feed' }
    ],
})

export class FeedComponent implements OnInit, OnDestroy, AfterViewInit {
    @ContentChild('content', { static: false }) content: any;
    @ViewChild('feedContainer', { static: true }) feedContainer: ElementRef;

    loading = new BehaviorSubject<boolean>(false);
    view: string;
    typeFilters: TypeFilter[] = ['user', 'activity'];
    selectedTypeFilters = new Set<TypeFilter>();
    onlyStarred = false;
    selectedWorkspaces = new Set<string>();
    userData;
    me: Observable<PersonalSettings>;

    searchForm = new FormControl<string>('');

    revealHidden = false;

    hiddenPosts: FeedPost[] = [];

    workspaceIds: string[] = [];

    private inputPostId: string;
    private previousScroll = 0;
    private onDestroy = new Subject<void>();

    constructor(
        public core: CoreService,
        public feed: FeedService,
        private feedStatic: FeedStaticService,
        private zone: NgZone,
        private rpc: RPCService,
        private dialogHelperService: DialogHelperService,
        private cdr: ChangeDetectorRef,
        private activatedRoute: ActivatedRoute,
        private matDialog: MatDialog,
    ) {}

    ngOnInit() {
        const selectedTypeFilters = localStorage.getItem('feedSelectedTypeFilters');
        if (selectedTypeFilters) {
            this.selectedTypeFilters = new Set<TypeFilter>(JSON.parse(selectedTypeFilters));
        }

        this.setWorkspaceIds();
        this.selectedWorkspaces = new Set(this.core.user.value?.globalSettings?.feedSelectedWorkspaces);
        void this.loadFeedPosts();
        this.me = this.core.user;
        void this.setSeen();

        if (this.activatedRoute.snapshot.firstChild) {
            this.activatedRoute.firstChild.data.pipe(takeUntil(this.onDestroy)).subscribe({
                next: (data: any) => {
                    if (!data) {
                        return;
                    }
                    this.inputPostId = data.post._id;
                },
                error: () => {
                    // Error handling
                },
            });
        }

        this.feed.details.pipe(takeUntil(this.onDestroy)).subscribe({
            next: () => {
                this.setHiddenPosts();
                this.cdr.detectChanges();
            },
        });

        this.feed.posts.pipe(takeUntil(this.onDestroy)).subscribe({
            next: () => {
                this.setHiddenPosts();
                this.cdr.detectChanges();
            },
        });

        this.core.permission.changed.pipe(takeUntil(this.onDestroy)).subscribe({
            next: () => {
                this.cdr.detectChanges();
            },
        });

        this.searchForm.valueChanges.pipe(takeUntil(this.onDestroy), debounceTime(500)).subscribe({
            next: () => void this.loadFeedPosts({ clearPosts: true }),
        });

        this.feed.reload.pipe(takeUntil(this.onDestroy)).subscribe({
            next: options => void this.loadFeedPosts(options),
        });

        this.core.networks.pipe(takeUntil(this.onDestroy)).subscribe({
            next: () => {
                this.setWorkspaceIds();
            },
        });
    }

    ngAfterViewInit(): void {
        if (this.inputPostId) {
            this.openFeedPost();
        }
    }

    ngOnDestroy(): void {
        void this.setSeen();
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    async setSeen(): Promise<void> {
        const selectedWorkspaceIds = Array.from(this.selectedWorkspaces);
        const workspaceIds = selectedWorkspaceIds.length ? selectedWorkspaceIds : Object.keys(this.core.networks.value);
        await this.feedStatic.setSeen(workspaceIds);
    }

    setHiddenPosts(): void {
        if (!this.hiddenPostIds.length) {
            this.hiddenPosts = [];
            return;
        }

        this.hiddenPosts = this.feed.posts.value.filter(post => this.hiddenPostIds.includes(post._id));
    }

    onlyStarredPosts(): void {
        this.onlyStarred = !this.onlyStarred;
        void this.loadFeedPosts({ clearPosts: true });
    }

    openFeedPost() {
        this.zone.run(() => {
            this.matDialog.open(FeedPostDialogComponent, {
                data: this.inputPostId,
                panelClass: 'feed-post-dialog',
            });
        });
    }

    toggleHiddenPosts(): void {
        this.revealHidden = !this.revealHidden;
        void this.loadFeedPosts({ clearPosts: true });
    }

    // Filters
    toggleTypeFilter(filter: TypeFilter): void {
        if (this.selectedTypeFilters.has(filter)) {
            this.selectedTypeFilters.delete(filter);
        } else {
            this.selectedTypeFilters.add(filter);
        }

        localStorage.setItem('feedSelectedTypeFilters', JSON.stringify(Array.from(this.selectedTypeFilters)));
        this.cdr.detectChanges();
        void this.loadFeedPosts({ clearPosts: true });
    }

    postIsHidden(postId: string): boolean {
        return this.hiddenPostIds.includes(postId);
    }

    get areFiltersActive(): boolean {
        return !!this.selectedTypeFilters.size || !!this.selectedWorkspaces.size;
    }

    clearFilter(event: MouseEvent): void {
        event.stopPropagation();
        this.selectedTypeFilters.clear();
        localStorage.removeItem('feedSelectedTypeFilters');
        this.selectedWorkspaces.clear();
        void this.saveSelectedWorkspaces([]);
        void this.loadFeedPosts({ clearPosts: true });
    }

    processFilesFromPostMetas(post: FeedPost, isPicture: boolean) {
        const out = [];
        if (post && post.initMessage && post.initMessage.files && post._metas && post._metas.files) {
            for (const file of post.initMessage.files) {
                if (post._metas.files[file] && post._metas.files[file].isPicture === isPicture) {
                    out.push(post._metas.files[file]);
                }
            }
        }
        return out;
    }

    followStatus(post) {
        if (post && post.followers.isArray()) {
            if (post.followers.indexOf(this.getPersonalSettings().id) > -1) {
                return true;
            }
            return false;
        }
    }

    followPost(post: FeedPost) {
        const status = this.followStatus(post);
        const action = status ? 'wall2.unfollow_post' : 'wall2.follow_post';
        this.rpc.rpc(action, [post._id], (err, data) => {
            if (err) {
                this.showError(`Unable to follow post. ${data}`);
                return;
            }
            if (status) {
                post.followers.splice(post.followers.indexOf(this.getPersonalSettings().id), 1);
            } else {
                post.followers.push(this.getPersonalSettings().id);
            }
        });
    }

    processRecipientItem(item) {
        const type = item.split('_')[0];
        const id = item.split('_')[1];
        switch (type) {
            case 'user':
                return { type, id };
            case 'team':
                return { type, id };
        }
    }

    setWorkspaceIds(): void {
        const workspaces = Object.values(this.core.networks.value);
        const sortedWorkspaces = workspaces.sort((a, b) => a.name.trim().localeCompare(b.name.trim(), undefined, { caseFirst: 'upper' }));

        this.workspaceIds = sortedWorkspaces.map(({ _id }) => _id);
        this.cdr.detectChanges();
    }

    get feedTags(): boolean {
        return !!localStorage.getItem('feedTags');
    }

    trackByString(index: number, string: string) {
        return string;
    }

    filterNetwork(workspaceId: string) {
        if (this.selectedWorkspaces.has(workspaceId)) {
            this.selectedWorkspaces.delete(workspaceId);
        } else {
            this.selectedWorkspaces.add(workspaceId);
        }

        void this.loadFeedPosts({ clearPosts: true });
        void this.saveSelectedWorkspaces(Array.from(this.selectedWorkspaces));
    }

    async saveSelectedWorkspaces(workspaceIds: string[]): Promise<void> {
        try {
            await this.rpc.requestAsync('v2.user.settings.global.set', [{ feedSelectedWorkspaces: workspaceIds }]);
        } catch (error) {
            console.error('Error saving selected workspaces', error);
        }
    }

    async loadFeedPosts(options?: { postIds?: string[]; clearPosts?: boolean }): Promise<void> {
        if (this.loading.value) {
            return;
        }

        this.loading.next(true);

        if (!this.core.network.value?._id) {
            await new Promise<void>(resolve => {
                this.core.network
                    .pipe(
                        filter(workspace => !!workspace),
                        take(1),
                        takeUntil(this.onDestroy)
                    )
                    .subscribe({ next: () => resolve() });
            });
        }

        const query: FeedLoadPostsOptions = {
            workspaceIds: this.selectedWorkspaces.size ? Array.from(this.selectedWorkspaces) : undefined,
            skip: !options?.clearPosts && !options?.postIds ? this.feed.posts.value.length || undefined : undefined,
            postIds: options?.postIds,
            // onlyStarred: this.onlyStarred,
            search: this.searchForm.value || undefined,
            includeHidden: this.revealHidden,
            ...(this.feed.onlyScheduledPosts
                ? {
                      onlyScheduled: true,
                      sort: {
                          created: 1,
                      },
                  }
                : {}),
        };

        if (this.selectedTypeFilters.size) {
            for (const filter of Array.from(this.selectedTypeFilters)) {
                query.type = query.type || [];
                switch (filter) {
                    case 'activity':
                        query.type.push('activity.created', 'activity.updated');
                        break;
                    case 'user':
                        query.type.push('user');
                        break;
                    default:
                        break;
                }
            }
        }

        await this.feed.loadPosts(stripUndefined(query), {
            clearPosts: options?.clearPosts,
            bypassDetailsUpdate: !!options?.postIds,
        });
        await this.setSeen();
        this.loading.next(false);
        this.cdr.detectChanges();
    }

    get scheduledPosts(): { _id: string; scheduleTime: number }[] {
        return this.feed.details.value?.scheduledPosts || [];
    }

    get hiddenPostIds(): string[] {
        return this.feed.details.value?.hiddenPosts || [];
    }

    toggleScheduledPosts(): void {
        this.feed.onlyScheduledPosts = !this.feed.onlyScheduledPosts;
        void this.loadFeedPosts({ clearPosts: true });
    }

    get isPostBeingEdited(): boolean {
        if (!this.feed.editingPost.value) {
            return false;
        }

        if (this.feed.editingPost.value === 'create') {
            return false;
        }

        return true;
    }

    /**
     *  Loads more posts if scrolled at bottom and hides the topbar in mobile view
     */
    handleFeedScrolling() {
        if (this.feed.lastPostLoaded) {
            return;
        }

        const topbar = document.getElementById('feed-topbar');
        const content = document.getElementById('feed-content');

        if (topbar && content) {
            if (window.innerWidth <= 600) {
                const currentScroll = window.scrollY;

                if (this.previousScroll < currentScroll && currentScroll > 56) {
                    topbar.style.top = '-8px';
                } else if (topbar.style.top) {
                    topbar.style.top = '48px';
                }

                this.previousScroll = currentScroll;

                if (window.innerHeight + window.scrollY >= content.scrollHeight) {
                    void this.loadFeedPosts();
                }
            } else {
                const clientHeight = content.clientHeight;
                const scrollTop = content.scrollTop;
                const scrollHeight = content.scrollHeight;
                const scrollPadding = 20;

                if (scrollHeight - scrollTop <= clientHeight + scrollPadding) {
                    void this.loadFeedPosts();
                }
            }
        }
    }

    private getPersonalSettings() {
        return this.core.user.value;
    }

    private showError(errorMsg: string) {
        const confirm = `Ok`;
        const content = errorMsg;
        const title = `Error`;

        this.dialogHelperService.showError(confirm, content, title).pipe(take(1)).subscribe();
    }
}
