/** @format */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Params, Router, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class FrontPageGuard implements CanActivate {
    private key;
    private anotherKey;

    constructor(private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // Remove this when confirmed not needed for DigiOne
        /* if (location?.hash) {
            this.checkHash(location.hash);
        } */

        const qParams: Params = route.queryParams;
        this.checkQueryParameters(qParams);
        return true;
    }

    /* Remove this when confirmed not needed for DigiOne */
    /* checkHash(hash: string) {
        try {
            if (!hash.includes('#access_token=')) {
                return;
            }

            // Remove # and split query parameters
            hash = hash.substring(1);
            const queryParams = hash.split('&');

            // Get necessary query parameters and redirect user to login
            const accessToken = queryParams.find(x => x.includes('access_token')).split('=')[1];
            const idToken = queryParams.find(x => x.includes('id_token')).split('=')[1];
            const state = queryParams.find(x => x.includes('state')).split('=')[1];
            const tokenType = queryParams.find(x => x.includes('token_type')).split('=')[1];
            const expiresIn = queryParams.find(x => x.includes('expires_in')).split('=')[1];

            this.router.navigate(
                ['/login'],
                // eslint-disable-next-line @typescript-eslint/naming-convention
                { queryParams: { access_token: accessToken, id_token: idToken, state, token_type: tokenType, expires_in: expiresIn } }
            );
        } catch (error) {
            return;
        }
    } */

    checkQueryParameters(qParams) {
        if (qParams.login) {
            this.key = qParams.login;
            this.router.navigate(['/login', { loginTo: this.key }]);
        }
        if (qParams.validate && qParams.check) {
            this.key = qParams.validate;
            this.anotherKey = qParams.check;
            this.router.navigate(['/validate', { validate: this.key, check: this.anotherKey }]);
        }
        if (qParams.forgotpassword) {
            this.key = qParams.forgotpassword;
            this.router.navigate(['/forgotpassword', this.key]);
        }
        // Forgotpassword without key
        if (qParams.forgotpassword === '') {
            this.router.navigate(['/forgotpassword']);
        }
        if (qParams.invite) {
            this.key = qParams.invite;
            this.router.navigate(['/invite', { invite: this.key }]);
        }
    }
}
