/** @format */
/* ToDo: Delete when DigiOne changed url from /#/redirect/digione to /digione-login */

import { Component, OnDestroy, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

import { CoreService, SessionState } from 'app/_services/core.service';
import { OidcService, Provider } from 'app/_services/oidc.service';
import { authConfigMap } from '../../../../sso-config';
import { RoutingService } from 'app/_services/routing.service';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-redirect',
    templateUrl: './redirect.component.html',
    styleUrls: ['./redirect.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: {
                scope: 'public',
                alias: 'public',
            },
        },
    ],
})
export class RedirectComponent implements OnInit, OnDestroy {
    state: Observable<SessionState>;
    providerId: Provider;
    providerIds: Provider[] = [];
    idToken: string;

    private onDestroy = new Subject<void>();

    constructor(private route: RoutingService, private auth: CoreService, private router: Router, private oidc: OidcService) {}

    ngOnInit(): void {
        // Observable that resolves when state is loaded
        const stateLoaded$ = this.auth.state.pipe(takeUntil(this.onDestroy));

        // Combine state and checkSignInOpenId logic
        stateLoaded$.subscribe({
            next: async (newState) => {
                if (newState === 'authenticated' && this.auth.user.value) {
                    // Redirect to default view if the user is authenticated
                    void this.route.toDefaultView();
                } else {
                    // Initialize the provider and check sign-in state
                    this.providerIds = Object.keys(authConfigMap) as Provider[];
                    this.providerId = this.router.url.split('/redirect/')[1] as Provider;

                    if (!this.providerIds.includes(this.providerId)) {
                        // If the provider is invalid, navigate to login
                        void this.router.navigate(['/login']);
                    } else {
                        // Proceed to check if sign-in is needed
                        await this.checkSignInOpenId();
                    }
                }
            },
            error: (err) => {
                console.error('Error during state check:', err);
            },
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    async signIn(providerId: Provider): Promise<void> {
        await this.oidc.signIn(providerId);
    }

    signOut(): void {
        this.oidc.signOut();
    }

    private async checkSignInOpenId(): Promise<void> {
        const hasValidIdToken = this.oidc.hasValidIdToken();
        this.idToken = this.oidc.getIdToken();

        if (!this.idToken) {
            // If no ID token, initiate sign-in
            await this.signIn(this.providerId);
        } else if (this.idToken && hasValidIdToken) {
            // If a valid ID token exists, log in the user
            await this.oidc.loginDigiOne(this.idToken);
        }
    }
}
