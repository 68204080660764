<mat-card class="feed__feed-post-create-card mat-elevation-z0" [ngClass]="{'feed__feed-post-create-card_closed': !isFocused()}">
    
    <div class="feed-title-input-wrapper">
        <mat-form-field color="accent" appearance="outline" [floatLabel]="isFocused() ? 'never' : 'always'" class="form-field highlight-bg-light-theme-only full-width" [ngClass]="{'new-feed-post-field': !isFocused(), 'new-feed-post-field-open': isFocused()}">
            <mat-label>{{ 'feed.feed_edit.subject' | transloco }}</mat-label>
            <input
                [attr.data-cy]="post ? 'post-edit-subject-input' : 'post-create-subject-input'"
                #subject
                matInput
                (focus)="onFocus()"
                (blur)="onBlur()"
                [formControl]="postForm.controls.subject"
                autocomplete="off"
                [placeholder]="isFocused() ? ('feed.feed_create.start_new_post' | transloco) : (post ? 'feed.feed_edit.subject' : 'feed.feed_create.new_post') | transloco" >
        </mat-form-field>
        <ng-container *ngIf="isFocused()">
            <app-button
                type="icon"
                color="tertiary-default-background"
                hailerIconName="hailer-x"
                (clickEvent)="cancel()"
                [tippyText]="'buttons.buttons.cancel' | transloco"
                [ariaLabel]="'buttons.buttons.cancel' | transloco"></app-button>
        </ng-container>
    </div>

    <div class="create-post-expanded" [ngClass]="{'feed__feed-post-create-card-content_closed': !isFocused()}">
        <app-text-editor
            *ngIf="translationsLoaded"
            [dataCy]="post ? 'post-edit-text-input' : 'post-create-text-input'"
            [recipients]="this.postForm.value.recipients || []"
            [styles]="{
                background: 'var(--input-highlight-bg-light-theme-only)',
                'border-radius': '8px',
                'height': '200px',
                'font-size': '1rem'
            }"
            [textForm]="postForm.controls.text"
            [placeholder]="'feed.feed_create.tell_your_network' | transloco"></app-text-editor>

        <!-- Tippy with list of selected members, if some selection in user-team-selector -->
        <button
            [tippy]="recipientNames.length ? recipientNames.join(', ') : ''"
            [isEnabled]="!!recipientNames.length"
            class="no-button visibility-button flex-align-center"
            (click)="openVisibilityDialog()"
            data-cy="post-limit-visibility-button">
            <mat-icon
                [svgIcon]="postForm.value.recipients?.length ? 'hailer-team' : 'hailer-workspace'"
                class="margin-right-medium"></mat-icon>
            {{ 'feed.visibility-button.audience' | transloco }}:
            {{
                postForm.value.recipients?.length
                    ? ('feed.visibility-button.only-selected' | transloco)
                    : ('feed.visibility-button.everyone' | transloco)
            }}
            {{ 'feed.visibility-button.in' | transloco }} {{ workspace?.name }}
        </button>
        <mat-slide-toggle (change)="allowReply($event)" [checked]="postForm.value.allowReply"
            labelPosition="after" class="reply-switch">{{ 'feed.allow-reply' | transloco
            }}</mat-slide-toggle>

        <app-file-uploader data-cy="post-create-file-uploader" class="file-uploader" #uploader></app-file-uploader>

        <div *ngIf="setPoll && (!post || (post && (post.draft || post.scheduled)))" class="remove-poll-btn-wrapper">
            <app-button
                [color]="'destructive-toned-down'"
                [type]="'text-with-icon'"
                size="small"
                [hailerIconName]="'hailer-delete'"
                [ariaLabel]="'poll.remove' | transloco"
                [label]="'poll.remove' | transloco"
                (clickEvent)="toggleSetPoll()">
            </app-button>
        </div>
        <ng-container *ngIf="setPoll">
            <app-poll-editor
                [poll]="poll"
                (validityChanged)="pollValidityChanged($event)"
                (pollChanged)="pollChanged($event)"></app-poll-editor>
        </ng-container>

        <div class="feed-post-editor-toolbar">
            <div class="feed-post-icon-button-row">
                <app-button
                    [size]="'medium'"
                    [color]="'icon-default'"
                    [type]="'icon'"
                    [hailerIconName]="'hailer-attach'"
                    (clickEvent)="uploader.open()"
                    [tippyText]="'feed.feed_create.attach_files' | transloco"
                    [ariaLabel]="'feed.feed_create.attach_files' | transloco">
                </app-button>
                <ng-container *ngIf="!poll || post?.draft">
                    <app-button
                        [dataCy]="'post-editor-add-poll-button'"
                        *ngIf="!setPoll"
                        (click)="toggleSetPoll()"
                        type="icon"
                        size="medium"
                        hailerIconName="hailer-insights"
                        color="icon-default"
                        [ariaLabel]="'feed.toggle-poll' | transloco"
                        [tippyText]="'feed.toggle-poll' | transloco"></app-button>
                </ng-container>
            </div>

            <div class="feed-post-save-buttons">
                <!-- Hiding drafts for v1 -->
                <!-- <app-button
                    *ngIf="!post && !scheduleDateForm.value"
                    size="small"
                    color="secondary"
                    [dataCy]="'create-draft-button'"
                    (clickEvent)="setPost({ draft: true })"
                    [isDisabled]="saveButtonDisabled"
                    [label]="'feed.feed_create.new_draft' | transloco"
                    [ariaLabel]="'feed.feed_create.new_draft' | transloco">
                </app-button> -->

                <ng-container *ngIf="postForm.value.scheduled">
                    <p class="posting-date">{{ 'feed.feed_post.scheduled' | transloco }} {{ postForm.value.scheduleTime | todayDate | async }}</p>
                </ng-container>
                <ng-container *ngIf="post?.draft || post?.scheduled || !post">
                    <app-button
                        type="icon"
                        hailerIconName="hailer-time"
                        (clickEvent)="openDateTimePickerDialog()"
                        size="medium"
                        color="icon-default"
                        [ariaLabel]="postForm.value.scheduled ? ('feed.change-schedule' | transloco) : ('feed.schedule-post' | transloco)"
                        [tippyText]="
                            postForm.value.scheduled ? ('feed.change-schedule' | transloco) : ('feed.schedule-post' | transloco)
                        "></app-button>
                </ng-container>
                <app-button
                    [dataCy]="post ? 'save-post-button' : 'create-post-button'"
                    size="small"
                    (clickEvent)="setPost()"
                    [isDisabled]="saveButtonDisabled"
                    [label]="saveButtonTranslation | transloco"
                    [ariaLabel]="saveButtonTranslation | transloco">
                </app-button>
                 <!-- Hiding drafts for v1 -->
                <!-- <app-button
                    *ngIf="post?.draft"
                    [dataCy]="'create-draft-button'"
                    size="small"
                    (clickEvent)="setPost({ draft: false })"
                    [isDisabled]="saveButtonDisabled"
                    [label]="'feed.feed_create.post_draft' | transloco">
                </app-button> -->
            </div>
        </div>
    </div>
</mat-card>