/** @format */

import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Capacitor } from '@capacitor/core';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

import { GoogleAuth2Service } from 'app/_services/google-auth2.service';
import { UserLogin } from '../../_models/userLogin.model';
import { ContactFormData } from '@app/models';

@Component({
    selector: 'app-login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: {
                scope: 'public',
                alias: 'public',
            },
        },
    ],
})
export class LoginFormComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() hasForgotPasswordLink = true;
    @Input() hasRememberMeButton = false;
    @Input() presetUserName: string;
    @Output() submitClick: EventEmitter<UserLogin> = new EventEmitter<UserLogin>();
    @Output() readonly emailChanged = new EventEmitter<ContactFormData>();

    loginForm: UntypedFormGroup;
    saving = new BehaviorSubject<boolean>(false);
    googleLoginError: string;
    googleAuth2IsLoaded: boolean;
    registeringError = false;
    loggingInError = false;
    isMobile = Capacitor.isNativePlatform();
    passwordVisible = false;

    // Login form fields
    private username: FormControl;
    private password: UntypedFormControl;
    private rememberLogin: UntypedFormControl;
    private onDestroy = new Subject<void>();
    private changedFormData: ContactFormData;

    constructor(private googleAuth: GoogleAuth2Service, private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        this.createFormControls();
        this.createForm();

        this.googleAuth.loginErrorMsg.pipe(takeUntil(this.onDestroy)).subscribe(errorMsg => {
            this.loggingInError = true;
            this.googleLoginError = errorMsg;
            this.cdr.detectChanges();
        });

        this.googleAuth.registerErrorMsg.pipe(takeUntil(this.onDestroy)).subscribe(errorMsg => {
            this.registeringError = true;
            this.googleLoginError = errorMsg;
            this.cdr.detectChanges();
        });

        this.username.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe({
            next: (email: string) => {
                this.changedFormData = { email: email };
                this.emailChanged.emit(this.changedFormData);
            },
        });
    }

    ngAfterViewInit() {
        // Google button is not rendered (in most cases) without the timeout.
        setTimeout(() => {
            this.googleAuth.loadGoogleLibrary();
        }, 200);

        this.cdr.detectChanges();
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    signInGoogleCapacitor(): void {
        this.googleAuth.signInCapacitor('login');
    }

    sendUserLogin() {
        if (!this.username.value.trim() || !this.password.value.trim()) {
            return;
        }

        this.saving.next(true);
        const userLogin = new UserLogin().deserialize({
            username: this.username.value.trim().toLowerCase(),
            password: this.password.value,
            rememberLogin: this.rememberLogin.value,
        });
        this.submitClick.emit(userLogin);
    }

    setSavingState(newState: boolean) {
        this.saving.next(newState);
    }

    setInvalidLoginErrors() {
        this.username.setErrors({ inValid: true });
        this.password.setErrors({ inValid: true });
        this.cdr.detectChanges();
    }

    setLoginBlockedError() {
        this.password.setErrors({ loginBlocked: true });
        this.cdr.detectChanges();
    }

    logInDisabled(): boolean {
        return (
            this.saving.value ||
            !this.loginForm.controls.password?.value ||
            !this.loginForm.controls.username?.value ||
            this.loginForm.controls.password?.errors?.loginBlocked
        );
    }

    emailFieldInvalid(): boolean {
        return (
            this.loginForm?.controls?.username?.errors &&
            !this.loginForm?.controls?.username?.errors?.inValid &&
            this.loginForm?.controls?.username?.value
        );
    }

    private createFormControls() {
        this.username = new UntypedFormControl(this.presetUserName ? { value: this.presetUserName, disabled: true } : '', {
            validators: [Validators.email, Validators.required],
        });
        this.password = new UntypedFormControl('', { validators: Validators.required });
        this.rememberLogin = new UntypedFormControl('');
    }

    private createForm() {
        this.loginForm = new UntypedFormGroup({
            username: this.username,
            password: this.password,
            rememberLogin: this.rememberLogin,
        });
    }
}
