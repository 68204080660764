<!-- @format -->
<!-- ToDo: Delete when DigiOne changed url from /#/redirect/digione to /digione-login -->
<div class="login-background">
    <div class="bubbles"></div>
    <form>
        <div class="entra-login-button-container--multiple-buttons">
            <app-hailer-logo></app-hailer-logo>
            <div class="flex-column--centered login-header">
                <h1 class="login-header">{{ 'public.login-page.log-in' | transloco }} DigiOne</h1>
            </div>
            <button
                (click)="signIn('digione')"
                data-cy="login-digione-button"
                mat-flat-button
                class="single-row-button primary-public-pages">
                {{ (saving | async) ? ('public.login-form.button_login_1' | transloco) : ('public.login-form.button_login_2' | transloco) }}
            </button>

            <ng-container *ngIf="digiOneLoginError">
                <span class="login-error">{{ 'public.login-form.login_error' | transloco }}</span>
                <span class="login-error">{{ digiOneLoginError }}</span>
            </ng-container>
        </div>
    </form>
</div>
