/** @format */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject, combineLatest, takeUntil } from 'rxjs';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

import { UserDetailComponent } from 'app/people-shared/user-detail/user-detail.component';
import { ActivityFieldValue, ActivityTemplateField } from 'app/_models/v3-activity.model';
import { SideNavService } from 'app/_services/side-nav.service';
import { V3ActivitySidenavComponent } from '../v3-activity-sidenav/v3-activity-sidenav.component';
import { V3ActivityViewContextService } from '../v3-activity-view-context.service';
import { TranslateService } from 'app/_services/translate.service';
import { environment } from '@app/env';
import { FilesService } from 'app/_services/files.service';

@Component({
    selector: 'app-v3-activity-field',
    templateUrl: './v3-activity-field.component.html',
    styleUrls: ['./v3-activity-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: TRANSLOCO_SCOPE, useValue: { scope: 'activity-sidenav', alias: 'activity-sidenav' } }],
})
export class V3ActivityFieldComponent implements OnInit, OnDestroy {
    @Input() field: ActivityTemplateField | null;

    /** Overrules value from activity, used in the import edit component */
    @Input() fieldValue?: ActivityFieldValue;

    userImageSize = 20;
    activityValue?: ActivityFieldValue;
    error?: string;

    /** User/Team name */
    meta: string | undefined;
    initializing = true;
    private onDestroy = new Subject<void>();
    constructor(
        public viewContext: V3ActivityViewContextService,
        public translate: TranslateService,
        private cdr: ChangeDetectorRef,
        private sideNav: SideNavService,
        private fileService: FilesService
    ) {}

    // eslint-disable-next-line @typescript-eslint/member-ordering
    static redrawCount = 0;
    redraw() {
        V3ActivityFieldComponent.redrawCount += 1;
        // Console.log('V3ActivityFieldComponent view redrawed: ' + V3ActivityFieldComponent.redrawCount + ' times');
    }

    async ngOnInit(): Promise<void> {
        this.activityValue = await this.getActivityValue();
        this.error = this.getError();

        this.handleValueChanges();
        this.initializing = false;
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    cypressIdentifier(): string {
        const identifier = this.field.id === 'nameField' ? this.field.id : this.field.label;
        return `field-${identifier}`;
    }

    handleValueChanges(): void {
        const combined: BehaviorSubject<any>[] = [];
        if (this.field?.functionEnabled) {
            combined.push(this.viewContext.fieldOverrides);
        }

        if (this.viewContext.action === 'view') {
            combined.push(this.viewContext.activity);
        }
        combined.push(this.viewContext.template);

        if (this.field?.subtype === 'linkedfrom') {
            combined.push(this.viewContext.linkedFromGroup);
        }

        combineLatest(combined)
            .pipe(takeUntil(this.onDestroy))
            .subscribe({
                next: async () => {
                    this.activityValue = await this.getActivityValue();
                    this.error = this.getError();
                    this.cdr.detectChanges();
                },
            });
    }

    get flagClass(): string {
        if (typeof this.activityValue !== 'object') {
            return '';
        }

        return this.activityValue.code ? `fi-${this.activityValue.code.toLowerCase()}` : '';
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    get fileURLs(): string[] {
        if (typeof this.activityValue !== 'string' || !this.field?.modifier?.file) {
            return [];
        }

        const fileMap = Object.fromEntries((this.viewContext.activity.value?.files || []).map(file => [file._id, file]));

        let fileIds: string[] = [];
        try {
            fileIds = JSON.parse(this.activityValue as string);
        } catch {
            fileIds = [];
        }

        return fileIds.map((id: string) => {
            const file = fileMap[id];
            if (!file) {
                return '';
            }
            return this.fileService.getFileThumbnail(file);
        });
    }

    fileToBeRemoved(fileId: string): boolean {
        return this.viewContext.toSave?.options?.files?.[fileId] === null;
    }

    setFileToBeRemoved(fileId: string) {
        if (!this.viewContext.toSave.options.files) {
            this.viewContext.toSave.options.files = {};
        }

        if (this.fileToBeRemoved(fileId)) {
            delete this.viewContext.toSave.options.files[fileId];

            if (!Object.keys(this.viewContext.toSave.options.files || {}).length) {
                delete this.viewContext.toSave.options.files;
            }
            this.cdr.detectChanges();
            return;
        }

        this.viewContext.toSave.options.files[fileId] = null;

        if (!this.viewContext.editingFiles.value) {
            this.viewContext.editingFiles.next(true);
        }

        this.cdr.detectChanges();
    }

    toggleSubheader(): void {
        if (!this.field) {
            return;
        }

        let collapsed = this.viewContext.collapsedSubheaderIds.value;

        if (collapsed.includes(this.field.id)) {
            // Expand by removing the subheader id from collapsedSubheaderIds
            collapsed = collapsed.filter(id => id !== this.field?.id);
        } else {
            // Collapse by adding the subheader id to collapsedSubheaderIds
            collapsed.push(this.field.id);
        }

        this.viewContext.collapsedSubheaderIds.next(collapsed);
    }

    openActivity(activityId: string, workflowId?: string): void {
        if (this.viewContext.isWorkspaceGuest) {
            return;
        }

        this.sideNav.create(V3ActivitySidenavComponent, {
            activityId,
            processId: workflowId,
        });
    }

    openUser(userId: string): void {
        this.sideNav.create(UserDetailComponent, { userId });
    }

    get hasActivityValue(): boolean {
        return !!this.activityValue || this.activityValue === 0 || this.activityValue === false;
    }

    get canBeEdited(): boolean {
        if (!this.field) {
            return false;
        }

        return !this.field.functionEnabled || !!this.field.editable;
    }

    private getError(): string | undefined {
        const activity = this.viewContext.activity.value;

        if (this.field) {
            return activity?.fields?.[this.field.id]?.error;
        }

        if (typeof this.fieldValue === 'object') {
            return this.fieldValue?.error;
        }

        return undefined;
    }

    private async getActivityValue(): Promise<ActivityFieldValue | undefined> {
        if (this.fieldValue) {
            return this.fieldValue;
        }

        if (!this.field) {
            return false;
        }

        const activity = this.viewContext.activity.value;
        const overrides = this.viewContext.fieldOverrides.value;

        switch (this.field.id) {
            case 'ownerTeam':
                this.meta = activity?.team_account?.team ? await this.viewContext.getTeamName(activity?.team_account?.team) : undefined;
                return activity?.team_account?.team;
            case 'ownerUser':
                if (!activity?.uid) {
                    return undefined;
                }

                this.meta = await this.viewContext.getUserNameAsync(activity?.uid);
                return activity?.uid;
            case 'createdDate':
                return activity?.created;
            case 'nameField':
                return activity?.name || overrides?.nameField;
            default:
                break;
        }

        const fieldValue = activity?.fields?.[this.field.id]?.value;
        const value = this.field.id in overrides && this.viewContext.editing.value ? overrides[this.field.id] : fieldValue;
        switch (this.field.subtype) {
            case 'teams':
                this.meta = await this.viewContext.getTeamName(value);
                return value;
            case 'users':
                this.meta = await this.viewContext.getUserNameAsync(value);
                return value;
            case 'subheader':
                return this.field.label;
            case 'linkedfrom':
                return this.field.data[0];
            case 'numeric':
                if (this.field.modifier?.checkbox) {
                    return !!+value;
                }
                return value;
            default:
                return value;
        }
    }
}
