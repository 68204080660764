
export function stripUndefined<T>(doc: T): T {
    return JSON.parse(JSON.stringify(doc));
}

export interface IdMap {
    [key: string]: string
}

/** Returns phaseIds where activity creation is possible */
export const InitialPhaseIds = (workflow: { phasesOrder: string[], phases: { [phaseId: string]: { _id: string, isInitial?: boolean } } }): string[] => {
    if (!workflow?.phasesOrder?.length || !workflow?.phases) {
        return [];
    }

    const initialPhaseIds = new Set(workflow.phasesOrder.filter(phaseId => !!workflow.phases[phaseId]?.isInitial) || []);
    const firstPhaseId = workflow.phasesOrder[0];
    const firstPhaseInitial = workflow.phases[firstPhaseId]?.isInitial;

    if (typeof firstPhaseInitial !== 'boolean') {
        initialPhaseIds.add(firstPhaseId);
    }

    return Array.from(initialPhaseIds) || [];
};
