/** @format */

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

import { FilesService, UploadState } from 'app/_services/files.service';
import { TagSelectorOptions } from 'app/_models/tag-selector-options.model';
import { LicenseService } from 'app/_services/license.service';
import { FileService } from 'app/files-shared/file.service';

export interface FileState {
    name: string;
    length: string;
    fileType: string;
    fileId?: string;
    done: boolean;
    error: boolean;
    progress: number;
    abort: () => void;
    previewUrl?: string;
}
export type FileType = 'archive' | 'code' | 'music' | 'presentation' | 'spreadsheet' | 'image' | 'video' | 'document' | 'pdf' | 'webp' | 'image/webp';

@Component({
    selector: 'app-file-uploader',
    templateUrl: './file-uploader.component.html',
    styleUrls: ['./file-uploader.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: { scope: 'shared', alias: 'shared' },
        },
    ],
})
export class FileUploaderComponent implements OnDestroy {
    @ViewChild('fileInput', { static: true }) fileInput;

    @Input() tagSelectorOptions: BehaviorSubject<TagSelectorOptions>;
    @Input() networkId: string;
    @Input() origin: string;
    @Input() types: FileType[];

    @Output() tagError = new EventEmitter<boolean>();
    @Output() tagged = new EventEmitter<{ tagId: string; fileId: string }>();
    @Output() untagged = new EventEmitter<{ tagId: string; fileId: string }>();
    /** Emits the current state of the files being uploaded.
        Emits values when the first file is added and after the final file has finished uploading. */
    @Output() uploadInProgress = new EventEmitter<boolean>();
    @Output() fileIds = new BehaviorSubject<string[]>([]);

    runningNumber = 0;
    uploaders: FileState[] = [];
    taggedFiles: string[] = [];
    tagSelectorFocus = new BehaviorSubject<void>(null);
    private filesBeingProcessed = new Set<string>([]);
    showDeleteOnEdit: boolean;

    private errors: { [fileId: string]: boolean } = {};
    private onDestroy = new Subject<void>();

    constructor(public filesService: FilesService, private cdr: ChangeDetectorRef, private licenseService: LicenseService) {}

    ngOnDestroy() {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    removeUploader(uploader: FileState) {
        const index = this.uploaders.indexOf(uploader);
        if (index !== -1) {
            this.uploaders.splice(index, 1);
        }
    }

    open() {
        this.fileInput.nativeElement.click();
    }

    reset() {
        this.uploaders = [];
        this.fileIds.next([]);
        this.cdr.detectChanges();
    }

    async uploadFile(file: File) {
        // If file upload is already in progress, we avoid sending an extra signal
        if (!this.filesBeingProcessed.size && !this.inProgress()) {
            this.uploadInProgress.emit(true);
        }

        /* We need to keep track of files that the browsers is currently actively processing.
           This is because it can take quite a while to process the thumbnails for example.
           Files being processed are not reflected in the uploaders array that inProgress() uses,
           which might otherwise cause the file uploader to report a successful upload too early */
        let fileIdentifier = crypto?.randomUUID();
        this.runningNumber++;
        if (!fileIdentifier) {
            fileIdentifier = this.runningNumber.toString() + Date.now().toString();
        }
        this.filesBeingProcessed.add(fileIdentifier);

        const fileSize = this.filesService.formatFileSize(file.size, 1);
        const fileType = this.filesService.fileType(file.name);
        const fileState: FileState = {
            name: file.name,
            length: fileSize,
            fileType,
            done: false,
            error: false,
            progress: 0,
            abort: () => {},
        };

        const subscription = this.filesService.upload(file).subscribe({
            next: (state: UploadState) => {
                if (state.fileId) {
                    fileState.done = true;
                    fileState.progress = 100;
                    fileState.fileId = state.fileId;
                    this.fileIds.next(this.fileIds.value.concat(state.fileId));
                    this.filesService.uploadReady.next();

                    /* In some cases, the file can be uploaded faster than it is processed.
					   This ensures the queue is kept clean. */
                    this.filesBeingProcessed.delete(fileIdentifier);

                    if (this.filesBeingProcessed.size === 0 && !this.inProgress()) {
                        this.uploadInProgress.emit(false);
                    }
                    this.cdr.detectChanges();
                    return;
                }
                fileState.progress = state.progress;
                this.cdr.detectChanges();
            },
            error: (error: any) => {
                fileState.error = true;
                fileState.progress = 100;
                fileState.done = true;
                this.filesService.uploadReady.error(error);

                this.filesBeingProcessed.delete(fileIdentifier);

                if (this.filesBeingProcessed.size === 0 && !this.inProgress()) {
                    this.uploadInProgress.emit(false);
                }
                this.cdr.detectChanges();
            },
        });

        fileState.abort = () => {
            subscription.unsubscribe();
            this.uploaders.splice(this.uploaders.indexOf(fileState), 1);
            if (fileState.fileId) {
                const newFieldIds = this.fileIds.value;
                const index = newFieldIds.indexOf(fileState.fileId);
                newFieldIds.splice(index, 1);
                this.fileIds.next(newFieldIds);
            }

            this.filesBeingProcessed.delete(fileIdentifier);

            if (this.filesBeingProcessed.size === 0 && !this.inProgress()) {
                this.uploadInProgress.emit(false);
            }
        };

        if (fileState.fileType === 'image') {
            fileState.previewUrl = await this.generatePreviewUrl(file);
        }

        this.filesBeingProcessed.delete(fileIdentifier);
        this.uploaders.push(fileState);
        this.cdr.detectChanges();
    }

    uploadFiles($event: any) {
        const files = $event.target.files;
        this.showDeleteOnEdit = true;
        for (const file of files) {
            void this.uploadFile(file);
        }
        $event.target.value = '';
    }

    getFileTypes(): string[] {
        if (!this.types?.length) {
            return [];
        }
        const fileTypes: string[] = [];
        for (const type of this.types) {
            const fileType = this.filesService.fileTypes[type];
            if (fileType) {
                const formattedFileType = Array.from(fileType).map((fileType) => '.' + String(fileType));
                fileTypes.push(...formattedFileType);
            }
        }
        return fileTypes;
    }

    removeFile(uploader) {
        if (!window.location.hash.includes('files')) {
            uploader.abort();
            this.removeUploader(uploader);
        } else {
            this.removeUploader(uploader);
        }
    }

    /**
     * Warning: For getting an accurate state for if files are being uploaded, listen to the filesBeingUploaded emitter
     * instead. This function doesn't take into account files that are being processed by the browser and might report
     * unexpected values as a consequence
     */
    inProgress(): boolean {
        return this.uploaders.some(uploader => !uploader.done);
    }

    allFilesTagged(): boolean {
        return this.taggedFiles.length === this.fileIds.value?.length;
    }

    fileTagged(tagId: string, fileId: string) {
        this.tagged.emit({ tagId, fileId });
        this.taggedFiles.push(fileId);
        this.cdr.detectChanges();
    }

    fileUntagged(tagId: string, fileId: string) {
        this.untagged.emit({ tagId, fileId });
        this.taggedFiles.splice(this.taggedFiles.indexOf(fileId), 1);
        this.cdr.detectChanges();
    }

    showTagSelector(): boolean {
        if (this.tagSelectorOptions && this.tagSelectorOptions.value) {
            return (
                this.licenseService.hasFileTagging() &&
                (this.tagSelectorOptions.value.tagPool
                    ? this.tagSelectorOptions.value.tagPool.length > 0 || this.tagSelectorOptions.value.allowNewTag
                    : this.tagSelectorOptions.value.allowNewTag && this.origin === 'activityView')
            );
        }
        return false;
    }

    setErrors(errorState: boolean, fileId: string) {
        if (fileId) {
            this.errors[fileId] = !!errorState;
        }
        this.getUploaderErrorState();
    }

    getUploaderErrorState(fileId?: string) {
        if (fileId) {
            return this.errors[fileId];
        }
        this.emitTagError();
    }

    focusTagSelector() {
        this.tagSelectorFocus.next();
    }

    private emitTagError() {
        this.tagError.emit(Object.values(this.errors).includes(true));
    }

    private generatePreviewUrl(file: File): Promise<string> {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.onload = (event: any) => {
                resolve(event.target.result);
            };
            reader.readAsDataURL(file);
        });
    }
}
