/** @format */

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpRequest } from '@angular/common/http';
import { filter, map, take } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { saveAs } from 'file-saver';
import { Capacitor, registerPlugin } from '@capacitor/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { File as FileModel } from 'app/_models/file.model';
import { DialogHelperService } from 'app/_dialogs/dialog-helper.service';
import { environment } from '@app/env';
import { RPCService } from './rpc.service';
import { CoreService } from './core.service';

export interface HailerAndroidFiles {
    downloadFile(options: { url: string; fileName: string; fileType: string; hlrkey: string }): Promise<void>;
    saveBinary(options: { binary: any; fileType: string; fileName: string; open: boolean });
}

@Injectable({
    providedIn: 'root',
})
export class FilesService {
    uploadUrl = `${String(environment.wsUrl)}/upload`;
    uploadReady = new Subject<void>();

    fileTypes = {
        archive: new Set(['zip', 'tar', 'gz', 'tgz', 'bz2', 'rar', '7z']),
        code: new Set([
            'ts',
            'js',
            'html',
            'css',
            'scss',
            'tsx',
            'jsx',
            'svelte',
            'rs',
            'go',
            'py',
            'rb',
            'c',
            'json',
            'yml',
            'yaml',
            'sh',
            'bat',
        ]),
        document: new Set(['docx', 'doc', 'odt', 'txt', 'md']),
        music: new Set(['mp3', 'flac', 'wav', 'ogg']),
        pdf: new Set(['pdf']),
        presentation: new Set(['odp', 'pptx', 'ppt']),
        spreadsheet: new Set(['xls', 'xlsx', 'csv', 'ods']),
        video: new Set(['mp4', 'mov', 'webm', 'mkv']),
        image: new Set(['png', 'jpg', 'jpeg', 'gif', 'tif', 'bmp', 'raw', 'webp']),
    };

    private androidFiles: HailerAndroidFiles;

    constructor(
        private core: CoreService,
        private rpcService: RPCService,
        private http: HttpClient,
        private dialogHelperService: DialogHelperService,
        private sanitizer: DomSanitizer
    ) {}

    formatFileSize(bytes, decimalPoint) {
        if (bytes === 0) {
            return '0 Bytes';
        }

        const k = 1000;
        const dm = decimalPoint || 2;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    }

    deleteFile(fileId: string) {
        return this.rpcService.request('files.remove_file', [fileId]);
    }

    getFileThumbnail(file: FileModel, quality = 'thumb'): string {
        if ((file.isPicture || file.type === 'application/pdf') && file._id) {
            return `${environment.wsUrl}/image/${quality}/${file._id}`;
        }

        return this.getGenericFileThumbnail(file.name);
    }

    getGenericFileThumbnail(filename: string): string {
        const type = this.fileType(filename);
        return `assets/icons/document-icons/${type}.svg`;
    }

    fileType(filename: string): string {
        const extension = filename.split('.').pop();
        let type = 'unknown';
        for (const [key, value] of Object.entries(this.fileTypes)) {
            if (value.has(extension?.toLowerCase() || '')) {
                type = key;
                break;
            }
        }
        return type;
    }

    upload(file: File, options?: { isPublic?: boolean }): Observable<UploadState> {
        if (!this.core.hlrkey) {
            return new Observable(subscriber => subscriber.error(new Error('Hailer session token is not defined')));
        }

        const form = new FormData();
        form.append('file', file);
        form.append('hlrkey', this.core.hlrkey);
        form.append('isPublic', options?.isPublic ? 'true' : 'false');

        const request = new HttpRequest('POST', this.uploadUrl, form, {
            reportProgress: true,
        });

        return this.http.request(request).pipe(
            filter(it => it.type === HttpEventType.Response || it.type === HttpEventType.UploadProgress),
            map(response => this.handleMessage(response))
        );
    }

    async download(file: FileModel): Promise<void> {
        try {
            if (!file) {
                throw new Error('File not defined!');
            }

            if (!file._id) {
                throw new Error('File id not defined!');
            }

            // Check if we are using Android or IOS
            if (Capacitor.isNativePlatform()) {
                if (!this.core.hlrkey) {
                    throw new Error('Hailer session token is not defined');
                }

                if (!this.androidFiles) {
                    // Initializing Android files plugin if it hasn't been yet
                    this.androidFiles = registerPlugin<HailerAndroidFiles>('HailerAndroidFiles');
                }


                await this.androidFiles.downloadFile({
                    url: this.getDownloadUrl(file._id),
                    fileName: file.name,
                    fileType: file.type,
                    hlrkey: this.core.hlrkey,
                });
                return;
            }

            // Create link element
            const link = document.createElement('a');

            // Add data to the link element
            link.target = '_blank';
            link.href = this.getDownloadUrl(file._id);

            // Click on the link element
            document.body.appendChild(link);
            link.click();

            // Remove the link element
            document.body.removeChild(link);
        } catch (error) {
            console.error('Failed to download a file!', error);
        }
    }

    getViewUrl(fileId: string): string {
        return `${this.getEnvUrl()}/file/${fileId}`;
    }

    getDownloadUrl(fileId: string): string {
        return `${this.getEnvUrl()}/file/${fileId}` + '?dl=1';
    }

    getEnvUrl(): string {
        return environment.wsUrl;
    }

    downloadFilesAsZip(fileIds: string[], archiveName: string): Observable<void> {
        // TODO: Return an Observable. Not a Subject
        const zipReady = new Subject<void>();
        if (!this.core.hlrkey) {
            return new Observable(subscriber => subscriber.error(new Error('Hailer session token is not defined')));
        }

        this.http
            .post(
                `${String(environment.wsUrl)}/files`,
                { fileIds, archiveName },
                { responseType: 'blob', headers: { hlrkey: this.core.hlrkey } }
            )
            .subscribe({
                next: data => {
                    saveAs(data, archiveName);
                    zipReady.next();
                    zipReady.complete();
                },
                error: error => {
                    console.error('Error happened while creating zip', error);
                    zipReady.next();
                    zipReady.complete();
                },
            });
        return zipReady;
    }

    async previewDocumentTemplate(
        activityId: string,
        templateId: string,
        code?: string
    ): Promise<{
        filename: string;
        sourceUrl: SafeResourceUrl;
        applicationType: string;
        csvData: string;
    }> {
        const url = `${environment.wsUrl}/documentgenerator/preview`;
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Ex. Europe/Helsinki
        const content = code;
        let filename: string;
        let sourceUrl: SafeResourceUrl;
        let applicationType: string;
        let csvData: string;

        return new Promise((resolve, reject) => {

            if (!this.core.hlrkey) {
                reject(new Error('Hailer session token is not defined'));
                return;
            }

            this.http
                .post(
                    url,
                    { activityId, templateId, timeZone, content },
                    {
                        responseType: 'blob',
                        observe: 'response',
                        headers: { hlrkey: this.core.hlrkey },
                    }
                )
                .subscribe({
                    next: async response => {
                        try {
                            let disposition: string[] = Array(2).fill('');
                            const contentDispositionHeader = response.headers.get('content-disposition');

                            if (contentDispositionHeader !== null) {
                                disposition = contentDispositionHeader.split('=');
                            }

                            const uri = disposition[1] ?? 'unknown.pdf';
                            filename = decodeURIComponent(uri);

                            if (response.body) {
                                applicationType = response.body.type;

                                if (applicationType === 'text/csv') {
                                    csvData = await new Response(response.body).text();
                                }

                                const unsafeImageUrl = URL.createObjectURL(response.body);
                                sourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
                            }
                        } catch (error) {
                            console.log('error when reading headers: ', error);
                            this.showError(error);
                        }

                        resolve({ filename, sourceUrl, applicationType, csvData });
                    },
                    error: async (error: HttpErrorResponse) => {
                        console.error('Error: ', error);
                        resolve({ filename, sourceUrl, applicationType, csvData });
                        const response = await new Response(error.error).json();
                        this.showError(response.msg);
                    },
                });
        });
    }

    async getDocumentGeneratedFile(activityId?: string, templateId?: string, saveFile?: boolean, templateMap?: { [templateId: string]: string[] }): Promise<void> {
        const url = `${environment.wsUrl}/documentgenerator`;
        const save = saveFile ? 1 : 0;
        // Ex. Europe/Helsinki
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        return new Promise((resolve, reject) => {
            if (!this.core.hlrkey) {
                reject(new Error('Hailer session token is not defined'));
                return;
            }

            this.http
                .post(
                    url,
                    { activityId, templateId, timeZone, save, templateMap },
                    {
                        responseType: 'blob',
                        observe: 'response',
                        headers: { hlrkey: this.core.hlrkey },
                    }
                )
                .subscribe({
                    next: async response => {
                        try {
                            let disposition: string[] = Array(2).fill('');
                            const contentDispositionHeader = response.headers.get('content-disposition');

                            if (contentDispositionHeader !== null) {
                                disposition = contentDispositionHeader.split('=');
                            }

                            let filename = disposition[1] ?? 'unknown.pdf';
                            filename = decodeURIComponent(filename);

                            if (Capacitor.isNativePlatform()) {
                                if (!this.androidFiles) {
                                    // Initializing Android files plugin if it hasn't been yet
                                    this.androidFiles = registerPlugin<HailerAndroidFiles>('HailerAndroidFiles');
                                }

                                this.androidFiles.saveBinary({
                                    binary: await this.blobToBase64(response.body),
                                    fileName: filename,
                                    fileType: 'application/pdf',
                                    open: true,
                                });
                                resolve();
                                return;
                            }

                            saveAs(response.body, filename);
                        } catch (error) {
                            console.log('error when reading headers: ', error);
                            this.showError(error);
                        }

                        resolve();
                    },
                    error: async (error: HttpErrorResponse) => {
                        console.error('Error: ', error);
                        resolve();
                        const response = await new Response(error.error).json();
                        this.showError(response.msg);
                    },
                });
        });
    }

    base64ToFile(data, filename) {
        const arr = data.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    blobToBase64 = blob =>
        new Promise(resolve => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });

    private showError(errorMsg: string) {
        const confirm = `Ok`;
        const content = errorMsg;
        const title = `Error`;

        this.dialogHelperService.showError(confirm, content, title).pipe(take(1)).subscribe();
    }

    private handleMessage(resp: HttpEvent<any>): UploadState {
        const uploadState: UploadState = {
            progress: 0,
            finished: false,
        };

        switch (resp.type) {
            case HttpEventType.UploadProgress: {
                if (resp.total) {
                    uploadState.progress = (100 * resp.loaded) / resp.total;
                }
                break;
            }

            case HttpEventType.Response: {
                uploadState.finished = true;
                uploadState.fileId = resp.body._id;
                break;
            }

            default:
                // Ignore all other events
                break;
        }

        return uploadState;
    }
}

export interface UploadState {
    progress: number;
    finished: boolean;
    fileId?: string;
}
