/** @format */

import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { CoreService } from 'app/_services/core.service';
import { Observable, Subscriber } from 'rxjs';

@Injectable()
export class UpdateGuard implements CanActivate {
    constructor(private core: CoreService) {}

    private static checkingUpdate = false;

    canActivate(): Observable<boolean> {
        /** Reads the version.json from cache and fetches the latest from the server, and reloads frontend if they are not the same */
        return new Observable<boolean>(observable => void this.checkForVersionAndUpdate(observable));
    }

    private async checkForVersionAndUpdate(subscriber: Subscriber<boolean>): Promise<void> {
        console.log('Checking for update.');

        try {
            if (UpdateGuard.checkingUpdate) {
                console.log('Not connected or already checking for update.', this.core.connected.value, UpdateGuard.checkingUpdate);
                subscriber.next(true);
                subscriber.complete();
                return;
            }

            UpdateGuard.checkingUpdate = true;

            const versionFile = 'version.json';
            const cache = await caches.open('version');

            const currentVersion = await (await cache.match(versionFile))?.json();
            const remoteVersionRequest = await fetch(versionFile);
            const remoteVersion = await remoteVersionRequest?.clone()?.json();

            if (!remoteVersion || currentVersion?.buildTime === remoteVersion.buildTime) {
                console.log('Missing remote version or no update available');
                UpdateGuard.checkingUpdate = false;
                subscriber.next(true);
                subscriber.complete();
                return;
            }

            console.log('Updating! Current version:', currentVersion, 'remote version:', remoteVersion);

            await cache.put(versionFile, remoteVersionRequest);

            console.log('Updated version. Reloading!');

            subscriber.next(false);
            subscriber.complete();
            await new Promise((resolve) => setTimeout(resolve, 1000));
            location.reload();
            return;
        } catch (error) {
            console.warn('Failed to check for update', error);
        }

        subscriber.next(true);
        subscriber.complete();
    }
}
