/** @format */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { filter, take, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';
import { Capacitor } from '@capacitor/core';

import { TeamService } from 'app/_services/team.service';
import { PersonalSettings } from '../../_models/personal-settings.model';
import { environment } from '@app/env';
import { Company, FeedPost, FeedPostActivity, FeedPostProcess, File } from '@app/models';
import { CoreService } from 'app/_services/core.service';
import { ImageViewerService, ViewerImage } from 'app/_services/image-viewer.service';
import { FeedService } from 'app/feed/feed.service';
import { SideNavService } from 'app/_services/side-nav.service';
import { UserDetailComponent } from 'app/people-shared/user-detail/user-detail.component';
import { FilesService } from 'app/_services/files.service';
import { V3ActivitySidenavComponent } from 'app/v3-activity/v3-activity-sidenav/v3-activity-sidenav.component';
import { FileViewComponent } from 'app/files-shared/file-view/file-view.component';
import { translateProcess } from 'app/_helpers/process-translation-helper';
import { RPCService } from 'app/_services/rpc.service';
import { TodayDatePipe } from 'app/pipes/src/today-date.pipe';
import { TranslateService } from 'app/_services/translate.service';

@Component({
    selector: 'app-feed-post',
    templateUrl: './feed-post.component.html',
    styleUrls: ['./feed-post.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: TRANSLOCO_SCOPE, useValue: { scope: 'feed', alias: 'feed' } }],
})
export class FeedPostComponent implements OnInit, OnDestroy {
    @Input() feedPost: FeedPost;
    @Input() isDialog = false;

    network: Company;
    apiUrl = environment.wsUrl;
    activityPostUsers: any[] = [];
    teamName: any;
    userName: any;
    peopleWithPermissions: any[] = [];
    people: any[] = [];
    attachments: { supportedMedia: File[]; other: File[] } = {
        supportedMedia: [],
        other: [],
    };

    announcementInitMessage;

    isWeb = Capacitor.getPlatform() === 'web';

    feedPostProcess: FeedPostProcess | undefined;

    user: BehaviorSubject<PersonalSettings>;
    feedPostTimestamp: string;
    collapsedState = true;

    private video: HTMLVideoElement;
    private onDestroy = new Subject<void>();

    constructor(
        public filesService: FilesService,
        public core: CoreService,
        public feed: FeedService,
        private imageViewerService: ImageViewerService,
        private router: Router,
        private sideNavService: SideNavService,
        private cdr: ChangeDetectorRef,
        private teamService: TeamService,
        private rpc: RPCService,
        private translocoService: TranslocoService,
        private translateService: TranslateService
    ) {}

    ngOnInit() {
        void this.setFeedPostTimestamp();
        this.user = this.core.user;
        this.network = this.core.network.value;

        this.core.permission.changed
            .pipe(
                takeUntil(this.onDestroy),
                filter(workspaceIds => workspaceIds.includes(this.feedPost.cid))
            )
            .subscribe(() => this.cdr.detectChanges());

        this.feedPostProcess = this.feedPost.initMessage?.meta?.process;
        this.video = document.createElement('video');

        // Process attachments.
        const feedPost = this.feedPost;
        if (feedPost.initMessage && feedPost.initMessage.files && feedPost._metas && feedPost._metas.files) {
            feedPost.initMessage.files.forEach(fileId => {
                const file = feedPost._metas.files[fileId];
                if (!file) {
                    console.error('File without metadata in feed post', feedPost);
                    return;
                }
                if (this.isSupportedMedia(file.type)) {
                    this.attachments.supportedMedia.push(file);
                } else {
                    this.attachments.other.push(file);
                }
            });
        }

        this.core.user.pipe(takeUntil(this.onDestroy)).subscribe({
            next: user => {
                if (!this.feedPostProcess) {
                    return;
                }

                this.feedPostProcess = translateProcess(
                    this.feedPostProcess,
                    user.preferredLanguages,
                    this.core.network.value.settings.languages || []
                );

                this.announcementInitMessage = this.feedPostProcess?.initPhaseDescription;
            },
        });

        this.feed.postUpdated.pipe(takeUntil(this.onDestroy)).subscribe({
            next: postId => {
                if (postId !== this.feedPost._id) {
                    return;
                }

                const post = this.feed.findFeedPost(postId);
                if (!post) {
                    return;
                }

                this.feedPost = post;
                this.setAnnouncementRecipients(this.feedPost.recipients);
                this.cdr.detectChanges();
            },
        });

        if (this.feedPost.recipients) {
            this.setAnnouncementRecipients(this.feedPost.recipients);
        }
    }

    ngOnDestroy() {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    viewImages(images: File[], index: number) {
        const preparedImages = images.map(image => {
            const isPicture = image.isPicture && image.type !== 'image/gif';
            const isVideo = image.isVideo;
            const preparedImage: ViewerImage = {
                url: isPicture ? `${environment.wsUrl}/image/hires/${image._id}` : `${this.apiUrl}/file/${image._id}`,
                _id: image._id,
                type: image.type,
                original: isPicture ? `${environment.wsUrl}/image/original/${image._id}` : '',
                isVideo,
                isPicture,
                name: image.name,
                downloadUrl: `${environment.wsUrl}/file/${image._id}?dl=1`,
            };
            return preparedImage;
        });

        this.imageViewerService.viewImages(preparedImages, index);
    }

    onEditClick() {
        this.feed.editingPost.next(this.feedPost._id);
    }

    isEternalDraft(): boolean {
        return !!this.feedPost.draft;
    }

    get editingPost(): boolean {
        return this.feedPost && this.feed.editingPost.value === this.feedPost._id;
    }

    async pinPost(): Promise<void> {
        const newPinStatus = !this.feedPost.pinned;
        try {
            await this.rpc.requestAsync('wall2.pin_post', [this.feedPost._id, newPinStatus]);
            this.feedPost.pinned = newPinStatus;
            this.feed.updatePostItems([this.feedPost]);
        } catch (error) {
            console.error('Failed to pin post', error);
            return;
        }
    }

    async setFeedPostTimestamp(): Promise<void> {
        await this.translateService.translationLoaded('feed');

        const todayDatePipe = new TodayDatePipe(this.translocoService, this.translateService);
        const timestamp = await todayDatePipe.transform(this.feedPost.created);
        this.feedPostTimestamp = timestamp;

        if (this.feedPost.draft) {
            this.feedPostTimestamp = this.translocoService.translate('feed.feed_post.draft');
        }

        if (this.feedPost.scheduled) {
            this.feedPostTimestamp = this.translocoService.translate('feed.feed_post.scheduled') + timestamp;
        }

        this.cdr.detectChanges();
    }

    dataRenderClick() {
        if (!this.isDialog) {
            this.sideNavService.create(V3ActivitySidenavComponent, {
                activityId: this.feedPost.initMessage.meta.activity._id,
            });
        } else {
            this.router.navigate(['activities', 'activity', this.feedPost.initMessage.meta.activity._id]);
        }
    }

    setAnnouncementRecipients(recipients: string[]) {
        this.people = [];
        if (recipients && recipients.length) {
            recipients.forEach(recipient => {
                const type = recipient.substring(0, 5);
                switch (type) {
                    case 'user_':
                        this.people.push(recipient.substring(5));
                        break;
                    case 'team_':
                        this.teamService.getTeam(recipient.substring(5))?.members?.forEach(member => this.people.push(member));
                        break;
                    case 'group':
                        break;
                    default:
                        break;
                }
            });
            this.people = this.people.filter((recipient, index, arr) => index === arr.indexOf(recipient));
            this.activityPostUsers = recipients;
        } else {
            this.people = [];
            this.activityPostUsers = [];
        }
    }

    getEnvUrl(): string {
        return environment.wsUrl;
    }

    openUserSidenav(userId: string): void {
        this.sideNavService.create(UserDetailComponent, {
            userId,
        });
    }

    openFile(fileId: string) {
        if (!fileId) {
            console.warn('No file id to open the sidenav with!');
            return;
        }

        this.sideNavService.create(FileViewComponent, {
            fileId,
        });
    }

    toggleMinimize(): void {
        this.collapsedState = !this.collapsedState;
    }

    get feedPostActivity(): FeedPostActivity {
        return this.feedPost.initMessage.meta.activity;
    }

    get isHidden(): boolean {
        return !!this.core.user.value?.globalSettings?.hiddenFeedPosts?.includes(this.feedPost._id);
    }

    get isCollapsed(): boolean {
        return this.isHidden && !!this.collapsedState && !this.isDialog;
    }

    feedPostProcessPhase(feedPostProcess: FeedPostProcess, feedPostActivity: FeedPostActivity) {
        return feedPostProcess.phases[feedPostActivity.completePhases[feedPostActivity.completePhases.length - 1]];
    }

    getImageUrl(attachment: File) {
        const isGif = attachment.type === 'image/gif';
        return this.apiUrl + (isGif ? '/file/' : '/image/hires/') + attachment._id;
    }

    get postWorkspaceName(): string | undefined {
        return this.core.networks.value[this.feedPost.cid]?.name;
    }

    get workspacesLength(): number {
        return Object.keys(this.core.networks.value).length;
    }

    private isSupportedMedia(mimeType) {
        if (mimeType.split('/')[0] === 'image') {
            return true;
        } else if (this.video.canPlayType(mimeType)) {
            return true;
        }
        return false;
    }
}
