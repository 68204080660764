/** @format */

import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    Renderer2,
    TemplateRef,
    ViewChild,
    ViewChildren,
    ViewContainerRef,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject, Subscription, combineLatest, fromEvent } from 'rxjs';
import { debounceTime, filter, pluck, take, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import * as DOMPurify from 'dompurify';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';
import { Discussion, Message } from '@app/models';
import { loadLatestMessages, clearMessages, focusToMessage, setScrollPosition } from 'app/redux/actions/message.actions';
import { CoreService, CoreStore } from 'app/_services/core.service';
import { UserService } from 'app/_services/user.service';

import { PeopleService } from 'app/people/people.service';

import { ConfirmDialogComponent } from 'app/_dialogs/confirm-dialog/confirm-dialog.component';

import { SideNavService } from 'app/_services/side-nav.service';
import { WindowListenerService } from '../../_services/window-listener.service';

import { environment } from '@app/env';
import { DialogHelperService } from 'app/_dialogs/dialog-helper.service';

import { SelectedDiscussionService } from 'app/_services/selected-discussion.service';
import { RoutingService } from 'app/_services/routing.service';
import { V3DiscussionService } from 'app/_services/v3-discussion.service';
import { EventSidenavComponent } from 'app/events-shared/event-sidenav/event-sidenav.component';
import { GroupDiscussionDetailSidenavComponent } from 'app/discussion/group-discussion-detail-sidenav/group-discussion-detail-sidenav.component';
import { UserDetailComponent } from 'app/people-shared/user-detail/user-detail.component';

import { V3ActivitySidenavComponent } from 'app/v3-activity/v3-activity-sidenav/v3-activity-sidenav.component';
import { ForwardToDiscussionsDialogComponent } from '../forward-to-discussions-dialog/forward-to-discussions-dialog.component';
import { ThemeService } from 'app/theme/theme.service';

@Component({
    selector: 'app-messages-container',
    templateUrl: './messages-container.component.html',
    styleUrls: ['./messages-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: TRANSLOCO_SCOPE, useValue: { scope: 'discussion', alias: 'discussion' } }],
})
export class MessagesContainerComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
    private static redrawCount = 0; // This is for dev purposes detecting redraw events

    @ViewChild('messagesContainer', { static: false }) messagesContainer: ElementRef;
    @ViewChild('messageMenu', { static: false }) messageMenu: TemplateRef<any>;
    @ViewChildren('message') messageElements: QueryList<ElementRef>;

    @Output() clearDiscussionVariables = new EventEmitter<boolean>();
    @Output() closeDiscussionInputMenuRequest = new EventEmitter<string>();

    newContact = this.selectedDiscussion.newContact;
    messages = this.selectedDiscussion.messages;
    participants = this.selectedDiscussion.participants;
    participantColorMap = this.selectedDiscussion.participantColorMap;
    oldestLoaded = this.selectedDiscussion.oldestLoaded;
    newestLoaded = this.selectedDiscussion.newestLoaded;
    discussion = this.selectedDiscussion.discussion;
    skeletonMessages = [
        { type: 0 },
        { type: 0 },
        { type: 0 },
        { type: 0 },
        { type: 0 },
        { type: 0 },
        { type: 0 },
        { type: 0 },
        { type: 0 },
        { type: 0 },
        { type: 1 },
        { type: 1 },
        { type: 1 },
        { type: 1 },
        { type: 1 },
        { type: 1 },
        { type: 1 },
        { type: 1 },
        { type: 1 },
        { type: 1 },
        { type: 2 },
        { type: 2 },
        { type: 2 },
        { type: 2 },
        { type: 2 },
        { type: 2 },
        { type: 2 },
        { type: 2 },
        { type: 2 },
        { type: 2 },
        { type: 3 },
        { type: 3 },
        { type: 3 },
        { type: 3 },
        { type: 3 },
        { type: 3 },
        { type: 3 },
        { type: 3 },
        { type: 3 },
        { type: 3 },
    ];
    me = this.core.user;
    focusedMessage: Message = null;
    screenWidth = window.innerWidth;
    editingMessage = this.selectedDiscussion.editingMessage;
    contextMenuMessage = this.selectedDiscussion.contextMenuMessage;
    dragging = this.selectedDiscussion.dragging;
    starredMessages: Message[] = [];

    private previousScrollPosition: number;
    private onDestroy = new Subject<void>();
    private contextMenuSubscription: Subscription;
    private messageMapSubscription = new Subscription();
    private focusMessageTimeout: NodeJS.Timeout;
    private longTouchTimer: NodeJS.Timeout;
    private overlayRef: OverlayRef | null;
    private discussionId: string;
    private willScrollToBottom = false;

    constructor(
        private zone: NgZone,
        public viewContainerRef: ViewContainerRef,
        public theme: ThemeService,
        public core: CoreService,
        private store: Store<CoreStore>,
        private cdr: ChangeDetectorRef,
        private renderer: Renderer2,
        private user: UserService,
        private people: PeopleService,
        private matDialog: MatDialog,
        private snackBar: MatSnackBar,
        private sideNav: SideNavService,
        private windowListener: WindowListenerService,
        private overlay: Overlay,
        private dialogHelper: DialogHelperService,
        private selectedDiscussion: SelectedDiscussionService,
        private routerService: RoutingService,
        private v3Discussion: V3DiscussionService,
        private translocoService: TranslocoService
    ) {}

    async ngOnInit(): Promise<void> {
        this.selectedDiscussion.scrolling
            .pipe(
                debounceTime(500),
                filter(state => state && !this.selectedDiscussion.loadingMessages),
                takeUntil(this.onDestroy)
            )
            .subscribe({
                next: () => {
                    this.selectedDiscussion.onScroll(this.messagesContainer, this.messageElements);
                },
            });

        this.windowListener.size.pipe(takeUntil(this.onDestroy), debounceTime(100)).subscribe({
            next: ({ x }) => {
                this.screenWidth = x;

                if (this.contextMenuMessage.value && !this.inMobileDiscussionView) {
                    this.contextMenuMessage.next(null);
                }

                this.cdr.detectChanges();
            },
        });

        combineLatest([this.messages, this.core.network, this.people.unknownUsers, this.core.users])
            .pipe(takeUntil(this.onDestroy), debounceTime(100))
            .subscribe({
                next: () => this.cdr.detectChanges(),
            });

        this.selectedDiscussion.scrollTo.pipe(takeUntil(this.onDestroy)).subscribe({
            next: scrollTo => {
                switch (scrollTo) {
                    case 'jumpToBottom':
                        this.jumpToBottom();
                        break;
                    case 'jumpToSentMessage':
                        this.jumpToSentMessage();
                        break;
                    case 'scrollToBottom':
                        this.scrollToBottom();
                        break;
                    default:
                        console.error(`Unexpected argument: ${scrollTo}`);
                        break;
                }
            },
        });

        this.v3Discussion.starredMessages.pipe(takeUntil(this.onDestroy)).subscribe({
            next: (data: any) => (this.starredMessages = data),
        });

        this.selectedDiscussion.openContextMenu.pipe(takeUntil(this.onDestroy)).subscribe({
            next: ({ event, message }) => {
                this.openContextMenu(event, message);
            },
        });

        this.selectedDiscussion.closeContextMenu.pipe(takeUntil(this.onDestroy)).subscribe({
            next: () => {
                this.closeContextMenu();
            },
        });

        this.selectedDiscussion.discussionChanged.pipe(takeUntil(this.onDestroy)).subscribe({
            next: newDiscussion => {
                this.messageMapSubscription.unsubscribe();

                if (typeof this.previousScrollPosition === 'number') {
                    this.store.dispatch(
                        setScrollPosition({
                            discussionId: this.discussionId,
                            scrollTop: this.previousScrollPosition,
                        })
                    );
                }

                this.discussionId = newDiscussion;

                this.messageMapSubscription = this.selectedDiscussion.messagesMap
                    .pipe(
                        debounceTime(1), // Cleans up redundant updates
                        takeUntil(this.onDestroy),
                        pluck(newDiscussion)
                    )
                    .subscribe({
                        next: data => {
                            this.selectedDiscussion.parseLoadedMessages(data, this.messagesContainer, this.messageElements);
                        },
                    });

                this.cdr.detectChanges();
            },
        });

        this.selectedDiscussion.scrollIsAtBottom = this.selectedDiscussion.isScrollAtBottom(this.messagesContainer);
        this.randomizeSkeletonMessages();
    }

    isMockUser(uid: string) {
        return this.people.isMockUser(uid);
    }

    async ngAfterViewInit(): Promise<void> {
        this.selectedDiscussion.focusMessage?.pipe(takeUntil(this.onDestroy)).subscribe({
            next: message => {
                if (!message) {
                    return;
                }

                this.focusToMessage(message.message, message.skipAnimation);
            },
        });

        // Scroll listeners for desktop and mobile
        this.renderer.listen(this.messagesContainer.nativeElement, 'scroll', () => {
            this.previousScrollPosition = this.messagesContainer.nativeElement.scrollTop;
            this.selectedDiscussion.scrolling.next(true);
        });

        // Following listeners are used for detecting long press events on mobile
        this.renderer.listen(this.messagesContainer.nativeElement, 'touchstart', event => {
            event.stopPropagation();

            if (this.contextMenuMessage.value) {
                return;
            }

            if (this.longTouchTimer) {
                clearTimeout(this.longTouchTimer);
            }

            this.longTouchTimer = setTimeout(() => {
                if (this.selectedDiscussion.scrolling.value) {
                    return;
                }

                const touchedMessage = this.messagesContainer.nativeElement.querySelector(':hover');
                const contextMenuMessage = this.messages.value
                    .filter(message => message.type === 'user')
                    .find(message => message._id === touchedMessage?.getAttribute('id'));

                this.contextMenuMessage.next(contextMenuMessage);
                if (this.messagesContainer) {
                    disableBodyScroll(this.messagesContainer);
                }
                this.cdr.detectChanges();
            }, 500);
        });

        this.renderer.listen(this.messagesContainer.nativeElement, 'touchend', () => {
            if (this.longTouchTimer) {
                clearTimeout(this.longTouchTimer);
            }
        });
        //
    }

    /* We need this to update scroll position using Angular's lifecycle hooks */
    ngAfterViewChecked() {
        if (this.selectedDiscussion.focusing.value && this.messageElements) {
            this.messageElements.toArray().forEach(e => {
                if (e.nativeElement.hasAttribute('focused-message')) {
                    this.messagesContainer.nativeElement.scrollTop = e.nativeElement.offsetTop - 200;
                    this.selectedDiscussion.focusing.next(false);
                    this.setFocusMessageTimeout();
                    this.cdr.detectChanges();
                }
            });
        }

        if (!this.messagesContainer) {
            return;
        }

        if (this.willScrollToBottom) {
            this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
            this.selectedDiscussion.scrolling.next(true);
            this.willScrollToBottom = false;
            return;
        }

        if (this.selectedDiscussion.scrollToPreviousPosition !== undefined) {
            this.messagesContainer.nativeElement.scrollTop = this.selectedDiscussion.scrollToPreviousPosition;
            this.selectedDiscussion.scrolling.next(true);
            this.selectedDiscussion.scrollToPreviousPosition = undefined;
        }
    }

    async ngOnDestroy() {
        if (typeof this.previousScrollPosition === 'number') {
            this.store.dispatch(
                setScrollPosition({
                    discussionId: this.discussionId,
                    scrollTop: this.previousScrollPosition,
                })
            );
        }

        this.onDestroy.next();
        this.onDestroy.complete();
    }

    // Forwards message data and opens the dialog
    openForwardMessageDialog(message: Message) {
        this.matDialog.open(ForwardToDiscussionsDialogComponent, {
            data: {
                title: this.translocoService.translate('discussion.messages_container.forward-message-container-header'),
                message,
            },
            width: '400px',
            panelClass: 'borderless-dialog',
        });
    }

    // Reply to the person who sent the message in a private discussion
    async replyPrivately(message: Message) {
        const userDiscussions = this.v3Discussion.getPrivate(message.uid);
        const replyDiscussionId = userDiscussions.length === 1 ? userDiscussions[0]._id : message.uid;

        await this.routerService.navigate(['discussions', replyDiscussionId]);

        /* Copy the message object so that it can be edited in the forward function in v3-discussion.service.ts
           MAT_DIALOG_DATA injection makes the data non editable for an unknown reason */
        const messageCopy: any = {};
        Object.assign(messageCopy, message);
        this.v3Discussion.forward(messageCopy, replyDiscussionId, true);
        this.selectedDiscussion.focusMessageInput.next();
    }

    /* Closes either forward or reply draft input container depending on if we emit 'reply' or 'forward'
       path: message-container -> message-view -> discussion-input */
    closeDiscussionInputMenu(closeContext: string) {
        this.closeDiscussionInputMenuRequest.emit(closeContext);
    }

    redraw() {
        MessagesContainerComponent.redrawCount += 1;
        console.log(`message view redrawn: ${MessagesContainerComponent.redrawCount} times`);
    }

    getUserName(uid: string): string {
        return this.people.getUser(uid)?.display_name;
    }

    scrollToBottom() {
        if (this.newestLoaded.value || this.selectedDiscussion.loadCount === 0) {
            this.willScrollToBottom = true;
        }
    }

    jumpToBottom() {
        const discussionId = this.discussion?.value?._id;
        if (!discussionId) {
            return void console.warn('Cannot jump to bottom, no discussion id');
        }

        this.store.dispatch(clearMessages({ discussionId }));
        this.clearDiscussionVariables.emit(false);
        this.store.dispatch(loadLatestMessages({ options: { discussionId, limit: 50 } }));
    }

    jumpToSentMessage() {
        if (!this.newestLoaded.value) {
            this.jumpToBottom();
            return;
        }

        this.messagesContainer.nativeElement.scrollTo({
            top: this.messagesContainer.nativeElement.scrollHeight,
            behavior: 'auto',
        });
    }

    editMessage(message: Message) {
        this.editingMessage.next({});
        this.editingMessage.value[message._id] = {
            editing: true,
            editMessageForm: new UntypedFormControl(DOMPurify.sanitize(message.msg)),
        };

        setTimeout(() => {
            this.selectedDiscussion.focusEditTextarea.next();
        }, 200);

        this.cdr.detectChanges();
    }

    starMessage(message: Message) {
        this.v3Discussion.starMessage(message._id);
    }

    reactToMessage(message: Message, reaction: string) {
        this.v3Discussion.v3Message
            .react(message.discussion, message._id, reaction)
            .pipe(takeUntil(this.onDestroy))
            .subscribe({
                error: error => console.error('Failed to react to message: ', error),
            });
    }

    replyToMessage(message: Message) {
        this.v3Discussion.addReply(message);
        setTimeout(() => {
            this.selectedDiscussion.focusMessageInput.next();
        }, 500);
    }

    deleteMessage(message: Message) {
        this.zone.run(() => {
            const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
                data: {
                    cancel: this.translocoService.translate('discussion.messages_container.ts_delete_message_dialog.cancel'),
                    confirm: this.translocoService.translate('discussion.messages_container.ts_delete_message_dialog.confirm_delete'),
                    content: this.translocoService.translate('discussion.messages_container.ts_delete_message_dialog.content'),
                    title: this.translocoService.translate('discussion.messages_container.ts_delete_message_dialog.title'),
                },
                width: '300px',
            });
            dialogRef.afterClosed().subscribe((confirmed: boolean) => {
                if (confirmed) {
                    this.v3Discussion.v3Message.delete(message.discussion, message._id).pipe(take(1)).subscribe();
                }
            });
        });
    }

    async copyToClipboard(message: Message) {
        await navigator.clipboard.writeText(message.msg);
        this.snackBar.open(
            this.translocoService.translate('discussion.messages_container.ts_copy_to_clipboard_text'),
            this.translocoService.translate('discussion.messages_container.ts_copy_to_clipboard_ok'),
            { duration: 2000 }
        );
    }

    /* We need a deep copy of the message object in some child components, since redux makes it immutable.
       TODO: fix immutability errors in child components that have [message] inputs */
    getDeepCopy(message: any): any {
        return JSON.parse(JSON.stringify(message));
    }

    openActivity(activityId: string, processId: string) {
        if (!this.allowActivitySidenav) {
            return;
        }

        this.sideNav.create(V3ActivitySidenavComponent, {
            activityId,
            processId,
        });
    }

    openEvent(eventId: string) {
        this.sideNav.create(EventSidenavComponent, {
            eventId,
        });
    }

    openGroup(discussion) {
        this.sideNav.create(GroupDiscussionDetailSidenavComponent, {
            discussion,
        });
    }

    toggleStarred() {
        if (!this.discussion.value) {
            return;
        }
        this.user.toggleDiscussionStarred(this.discussion.value._id);
    }

    getDiscussionType(discussion: Discussion): string {
        if (this.newContact.value) {
            return 'user';
        }

        return this.v3Discussion.getDiscussionType(discussion);
    }

    focusToMessage(message: Message, skipAnimation?: boolean) {
        if (this.selectedDiscussion.focusing.value || message.discussion !== this.discussion.value._id) {
            return;
        }

        if (!skipAnimation) {
            this.focusedMessage = message;
        }

        // Check if focus message is already in DOM and scroll to it, else do a initial load around it
        const focusMessageInView = this.messageElements.find(messageEl => messageEl.nativeElement.getAttribute('id') === message._id);
        if (focusMessageInView) {
            const scrollTop = focusMessageInView.nativeElement.offsetTop;
            if (skipAnimation) {
                this.messagesContainer.nativeElement.scrollTo({
                    top: scrollTop - 200,
                    behavior: 'auto',
                });
            } else {
                this.messagesContainer.nativeElement.scrollTo({
                    top: scrollTop - 200,
                    behavior: 'smooth',
                });
            }

            this.setFocusMessageTimeout();
            this.cdr.detectChanges();
            return;
        }

        this.selectedDiscussion.focusing.next(true);
        this.store.dispatch(clearMessages({ discussionId: this.discussion.value._id }));
        this.clearDiscussionVariables.emit(false);
        this.cdr.detectChanges();
        this.store.dispatch(
            focusToMessage({
                options: {
                    discussionId: this.discussion.value._id,
                    messageId: message._id,
                },
            })
        );
    }

    leaveDiscussion() {
        if (this.discussion.value?.private) {
            return;
        }

        const confirm = this.translocoService.translate('discussion.messages_container.ts_leave_discussion_dialog.confirm_leave');
        const content = this.translocoService.translate('discussion.messages_container.ts_leave_discussion_dialog.content');
        const title = this.translocoService.translate('discussion.messages_container.ts_leave_discussion_dialog.title');

        this.dialogHelper
            .showConfirm(confirm, content, title)
            .pipe(takeUntil(this.onDestroy))
            .subscribe({
                next: confirmed => {
                    if (confirmed) {
                        this.v3Discussion
                            .leave(this.discussion.value._id)
                            .pipe(takeUntil(this.onDestroy))
                            .subscribe({
                                next: () => this.v3Discussion.refreshViewport.next(),
                                error: err => console.error('Unable to leave discussion: ', err),
                            });
                    }
                },
            });
    }

    /**
     * Right click menu for messages
     */
    openContextMenu(event: MouseEvent, message: Message) {
        const { x, y } = event;
        this.contextMenuMessage.next(message);
        event.preventDefault();
        this.closeContextMenu();
        const positionStrategy = this.overlay
            .position()
            .flexibleConnectedTo({ x, y })
            .withPositions([
                {
                    originX: 'end',
                    originY: 'bottom',
                    overlayX: 'start',
                    overlayY: 'top',
                },
            ]);

        this.overlayRef = this.overlay.create({
            positionStrategy,
            scrollStrategy: this.overlay.scrollStrategies.close(),
        });

        this.overlayRef.attach(
            new TemplatePortal(this.messageMenu, this.viewContainerRef, {
                $implicit: message,
            })
        );

        this.contextMenuSubscription = fromEvent<MouseEvent>(document, 'click')
            .pipe(
                filter(mouseEvent => {
                    const clickTarget = mouseEvent.target as HTMLElement;
                    return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
                }),
                take(1)
            )
            .subscribe(() => this.closeContextMenu());

        this.cdr.detectChanges();
    }

    closeContextMenu() {
        this.contextMenuMessage.next(null);
        this.contextMenuSubscription?.unsubscribe();
        if (this.overlayRef) {
            this.overlayRef.dispose();
            this.overlayRef = null;
        }
        if (this.messagesContainer) {
            enableBodyScroll(this.messagesContainer);
        }
        this.cdr.detectChanges();
    }

    get noSelection(): boolean {
        return window.getSelection().isCollapsed;
    }

    openUser(userId: string) {
        this.sideNav.create(UserDetailComponent, {
            userId,
        });
    }

    getProfilePictureURL(uid: string): string {
        const baseUrl = `${environment.wsUrl}/image/square100/`;

        if (this.participants[uid]) {
            return baseUrl + this.participants[uid].default_profilepic;
        }

        return baseUrl + this.people.getUser(uid).default_profilepic;
    }

    get allowActivitySidenav(): boolean {
        const activityDiscussion = !!this.discussion.value?.linked_activity;

        return activityDiscussion;
    }

    get allowEventSidenav(): boolean {
        const eventDiscussion = !!this.discussion.value?.linked_event;
        const inCorrectNetwork = this.discussion.value?.cid === this.core.network.value?._id;

        return eventDiscussion && inCorrectNetwork;
    }

    get discussionSubject(): string {
        if (this.newContact.value) {
            return this.getUserName(this.newContact.value);
        }

        if (!this.discussion.value) {
            return;
        }

        if (this.discussion.value.private) {
            const filteredParticipants = this.participantsWithoutMe;
            return filteredParticipants[0] ? this.people.getUser(filteredParticipants[0]).display_name : 'Unknown User';
        }
        return this.discussion.value.subject;
    }

    get participantsWithoutMe(): string[] {
        if (this.newContact.value) {
            return [this.newContact.value];
        }

        return this.discussion.value.participants.filter(uid => uid !== this.me.value._id);
    }

    get inMobileDiscussionView(): boolean {
        return window.innerWidth <= 600 && !this.sidenavMode;
    }

    get sidenavMode(): boolean {
        return this.selectedDiscussion.sidenavMode;
    }

    getParticipantName(userId: string): string {
        // TODO: add new contact as an input
        if (this.newContact.value) {
            return this.getUserName(this.newContact.value);
        }

        return this.participants[userId]?.display_name;
    }

    getUsernames(userIds: string[]): string {
        return userIds.map(uid => this.participants[uid]?.display_name || this.people.getUser(uid)?.display_name).join(', ');
    }

    trackById(item: any): string {
        return item._id;
    }

    private setFocusMessageTimeout() {
        clearTimeout(this.focusMessageTimeout);
        this.focusMessageTimeout = setTimeout(() => {
            this.focusedMessage = null;
            this.cdr.detectChanges();
        }, 2000);
    }

    /**
     * Add some randomness to skeleton messages order
     */
    private randomizeSkeletonMessages() {
        for (let i = this.skeletonMessages.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const temp = this.skeletonMessages[i];
            this.skeletonMessages[i] = this.skeletonMessages[j];
            this.skeletonMessages[j] = temp;
        }
    }
}
