/*
ToDo: Delete when DigiOne changed url from /#/redirect/digione to /digione-login
Also drop sso object from all environments directly under Hailer. Only keep the once in pojects/oidc-login
*/

import { AuthConfig } from "angular-oauth2-oidc";
import { environment } from '@app/env';

export const authConfigMap: { [provider: string]: AuthConfig } = {
    digione: {
        // Url of the Identity Provider
        issuer: environment.sso.digione.issuer,
    
        // URL of the SPA to redirect the user to after login
        redirectUri: environment.sso.digione.redirectUri,
    
        // The SPA's id. The SPA is registered with this id at the auth-server
        clientId: environment.sso.digione.clientId,
    
        // Set the scope for the permissions the client should request
        // This is not in use at the moment
        scope: environment.sso.digione.scope,

        // Use the Authorization Code Flow (PKCE)
        responseType: 'code',

        // showDebugInformation: true,
    },
    // Add more providers here...
    /* digione2: {
        // Url of the Identity Provider
        issuer: environment.sso.digione2.issuer,
    
        // URL of the SPA to redirect the user to after login
        redirectUri: environment.sso.digione2.redirectUri,
    
        // The SPA's id. The SPA is registered with this id at the auth-server
        clientId: environment.sso.digione2.clientId,
    
        // Set the scope for the permissions the client should request
        // This is not in use at the moment
        scope: environment.sso.digione2.scope
    } */
}
