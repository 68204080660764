/** @format */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Activity, Process, ProcessField, ProcessFieldType } from '@app/models';
import { LinkedFromGroupActivity, SetCreateArguments, WorkflowStaticFields } from 'app/_models/v3-activity.model';
import { CoreService } from 'app/_services/core.service';
import { V3ActivityViewContextService } from 'app/v3-activity/v3-activity-view-context.service';
import { filter, Subject, takeUntil } from 'rxjs';
import { InitialPhaseIds } from '../../../../../../../test/deps/hailer-api/shared/util';

@Component({
    selector: 'app-linked-from-quick-add',
    templateUrl: './linked-from-quick-add.component.html',
    styleUrls: ['./linked-from-quick-add.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [V3ActivityViewContextService],
})
export class LinkedFromQuickAddComponent implements OnInit {
    @Input() workflowId: string;
    @Input() phaseId: string;
    @Input() fieldId: string;
    @Input() activityId: string;

    @Output() activityCreated = new EventEmitter<LinkedFromGroupActivity>();

    workflow: Process;
    fieldsWithErrors = new Set<string>();
    initializing = true;

    quickAdd = false;

    private onDestroy = new Subject<void>();
    constructor(public viewContext: V3ActivityViewContextService, private core: CoreService, private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        if (!this.workflowId || !this.fieldId || !this.activityId) {
            return;
        }

        void this.init();
    }

    get field(): ProcessField | undefined {
        return this.workflow?.fields?.[this.fieldId];
    }

    get targetWorkflowId(): string | undefined {
        return this.field?.data?.[0];
    }

    get targetFieldId(): string | undefined {
        return this.field?.modifier?.quickAdd?.targetFieldId;
    }

    valueChange(fieldId: string, value: any): void {
        if (!this.viewContext.toSave.activity.fields) {
            this.viewContext.toSave.activity.fields = {};
        }

        this.viewContext.toSave.activity.fields[fieldId] = value;
    }

    openQuickAdd(): void {
        this.quickAdd = true;
    }

    errorChange(fieldId: string, state: boolean): void {
        if (state) {
            this.fieldsWithErrors.add(fieldId);
        } else {
            this.fieldsWithErrors.delete(fieldId);
        }

        this.cdr.detectChanges();
    }

    async init(options?: { reload?: boolean }): Promise<void> {
        this.initializing = true;
        this.cdr.detectChanges();
        this.cdr.detach();

        this.fieldsWithErrors.clear();
        this.workflow = this.core.processById(this.workflowId);
        if (!this.targetFieldId) {
            console.error('Cannot find target fieldId');
            this.initializing = false;
            return;
        }

        if (options?.reload) {
            await this.viewContext.reloadSidenavInfo({ resetToSave: true });
        } else {
            await this.viewContext.initializeSidenavInfo({
                workflowId: this.targetWorkflowId,
                phaseId: this.phaseId,
            } as SetCreateArguments);
        }

        const userId = this.core.user.value?._id;
        if (this.viewContext.workflow.value?.enableMessenger && this.viewContext.workflow.value?.inviteActivityCreator && userId) {
            this.viewContext.toSave.options.followerIds = this.viewContext.toSave.options.followerIds || [];
            this.viewContext.toSave.options.followerIds.push(userId);
        }

        if (!this.viewContext.toSave.activity.fields) {
            this.viewContext.toSave.activity.fields = {};
        }

        this.viewContext.toSave.activity.fields[this.targetFieldId] = this.activityId;

        this.initializing = false;
        this.cdr.reattach();
        this.cdr.detectChanges();
    }

    async save(): Promise<void> {
        if (!this.targetWorkflowId) {
            return;
        }

        try {
            const activity: Activity = await this.viewContext.create(
                this.targetWorkflowId,
                this.viewContext.toSave.activity,
                this.viewContext.toSave.options || {}
            );
            void this.init();

            if (!activity?.currentPhase) {
                return;
            }

            const primaryDateFieldId = this.viewContext.workflow.value?.phases[activity.currentPhase]?.primaryDateField;
            const primaryNumericFieldId = this.viewContext.workflow.value?.phases[activity.currentPhase]?.primaryNumericField;
            const primaryDateField = primaryDateFieldId ? activity.fields?.find(({ id }) => id === primaryDateFieldId)?.value : undefined;
            const primaryNumericField = primaryNumericFieldId
                ? activity.fields?.find(({ id }) => id === primaryNumericFieldId)?.value
                : undefined;

            this.activityCreated.emit({
                name: activity.name,
                _id: activity._id,
                currentPhase: activity.currentPhase,
                index: 0,
                primaryDateField,
                primaryNumericField,
            });
        } catch (error) {
            console.error('Failed to quick add activity');
        }
    }

    get quickAddAvailable(): boolean {
        if (!this.fieldId || !this.workflowId || !this.activityId || !this.workflow || !this.phaseId) {
            return false;
        }

        if (!this.viewContext.initialPhaseIds.includes(this.phaseId)) {
            return false;
        }

        if (!this.field?.modifier?.quickAdd?.targetFieldId) {
            return false;
        }

        if (!this.targetWorkflowId) {
            return false;
        }

        const targetWorkflow = this.viewContext?.workflow?.value;
        if (!targetWorkflow?.nameFunctionEnabled && !targetWorkflow?.enablePredefinedName) {
            return false;
        }

        if (!targetWorkflow.fields[this.field?.modifier?.quickAdd?.targetFieldId]) {
            return false;
        }

        if (!targetWorkflow.enablePreselectedTeam || !targetWorkflow.preselectedTeam) {
            return false;
        }

        for (const targetFieldId of targetWorkflow.fieldsOrder) {
            const targetField = targetWorkflow.fields[targetFieldId];

            if (!targetField?.required) {
                continue;
            }

            if (this.field.modifier.quickAdd.fieldIds?.includes(targetFieldId)) {
                continue;
            }

            return false;
        }

        return true;
    }

    isStaticField(fieldType: ProcessFieldType): boolean {
        return WorkflowStaticFields.includes(fieldType);
    }
}
