/** @format */

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Observer, ReplaySubject, Subject, throwError } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { map, take } from 'rxjs/operators';
import { bcrypt } from 'hash-wasm';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';

import { RPCService } from './rpc.service';
import {
    AccountMap,
    Calendar,
    CalendarMap,
    Company,
    CompanyMap,
    Country,
    Device,
    DiscussionMap,
    MessageDraftMap,
    MessageMap,
    Notification,
    PersonalSettings,
    Process,
    ProcessIdMap,
    ProcessMap,
    TeamMap,
    User,
    UserMap,
} from '@app/models';
import { Invitation, InvitationMap } from 'app/_models/invitation.model';
import { Tag, TagMap } from 'app/_models/tag.model';
import { CoreSignal } from 'app/_models/coreSignal.enum';
import { CacheInvalidateMeta, MetaModule, SignalMeta } from 'app/_models/signalMeta.model';
import { environment } from '../../environments/environment';
import { setUsers } from 'app/redux/actions/users.actions';
import { setPersonalSettings } from 'app/redux/actions/personal-settings.actions';
import { syncDiscussions } from 'app/redux/actions/discussion.actions';
import { DialogHelperService } from 'app/_dialogs/dialog-helper.service';
import { LoadingProgressService } from './loading-progress.service';
import { TranslateService } from './translate.service';
import { ActivitiesUpdatedSignal } from 'app/_models/v3-activity.model';
import { V2PermissionService } from './v2-permission.service';
import { translateProcess } from 'app/_helpers/process-translation-helper';
import { Group, GroupMap } from 'app/_models/group.model';
import { AppMap } from 'app/_models/app.model';
import { V3DiscussionService } from './v3-discussion.service';

export type SessionState = 'authenticated' | 'connecting' | 'connectiontimeout' | 'login';
// TODO: make sure all async functions have try/catch

export interface CoreStore {
    personalSettings: PersonalSettings;
    messages: MessageMap;
    users: UserMap;
    discussions: DiscussionMap;
    messageDrafts: MessageDraftMap;
    messageReplys: MessageMap;
}

@Injectable({
    providedIn: 'root',
})
export class CoreService {
    /**
     * Emits the state of the application
     */
    state = new BehaviorSubject<SessionState>('connecting');

    /**
     * Emits the connection state of the web socket
     */
    connected = new BehaviorSubject<boolean>(false);

    /**
     * Emits the currently logged in user on login and on back-end signals
     */
    user = new BehaviorSubject<PersonalSettings | null>(null);

    /**
     * Emits all users
     */
    users = new BehaviorSubject<UserMap>({});

    /**
     * Emits users that has no workspaces in common
     */
    unknownUsers = new BehaviorSubject<UserMap>({});

    /**
     * Emits current network
     */
    network = new BehaviorSubject<Company | null>(null);

    /**
     * Emits all networks
     */
    networks = new BehaviorSubject<CompanyMap>({});

    /**
     * Emits all teams
     */
    teams = new BehaviorSubject<TeamMap>({});

    /**
     * Emits all groups
     */
    groups = new BehaviorSubject<GroupMap>({});

    /**
     * Emits all processes
     */
    processes = new BehaviorSubject<ProcessMap>({});

    /**
     * Emits an updated process id
     */
    processUpdated = new Subject<ProcessIdMap>();

    /**
     * Emits all users devices
     */
    devices = new BehaviorSubject<Device[]>([]);

    /**
     * Emits in-app notifications
     */
    notifications = new BehaviorSubject<Notification[]>([]);

    /**
     * Emits unseen notification count
     */
    notificationCount = new BehaviorSubject<any>(null);

    /**
     * Emits all invitations by network
     */
    invitations = new BehaviorSubject<InvitationMap>({});

    /**
     * Emits all invitations by user
     */
    userInvitations = new BehaviorSubject<{ [cid: string]: Invitation }>({});

    /**
     * Behavior subject for title unread count
     */
    calendars = new BehaviorSubject<CalendarMap>({});

    /**
     * Available tags for tagging
     */
    tags = new BehaviorSubject<TagMap>({});

    /**
     * Available tags for tagging
     */
    countries = new BehaviorSubject<Country[]>([]);

    /**
     * Emits all apps
     */
    apps = new BehaviorSubject<AppMap>({});

    /** Service for handling user permissions */
    permission: V2PermissionService;

    /**
     * This helps to prevent excess `user.load` request when fetching users
     */
    private unknownUsersLoading: { [userId: string]: boolean } = {};

    /**
     * Emits when the user is authenticated (on load / reconnection / reload etc.)
     */
    private authenticated = new ReplaySubject<void>();

    /**
     * Handler for signals
     */
    private signalHandler = {
        [CoreSignal.CompanyNewInvitation]: async () => {
            await this.updateInvitations();
        },
        [CoreSignal.CompanyReload]: async (meta: SignalMeta) => {
            try {
                if (meta.modules) {
                    const indexOfProcesses = meta.modules.indexOf(MetaModule.Processes);

                    if (indexOfProcesses > -1) {
                        meta.modules.splice(indexOfProcesses, 1);
                    }

                    this.invalidateCaches(meta.modules);
                } else {
                    this.initData();
                }
            } catch (error) {
                console.error('Error in company.reload handler', error);
                this.initData();
            }
        },
        [CoreSignal.CompanyRemoveInvitation]: async (meta: SignalMeta) => {
            await this.updateInvitations();
        },
        [CoreSignal.CompanyUpdateProcessLastActive]: (meta: SignalMeta) => {},
        [CoreSignal.DevicesNew]: async (meta: SignalMeta) => {
            this.devices.next(await this.rpc.requestAsync('devices.list'));
        },
        [CoreSignal.NotificationReload]: async (meta: SignalMeta) => {
            if (meta.notification_id) {
                this.loadNewNotifications(meta.notification_id);
            }
        },
        [CoreSignal.NetworkTags]: async (meta: any) => {
            await this.updateTags();
        },
        [CoreSignal.CacheInvalidate]: async (meta: CacheInvalidateMeta) => {
            if (meta.caches) {
                this.invalidateCaches(meta.caches);
            } else {
                Object.entries(meta.value).forEach(async ([cacheName, id]) => {
                    if (cacheName === MetaModule.Processes) {
                        this.loadWorkflow(id, meta.cid);
                    }
                    if (cacheName === MetaModule.Network) {
                        if (id !== this.network.value._id) {
                            // If core init returns different workspace as the one loaded, switch to the new one.
                            this.switchNetwork(id);
                            return;
                        }

                        this.loadWorkspace(id);
                    }
                    if (cacheName === MetaModule.Networks) {
                        this.loadWorkspace(id);
                    }
                    if (cacheName === MetaModule.Groups) {
                        const groupId = meta.value.groups;
                        if (!groupId) {
                            return;
                        }
                        const newGroup: Group = (await this.rpc.requestAsync('network.group.get', [groupId])) as Group;
                        if (!this.groups.value[this.network.value._id]) {
                            this.groups.value[this.network.value._id] = {};
                        }
                        this.groups.value[this.network.value._id][groupId] = newGroup;
                        this.updateGroups(this.groups.value);
                    }
                    if (cacheName === MetaModule.Gui) {
                        return; /* TODO: Implementation... */
                    }
                    if (cacheName === MetaModule.Tags) {
                        return; /* TODO: Implementation... */
                    }
                    if (cacheName === MetaModule.User) {
                        return; /* TODO: Implementation... */
                    }
                    if (cacheName === MetaModule.Users) {
                        const user: any = (await this.rpc.requestAsync('v2.user.profile', [id])) as Promise<User>;

                        if (user.user) {
                            const newUser = user.user;
                            const oldUser = { ...this.users.value[user.user._id] };
                            const userMap = { ...this.users.value };
                            Object.keys(newUser).forEach(key => {
                                if (key === 'profilepic') {
                                    oldUser.default_profilepic = newUser[key];
                                } else {
                                    oldUser[key] = newUser[key];
                                }
                            });
                            const updatedUser = new User(oldUser);
                            userMap[user._id] = updatedUser;
                            this.updateUsers(userMap);
                        }
                        return;
                    }
                    if (cacheName === MetaModule.Teams) {
                        return; /* TODO: Implementation... */
                    }
                    if (cacheName === MetaModule.Accounts) {
                        return; /* TODO: Implementation... */
                    }
                    if (cacheName === MetaModule.Calendars) {
                        return; /* TODO: Implementation... */
                    }
                });
            }
        },
        [CoreSignal.CalendarReload]: () => this.updateCalendars(),
        [CoreSignal.CalendarRemoved]: () => this.updateCalendars(),
        [CoreSignal.ActivitiesUpdated]: (meta: ActivitiesUpdatedSignal) => this.checkForNewWorkflow(meta),
        [CoreSignal.AppRefresh]: async () => {
            this.updateApps((await this.rpc.requestAsync('v2.core.init', [['apps']])).apps as AppMap);
        },
    };

    constructor(
        private rpc: RPCService,
        private titleService: Title,
        private store: Store<CoreStore>,
        private router: Router,
        private dialog: DialogHelperService,
        private loadingProgress: LoadingProgressService,
        private transloco: TranslocoService,
        private translate: TranslateService,
        private v3Discussion: V3DiscussionService
    ) {
        this.rpc.connectionListener.subscribe(this.handleConnect.bind(this));
        this.rpc.disconnectListener.subscribe(this.handleDisconnect.bind(this));

        this.renewPeriodically();

        this.state.subscribe(state => {
            if (state === 'authenticated') {
                this.authenticated.next();
            }
        });

        this.rpc.signals.subscribe(signal => {
            if (this.state.value !== 'authenticated') {
                // Ignore signals until in authenticated state
                return;
            }

            if (!this.signalHandler[signal.sig]) {
                // No handler for this signal, ignore
                return;
            }

            this.signalHandler[signal.sig](signal.meta);
        });

        this.v3Discussion.unreadDiscussions.subscribe({
            next: unreadDiscussions => {
                const unreadCount = Object.keys(unreadDiscussions)?.length;
                let title = 'Hailer';

                if (unreadCount > 0) {
                    title = `(${unreadCount}) ${title}`;
                }

                this.titleService.setTitle(title);
            },
        });
    }

    get hlrkey(): string | null {
        return window.localStorage.getItem('session_token');
    }

    async ready() {
        return new Promise((resolve, reject) => {
            this.authenticated.subscribe(resolve, reject);
        });
    }

    onAuthenticated(success: () => void) {
        this.authenticated.subscribe(success, err => {
            console.error('Something bad happened during authentication....', err);
        });
    }

    getState() {
        return this.state.value;
    }

    setState(state: SessionState) {
        if (state === this.getState()) {
            return;
        }

        this.state.next(state);
    }

    stateChanges() {
        return this.state;
    }

    /**
     * Log in to Hailer
     *
     * Logs in to Hailer using rpc request and sets session_id to localStorage and sets cookie hlrkey to session id.
     *
     * @param username
     * @param password
     */
    login(username: string, password: string): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.rpc.request('v3.login', [username, password]).subscribe(
                async loginResponse => {
                    this.setSessionToken(loginResponse);
                    await this.initData();

                    observer.next(true);
                    observer.complete();
                },
                error => {
                    console.error('login error:', error);
                    observer.error(error);
                }
            );
        });
    }

    /**
     * Log in to Hailer with OpenId
     *
     * Logs in to Hailer using rpc request and sets session_id to localStorage and sets cookie hlrkey to session id.
     *
     * @param idToken
     */
    loginOpenId(idToken: string): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.rpc.request('v3.loginOpenId', [idToken]).subscribe(
                async loginResponse => {
                    this.setSessionToken(loginResponse);
                    await this.initData();

                    observer.next(true);
                    observer.complete();
                },
                error => {
                    console.error('login error:', error);
                    observer.error(error);
                }
            );
        });
    }

    /**
     * Log in to Hailer with OpenId
     *
     * Logs in to Hailer using rpc request and sets session_id to localStorage and sets cookie hlrkey to session id.
     *
     * @param idToken
     */
    loginMicrosoft(idToken: string): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.rpc.request('v3.loginMicrosoft', [idToken]).subscribe(
                async loginResponse => {
                    this.setSessionToken(loginResponse);
                    await this.initData();

                    observer.next(true);
                    observer.complete();
                },
                error => {
                    console.error('login error:', error);
                    observer.error(error);
                }
            );
        });
    }

    loginToken(loginToken: string): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.rpc.request('v3.loginToken', [loginToken]).subscribe(
                async loginResponse => {
                    this.setSessionToken(loginResponse);
                    await this.initData();

                    observer.next(true);
                    observer.complete();
                    this.router.navigateByUrl('/');
                },
                error => {
                    console.error('login error:', error);
                    observer.error(error);
                }
            );
        });
    }

    async clearCache() {
        try {
            // Clear cache storage
            const keyList = await caches.keys();

            for (const key of keyList) {
                await caches.delete(key);
            }
        } catch (error) {
            console.error('error clearing cache storage');
        }
    }

    async logout() {
        try {
            const selectedLanguage = localStorage.getItem('selectedLanguage');
            const wasInDebugMode = !!localStorage.getItem('debug');
            const wasInAppsBetaMode = !!localStorage.getItem('apps-beta');

            this.clearSessionId();
            localStorage.clear();

            if (!selectedLanguage || selectedLanguage !== 'null') {
                localStorage.setItem('selectedLanguage', selectedLanguage);
            }

            if (wasInAppsBetaMode) {
                localStorage.setItem('apps-beta', 'true');
            }

            if (wasInDebugMode) {
                localStorage.setItem('debug', 'true');
                localStorage.setItem(
                    'tours',
                    '[{"id":29841,"state":"closed","name":"Test - USERS (ENG)","currentStep":0,"updatedAt":"2023-09-01T06:26:24.199Z"}]'
                );
            }

            sessionStorage.clear();
            await this.rpc.requestAsync('core.logout', []).catch(
                // Disregard not authenticated error or write to log
                error => error.code === 6 || console.log('Error logging out', error)
            );

            this.clearSessionId();

            if (wasInDebugMode) {
                this.rpc.reconnect();
                this.state.next('login');
                void this.router.navigate(['/devtools']);
            } else {
                location.reload();
            }

        } catch (error) {
            console.error('Error in logout', error);
        }
    }

    switchNetwork(cid: string): Observable<void> {
        const result = new Subject<void>();
        this.rpc.request('core.switch_ecosystem', [cid]).subscribe({
            next: () => {
                result.next();
                result.complete();
            },
            error: err => result.error(err),
        });
        return result.asObservable();
    }

    async initData(): Promise<void> {
        try {
            this.store.dispatch(syncDiscussions());

            const initData: {
                user: User;
                users: UserMap;
                network: Company;
                networks: CompanyMap;
                processes: Process[];
                teams: TeamMap;
                groups: GroupMap;
                tags: TagMap;
                accounts: AccountMap;
                onlineusers: [];
                calendars: CalendarMap;
                apps: AppMap;
            } = (await this.rpc.requestAsync('v2.core.init', [])) as any;

            this.loadingProgress.status.next({
                percentage: 60,
                label: 'Loaded Init Data',
            });

            const userData = {
                ...initData.user,
                short_name: `${initData.user.firstname.substring(0, 1)}.${initData.user.lastname}`,
                display_name: `${initData.user.firstname} ${initData.user.lastname}`,
                picture: initData.user.default_profilepic,
                id: initData.user._id,
                _id: initData.user._id,
            };

            this.setWorkspaces(initData.networks);
            this.setWorkspace(initData.network);

            /* TODO: There are several implementations of this
               Set display_name and short_name */
            Object.keys(initData.users).forEach(userId => {
                initData.users[userId] = new User(initData.users[userId]);
            });

            this.updateUsers(initData.users);
            this.teams.next(initData.teams || {});
            this.groups.next(initData.groups || {});
            this.user.next(userData as unknown as PersonalSettings);
            this.permission = await V2PermissionService.from(this, this.rpc, this.router);
            this.apps.next(initData.apps || {});
            const processMap = this.processesMapFromBackend(initData.processes);
            this.processes.next(processMap);

            void this.updateTags();
            void this.setCountries();

            /* Setting personal settings store value initial value
               This just saves on one backend call, in the future we can use
               loadPersonalSettings() within personal-settings service instead */

            /* Note: Since this is just the initial value the old personal settings (user.value)
               and the new redux values will slowly drift apart */
            this.store.dispatch(setPersonalSettings({ personalSettings: this.user.value }));

            this.devices.next(await this.rpc.requestAsync('devices.list'));

            await this.updateInvitations();

            this.notifications.next(await this.rpc.requestAsync('notification.get_notifications'));

            const unreadCount = await this.rpc.requestAsync('notification.get_unread_notification_count');
            if (unreadCount > 0) {
                this.notificationCount.next(unreadCount);
            } else {
                this.notificationCount.next(null);
            }

            this.updateCalendars(initData.calendars);
            this.setState('authenticated');

            this.loadingProgress.hideLoadingScreen(1250);
        } catch (err) {
            console.error('Error syncing with initial data: \n', err);
        }
    }

    async callChallengeEndpoint(op: string, args: any[]): Promise<Observable<any>> {
        try {
            const { challenge, difficulty } = (await this.rpc.requestAsync('v3.proofOfWork.challenge')) as any;

            const proofOfWork = await this.work(challenge, difficulty);

            return this.rpc.request('v3.proofOfWork.call', [challenge, proofOfWork, op, args]);
        } catch (error) {
            return throwError('Failed getting challenge from server.');
        }
    }

    checkExistingEmail(email: string): Promise<{ emailTaken?: boolean; invalid?: boolean }> {
        return new Promise(async (resolve, reject) => {
            const subscription = await this.callChallengeEndpoint('v3.core.checkEmail', [email]);
            subscription.subscribe({
                next: (taken: any) => {
                    if (taken) {
                        resolve({ emailTaken: true });
                        return;
                    }
                    resolve(null);
                },
                error: err => {
                    resolve({ invalid: true });
                },
            });
        });
    }

    getCountries(): Observable<any> {
        return this.rpc.request('core.get_countries', []);
    }

    getAllLanguages(): Observable<string[]> {
        return this.rpc.request('core.getLanguages', []);
    }

    loadNewNotifications(notificationId: string): void {
        this.rpc.request('notification.get_notifications', [notificationId]).subscribe({
            next: notification => {
                const copy = this.notifications.getValue();
                copy.unshift(notification);
                this.notifications.next(copy);
                this.getUnreadNotificationCount();
            },
            error: err => {
                this.notifications.error(err);
            },
        });
    }

    loadNotifications(): void {
        this.rpc.request('notification.get_notifications', [null]).subscribe({
            next: next => {
                const copy = this.notifications.getValue();
                const res = copy.concat(next);
                this.notifications.next(res);
                this.getUnreadNotificationCount();
            },
            error: error => {
                this.notifications.error(error);
            },
        });
    }

    loadMoreNotifications(): void {
        this.rpc.request('notification.get_notifications', [null, { skip: this.notifications.getValue().length }]).subscribe({
            next: next => {
                const copy = this.notifications.getValue();
                const res = copy.concat(next);
                this.notifications.next(res);
            },
            error: error => {
                this.notifications.error(error);
            },
        });
    }

    markAllNotificationsRead(): void {
        this.rpc.request('notification.mark_all_as_read').subscribe({
            next: () => this.getUnreadNotificationCount(),
            error: error => console.error('Error marking notifications as read.', error),
        });
    }

    markAllNotificationsSeen(): void {
        this.rpc.request('notification.mark_all_as_seen').subscribe({
            next: () => this.getUnreadNotificationCount(),
            error: error => console.error('Error marking notifications as seen.', error),
        });
    }

    getUnreadNotificationCount(): void {
        this.rpc.request('notification.get_unread_notification_count').subscribe({
            next: count => {
                if (count > 0) {
                    this.notificationCount.next(count);
                } else {
                    this.notificationCount.next(null);
                }
            },
            error: error => {
                this.notificationCount.error(error);
            },
        });
    }

    checkFieldSync(process: Process): boolean {
        /*
        True in this case means that we just skip the actual sync process.
        We don't want to even try to sync if we lack the permissions to do so
        */
        if (!this.user.value?.permissions.hasOwnProperty('process.set_info')) {
            return true;
        }

        if (!process.fieldsOrder || !process.fields) {
            return false;
        }

        const a = process.fieldsOrder.slice();
        const b = Object.keys(process.fields);

        // Field order can't be in sync if length is different
        if (a.length !== b.length) {
            return false;
        }

        a.sort();
        b.sort();

        // Check if sorted arrays are identical
        for (let i = 0; i < a.length; i++) {
            if (a[i] !== b[i]) {
                return false;
            }
        }
        return true;
    }

    processFromPhaseId(phaseId: string) {
        if (!phaseId) {
            return null;
        }

        const processMap = this.processes.value;

        for (const networkId of Object.keys(processMap)) {
            for (const processId of Object.keys(processMap[networkId])) {
                const process = processMap[networkId][processId];

                for (const id of Object.keys(process.phases)) {
                    if (process.phases[id]._id === phaseId) {
                        return process;
                    }
                }
            }
        }

        return null;
    }

    processById(id: string): Process {
        try {
            /* Check if process can be found from current workspace,
               this improves performance since we don't need to loop all workspaces */
            const process = this.processes.value[this.network.value?._id]?.[id];
            if (process) {
                return process;
            }

            const processMap = Object.values(this.processes.value);
            const networkProcesses = processMap.find(processes => processes[id]);
            return networkProcesses?.[id];
        } catch (err) {
            console.error('Could not read processById', err);
            return null;
        }
    }

    /**
     * 1. Fetch user from local caches (`this.users`, `this.unknownUsers`)
     * 2. If that fails try to request the user from backend with `user.load` request, cache it and return the response
     * 3. If that also fails cache and return an user object with translated first- and lastnames (Unknown User)
     */
    async getUserAsync(userId: string): Promise<User> {
        if (!userId) {
            return new User().deserialize({ firstname: 'Unknown', lastname: 'user', _id: '000000' });
        }

        const user = this.getCachedUser(userId);
        if (user) {
            return user;
        }

        return this.getUnknownUser(userId);
    }

    /**
     * Gets user locally from cache
     *
     * If user is not found,
     * temporary 'Unknown User' object is returned and `user.load` request is done in the background,
     * which the response is stored in `this.unknownUsers` cache.
     */
    getUser(userId: string): User {
        const user = this.getCachedUser(userId);
        if (user) {
            return user;
        }

        void this.getUnknownUser(userId);
        return new User({
            _id: userId,
            firstname: this.transloco.translate('misc.services.user.firstname'),
            lastname: this.transloco.translate('misc.services.user.lastname'),
        });
    }

    networkFilter<T>(_data: Observable<any>): Observable<{ [id: string]: T }> {
        return _data.pipe(
            map(data => {
                if (data[this.network.value._id]) {
                    return data[this.network.value._id];
                }
                return {};
            })
        );
    }

    async fetchAndUpdateNetworks() {
        try {
            const networks: CompanyMap = ((await this.rpc.requestAsync('v2.core.init', [['networks']])) as any).networks;
            this.setWorkspaces(networks);
        } catch (error) {
            console.error('Error in fetchAndUpdateNetworks', error);
        }
    }

    async work(challenge: string, difficulty: number): Promise<string> {
        return new Promise(resolve => {
            const hashing = async () => {
                const proofOfWork = await this.hash(challenge, difficulty);
                if (proofOfWork) {
                    resolve(proofOfWork);
                } else {
                    setTimeout(hashing, 1);
                }
            };
            hashing();
        });
    }

    renewPeriodically() {
        const fifteenMinutes = 15 * 60 * 1000;
        const renew = () => {
            if (this.state.value !== 'authenticated') {
                return;
            }

            if (this.rpc.browserInactive) {
                return;
            }

            this.renewSessionToken();
        };

        setInterval(renew, fifteenMinutes);
    }

    setSessionToken(token: string) {
        window.localStorage.setItem('session_token', token);
        const cookie =
            `hlrkey=${token};secure;domain=${this.domain()};` +
            `path=/;samesite=${environment.envName === 'awstest' ? 'none' : 'strict'};max-age=${10 * 365 * 3600 * 24};`;
        document.cookie = cookie;
    }

    /**
     * 1. Checks if user is in this.users cache
     * 2. Checks if user is in this.unknownUsers cache
     */
    private getCachedUser(userId: string): User {
        const user = this.users.value[userId];
        if (user) {
            return user;
        }

        return this.unknownUsers.value[userId];
    }

    /**
     * Does `user.load` request and stores the response in `this.unknownUsers` cache.
     *
     * If request fails it stores an user object with translated first- and lastnames (Unknown User)
     */
    private async getUnknownUser(userId: string): Promise<User> {
        if (!userId) {
            console.warn('Tried to get user with falsy id: ', userId);
            return;
        }

        await this.translate.translationLoaded('misc');
        const unknownUser = new User({
            _id: userId,
            firstname: this.transloco.translate('misc.services.user.firstname'),
            lastname: this.transloco.translate('misc.services.user.lastname'),
        });

        if (this.unknownUsersLoading[userId]) {
            return Promise.resolve(unknownUser);
        }

        this.unknownUsersLoading[userId] = true;

        let fetchedUser: User;
        try {
            fetchedUser = new User({
                _id: userId,
                ...((await this.rpc.requestAsync('user.load', [userId])) as User),
            });
        } catch (error) {
            console.error('Failed to fetch user', error);
            this.updateUnknownUsersCache(unknownUser);
            delete this.unknownUsersLoading[userId];
            return Promise.resolve(unknownUser);
        }

        this.updateUnknownUsersCache(fetchedUser);
        delete this.unknownUsersLoading[userId];

        return Promise.resolve(fetchedUser);
    }

    private updateUnknownUsersCache(user: User) {
        if (!user?._id) {
            return void console.error('Invalid unknown user!');
        }

        const currentValue = this.unknownUsers.value;
        currentValue[user._id] = user;
        this.unknownUsers.next(currentValue);
    }

    private async setCountries() {
        try {
            const countries = await this.rpc.requestAsync('core.get_countries', []);
            this.countries.next(countries || []);
        } catch (error) {
            console.error('Failed to set countries!', error);
        }
    }

    private updateUsers(users: UserMap) {
        try {
            const castUsers: UserMap = {};

            Object.values(users).forEach(user => {
                castUsers[user._id] = new User(user);
            });

            this.users.next(castUsers);
            this.store.dispatch(setUsers({ users: this.users.value }));
        } catch (error) {
            console.error('Error in updateUsers', error);
        }
    }

    private async hash(challenge: string, difficulty: number): Promise<string | null> {
        const salt = new Uint8Array(16);
        window.crypto.getRandomValues(salt);

        const hash = await bcrypt({
            password: challenge,
            salt,
            costFactor: 4,
        });

        const base64string = hash.substring(29, 35);

        try {
            atob(base64string);
        } catch (e) {
            return null;
        }

        const nonce = new DataView(Uint8Array.from(atob(base64string), c => c.charCodeAt(0)).buffer).getUint32(0);

        if (nonce < difficulty) {
            return hash;
        }
        return null;
    }

    private async updateTags() {
        const tags = (await this.rpc.requestAsync('v2.tags.list', [this.network.value._id])) as { [tig: string]: Tag };
        const value = this.tags.value;
        value[this.network.value._id] = tags;

        this.tags.next(value);
    }

    private domain() {
        if (window.location.hostname.endsWith('hailer.com')) {
            return '.hailer.com';
        }
        if (window.location.hostname.endsWith('hailer.biz')) {
            return '.hailer.biz';
        }
        if (window.location.hostname === 'localhost') {
            return 'localhost';
        }
    }

    private clearSessionId(): void {
        localStorage.removeItem('session_token');
        document.cookie = 'hlrkey=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }

    private async updateInvitations(): Promise<void> {
        const isNetworkInviter = this.network.value.members?.some(
            member => member.uid === this.user.value?._id && (member.inviter || member.owner || member.admin)
        );

        if (isNetworkInviter) {
            this.rpc.request('v2.network.invite.list', [this.network.value._id]).subscribe({
                next: success => {
                    const invMap = {};
                    success.forEach((inv: any) => {
                        if (!invMap.hasOwnProperty(inv.cid)) {
                            invMap[inv.cid] = [];
                            invMap[inv.cid].push(inv);
                        } else {
                            invMap[inv.cid].push(inv);
                        }
                    });
                    this.invitations.next(invMap);
                },
                error: error => console.error('Error in updateInvitations', error),
            });
        }

        /* Do we need another endpoint for this? We could just pipe all
           the invitations and filter out ones belonging to the user. */
        try {
            const userInvitations = (await this.rpc.requestAsync('v2.user.invite.list', [])) as Invitation[];

            const userInvMap = {};
            userInvitations.forEach(inv => {
                userInvMap[inv.cid] = inv;
            });
            this.userInvitations.next(userInvMap);
        } catch (error) {
            // Error listing own invitations, are we logged in properly?
            console.error('Error in updateInvitations', error);
        }
    }

    private updateSettings(user: PersonalSettings) {
        if (!user) {
            return;
        }

        try {
            const newValue: PersonalSettings = { ...this.user.value, ...user };
            this.user.next(newValue);
            this.store.dispatch(setPersonalSettings({ personalSettings: this.user.value }));
        } catch (error) {
            console.error('Error in updateSettings');
            this.user.error(error);
        }
    }

    private renewSessionToken() {
        const session_token = localStorage.getItem('session_token');

        if (!session_token) {
            return void this.state.next('login');
        }

        this.rpc.request('v3.resume', [session_token]).subscribe({
            next: renewed_token => {
                // Update session cookie
                this.setSessionToken(renewed_token);
            },
            error: error => {
                console.error('resume session attempt failed:', error);

                this.dialog
                    .showError(
                        this.transloco.translate('misc.services.core.dialog-error.ok'),
                        this.transloco.translate('misc.services.core.dialog-error.login'),
                        this.transloco.translate('misc.services.core.dialog-error.session_expired')
                    )
                    .subscribe({
                        next: () => {
                            this.router.navigate(['/login']);
                        },
                    });

                this.clearSessionId();

                this.state.next('login');
            },
        });
    }

    private handleConnect(): void {
        this.loadingProgress.status.next({ percentage: 15, label: 'Getting session data' });

        const sessionToken = localStorage.getItem('session_token');

        if (!sessionToken) {
            this.state.next('login');
            this.connected.next(true);
            return;
        }

        this.rpc.request('v3.resume', [sessionToken]).subscribe({
            next: async renewedToken => {
                // Update session cookie
                this.setSessionToken(renewedToken);

                await this.initData();
                // Authenticated state is now set when initData is completed
                this.connected.next(true);
            },
            error: async error => {
                console.error('error resuming session... tried session token', sessionToken, error);

                if (error.code === 127) {
                    return this.logout();
                }

                this.clearSessionId();
                this.connected.next(true);
                return void this.state.next('login');
            },
        });
    }

    private handleDisconnect() {
        this.connected.next(false);
    }

    private updateProcesses(processes: Process[]) {
        try {
            const processMap = this.processesMapFromBackend(processes);
            this.processes.next(processMap);
        } catch (error) {
            console.error('updateProcesses error', error);
        }
    }

    private updateGroups(groups: GroupMap) {
        try {
            this.groups.next(groups);
        } catch (error) {
            console.error('Error in updateGroups', error);
        }
    }

    private updateTeams(teams: TeamMap) {
        try {
            this.teams.next(teams);
        } catch (error) {
            console.error('Error in updateTeams', error);
        }
    }

    private updateApps(apps: AppMap): void {
        try {
            this.apps.next(apps);
        } catch (error) {
            console.error('Error in updateApps', error);
        }
    }

    private updateCalendars(calendars?: CalendarMap) {
        if (calendars) {
            this.calendars.next(calendars);
            return;
        }

        const isGuest = this.network.value?.members?.find(({ uid }) => uid === this.user.value?.id)?.guest;
        if (isGuest) {
            // Calendar.load_calendars endpoint will fail if user is guest
            return;
        }

        this.rpc
            .request('calendar.load_calendars', [])
            .pipe(take(1))
            .subscribe({
                next: (updatedCalendars: Calendar[]) => {
                    const calendarMap = updatedCalendars.reduce((aggregator, current) => {
                        aggregator[current._id] = current;
                        return aggregator;
                    }, {});

                    this.calendars.next({ [this.network.value._id]: calendarMap });
                },
                error: (error: any) => {
                    console.warn('Error in updateCalendars:', error);
                },
            });
    }

    private async invalidateCaches(modules: string[]) {
        /* Don't even make the request if there are
           no modules to reload. */
        if (!modules.length) {
            return;
        }

        // If teams were updated we want the newest accounts as well and vice versa
        if (modules.includes(MetaModule.Accounts) && !modules.includes(MetaModule.Teams)) {
            modules.push(MetaModule.Teams);
        }

        if (modules.includes(MetaModule.Teams) && !modules.includes(MetaModule.Accounts)) {
            modules.push(MetaModule.Accounts);
        }

        try {
            const initData: {
                calendars: CalendarMap;
                teams?: TeamMap;
                network?: Company;
                networks?: CompanyMap;
                processes?: Process[];
                user?: PersonalSettings;
                users?: UserMap;
                accounts?: AccountMap;
                groups?: GroupMap;
                apps?: AppMap;
            } = (await this.rpc.requestAsync('v2.core.init', [modules])) as any;

            if (modules.includes(MetaModule.Network)) {
                this.setWorkspace(initData.network);
            }

            if (modules.includes(MetaModule.Networks)) {
                this.setWorkspaces(initData.networks);
            }

            if (modules.includes(MetaModule.User)) {
                this.updateSettings(initData.user);
            }

            if (modules.includes(MetaModule.Calendars)) {
                this.updateCalendars();
            }

            if (modules.includes(MetaModule.Processes)) {
                this.updateProcesses(initData.processes);
            }

            if (modules.includes(MetaModule.Teams) || modules.includes(MetaModule.Accounts)) {
                this.updateTeams(initData.teams);
            }

            if (modules.includes(MetaModule.Groups)) {
                this.updateGroups(initData.groups);
            }

            if (modules.includes(MetaModule.Users)) {
                this.updateUsers(initData.users);
                await this.updateInvitations();
            }

            if (modules.includes(MetaModule.Apps)) {
                this.updateApps(initData.apps ?? {});
            }
        } catch (error) {
            console.error('Error in handleCacheInvalidate', error);
        }
    }

    private processesMapFromBackend(processes: Process[]): ProcessMap {
        try {
            const processMap: ProcessMap = {};

            processes.forEach(process => {
                processMap[process.cid] = processMap[process.cid] || {};
                processMap[process.cid][process._id] = translateProcess(
                    process,
                    this.user.value?.preferredLanguages,
                    this.network.value?.settings.languages
                );
            });

            return processMap;
        } catch (err) {
            console.error('Failed to transform processes received from backend for frontend use.', err);
            return {};
        }
    }

    /**
     * Update workflows if workflow id was not found in core.processes.
     *
     * Guest users may get activities updated signals from workflows they don't have loaded yet
     **/
    private async checkForNewWorkflow(meta: ActivitiesUpdatedSignal) {
        const workflowId = meta?.processId;
        const phaseId = meta?.phase;

        if (!workflowId && !phaseId) {
            return;
        }

        const workflowExists = workflowId ? this.processById(workflowId) : false;
        if (workflowExists) {
            return;
        }

        const phaseExists = phaseId ? this.processFromPhaseId(phaseId) : false;
        if (phaseExists) {
            return;
        }

        const coreInitResponse = (await this.rpc.requestAsync('v2.core.init', [['processes']])) as any;
        this.updateProcesses(coreInitResponse.processes);
    }

    private loadWorkflow(processId: string, cid: string) {
        this.rpc.request('v2.process.get', [processId]).subscribe({
            next: (workflow: Process | null) => {
                const workflowMap = this.processes.value || {};

                if (!workflow) {
                    delete workflowMap?.[cid]?.[processId];
                } else {
                    // Since we cannot use processTranslationServices, we use the helper function and check restrictions manually
                    workflow = translateProcess(workflow, this.user.value?.preferredLanguages, this.network.value?.settings.languages);
                    if (!workflow) {
                        return;
                    }

                    workflowMap[cid] = workflowMap[cid] || {};
                    workflowMap[cid][workflow._id] = workflow;
                }

                this.processes.next(workflowMap);
                this.processUpdated.next({ [cid]: { [processId]: processId } });
            },
            error: err => {
                if (err?.code === 404) {
                    // Process was removed
                    const processMap = this.processes.value;
                    if (processMap?.[cid]?.[processId]) {
                        delete processMap[cid]?.[processId];
                    }
                    this.processes.next(processMap);
                    return;
                }
                console.error('Error happened while fetching process', err);
            },
        });
    }

    private setWorkspace(network: Company): void {
        try {
            const lastWorkspaceId = this.network.value?._id;
            this.network.next(network);

            if (network._id !== lastWorkspaceId) {
                this.users.next(this.users.value);
                this.unknownUsers.next(this.unknownUsers.value);
                this.networks.next(this.networks.value);
                this.teams.next(this.teams.value);
                this.groups.next(this.groups.value);
                this.processes.next(this.processes.value);
                this.notifications.next(this.notifications.value);
                this.notificationCount.next(this.notificationCount.value);
                this.invitations.next(this.invitations.value);
                this.userInvitations.next(this.userInvitations.value);
                this.updateCalendars();
                this.tags.next(this.tags.value);
                this.apps.next(this.apps.value);
            }

            const isGuest = network.members?.find(({ uid }) => uid === this.user.value?.id)?.guest;
            const notInDiscussions = !location.hash.includes('/discussions');

            if (isGuest && notInDiscussions) {
                void this.router.navigate(['/discussions']);
            }
        } catch (error) {
            console.error('Error in updateNetwork', error);
        }
    }

    private setWorkspaces(networks: CompanyMap): void {
        this.networks.next(networks);
    }

    private loadWorkspace(cid: string): void {
        this.rpc.request('v2.network.get', [cid]).subscribe({
            next: network => {
                const networkMap = this.networks.value;

                if (!network) {
                    delete networkMap[cid];
                } else {
                    networkMap[cid] = network;
                }

                if (cid === this.network.value?._id) {
                    this.setWorkspace(network);
                }

                this.setWorkspaces(networkMap);
            },
            error: err => {
                if (err?.code === 404) {
                    // Network was removed
                    const networkMap = this.networks.value;
                    delete networkMap[cid];
                    this.setWorkspaces(networkMap);
                    return;
                }
                console.error('Error happened while fetching network', err);
            },
        });
    }
}
