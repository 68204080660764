<div class="modal fade right" *ngIf="userId && user && !error">
    <div class="modal-dialog modal-sm" role="document" *ngIf="user">
        <div data-cy="user-sidenav-header" class="user-dialog-top-header">
            <div class="items-flex">
                <app-user-profile-picture [userId]="user._id" [size]="80" [betterQuality]="true"></app-user-profile-picture>
                <!-- isOnline bubble not working, hiding before it is fixed -->
                <!-- <mat-icon class="not-online" [ngClass]="{'online': (isOnline)}">brightness_1</mat-icon> -->
                <h4 data-cy="user-name" class="user-dialog-user-name two-line-ellipsis">{{userName}}</h4>
            </div>
            <div class="top-bar-icons">
                <app-button *ngIf="(stackSize$ | async) > 1" (clickEvent)="popSidenav()" type="icon" color="icon-white-all-themes" hailerIconName="hailer-tailed-arrow" matIconClass="back-arrow" tippyText="{{'userdetail.go_back_tooltip' | transloco}}" ariaLabel="{{'userdetail.go-back-aria' | transloco}}"
                ></app-button>
                <app-button type="icon" [ariaLabel]="
                ((sideNavService.fullscreen | async)
                    ? 'userdetail.collapse_tooltip'
                    : 'userdetail.expand_tooltip'
                ) | transloco"
                [hailerIconName]="(sideNavService.fullscreen | async) ? 'hailer-collapse-view' : 'hailer-expand-view'"
                (clickEvent)="sideNavService.toggleFullscreen()"
                [tippyText]="
                ((sideNavService.fullscreen | async)
                    ? 'userdetail.collapse_tooltip'
                    : 'userdetail.expand_tooltip'
                ) | transloco"
                [color]="'icon-white-all-themes'"
                ></app-button>
                <app-button dataCy="close-sidenav-button" (clickEvent)="clearSidenav()" type="icon" color="icon-white-all-themes" hailerIconName="hailer-x" tippyText="{{'userdetail.close_tooltip' | transloco}}" ariaLabel="{{'userdetail.close-aria' | transloco}}"></app-button>
            </div>
        </div>
        <mat-tab-group #tabGroup color="accent" mat-align-tabs="center" (selectedTabChange)="tabChanged($event)"
            animationDuration="0">
            <ng-container *ngIf="user !== undefined">
                <mat-tab *ngIf="unknownUser">
                    <ng-template mat-tab-label>
                        <mat-icon tippy="{{'userdetail.user_info' | transloco}}">person</mat-icon>
                    </ng-template>
                    <h3>
                        {{unknownUser}}
                    </h3>
                </mat-tab>
                <mat-tab *ngIf="!unknownUser" label="User Info">
                    <ng-template mat-tab-label>
                        <mat-icon tippy="{{'userdetail.user_info' | transloco}}">person</mat-icon>
                    </ng-template>
                    <ng-container>
                        <div [formGroup]="editPropertiesForm" class="tab-container">
                            <div class="user-dialog-header">
                                <mat-icon>info_outline</mat-icon>
                                <h3>{{'userdetail.user_info' | transloco}}</h3>
                            </div>
                            <div data-cy="user-title-field" [ngClass]="{'network-fields-edit': ((permissions.isNetworkAdmin || isItMe) && enableEditProperties)}"
                                mat-button (click)="editField('', 'title')" class="user-details"
                                *ngIf="!(permissions.isNetworkAdmin || isItMe) || editingField !== 'title'">
                                <p>{{'userdetail.title' | transloco}}</p><span class="profile-text-overflow">{{userTitle}}</span>
                                <div style="float: right; margin-left: auto;"
                                    *ngIf="((permissions.isNetworkAdmin || isItMe) && enableEditProperties)">
                                    <mat-icon svgIcon="hailer-settings"></mat-icon>
                                </div>
                            </div>
                            <div *ngIf="editingField === 'title' && enableEditProperties" class="user-details">
                                <p>{{'userdetail.title' | transloco}}</p>
                                <div class="network-field-container">
                                    <div class="network-field">
                                        <div class="fill-field-container">
                                            <mat-form-field appearance="fill">
                                                <input matInput type="text" formControlName="title" placeholder="{{'userdetail.user_title' | transloco}}">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="network-field-buttons">
                                        <button mat-icon-button (click)="clearEditField()" class="clear">
                                            <mat-icon>clear</mat-icon>
                                        </button>
                                        <button mat-icon-button (click)="saveUserTitle()" class="save">
                                            <mat-icon>done</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <mat-divider></mat-divider>
                            <div class="user-details">
                                <p>{{'userdetail.last_seen' | transloco}}</p>
                                <ng-container *ngIf="lastSeen">
                                    <span class="profile-text-overflow">{{lastSeen | date :'dd/MM/yy HH:mm:ss'}}</span>
                                </ng-container>
                                <ng-container *ngIf="!lastSeen">
                                    <span class="profile-text-overflow">{{'userdetail.not_set' | transloco}}</span>
                                </ng-container>
                            </div>
                            <mat-divider></mat-divider>
                            <div data-cy="user-status-field" class="user-details"> 
                                <p>{{'userdetail.status' | transloco}}</p><span class="profile-text-overflow">{{userStatus}}</span>
                            </div>
                            <mat-divider></mat-divider>
                            <div class="user-details">
                                <p>{{'userdetail.location' | transloco}}</p><span class="profile-text-overflow">{{userLocation}}</span>
                            </div>
                            <mat-divider></mat-divider>
                            <div data-cy="workspace-owner-field" *ngFor="let field of userFields">
                                <div class="user-info-field" *ngIf="editingField !== field._id"
                                    [ngClass]="{'network-fields-edit': ((permissions.isNetworkAdmin || isItMe) && field.filledBy !== 'user' || isItMe) && enableEditProperties}"
                                    mat-button (click)="editField(field)">
                                    <div style="width: 100%;" *ngIf="propertyFieldValue(user._id, field._id)">
                                        <div [ngClass]="{'settings-page-info': (permissions.isNetworkAdmin || isItMe) && enableEditProperties}"
                                            *ngIf="field.type === 'date'">
                                            <div class="user-details">
                                                <p>{{field.label}}</p>
                                                <span
                                                    *ngIf="propertyFieldValue(user._id, field._id); else notSet"
                                                    class="profile-text-overflow">
                                                    {{propertyFieldValue(user._id, field._id) | date:'dd/MM/yy'}}
                                                </span>
                                            </div>
                                        </div>

                                        <div [ngClass]="{'settings-page-info': (permissions.isNetworkAdmin || isItMe) && enableEditProperties}"
                                            *ngIf="field.type !== 'date'">
                                            <div class="user-details">
                                                <p>{{field.label}}</p>
                                                <ng-container *ngIf="propertyFieldValue(user._id, field._id); else notSet">
                                                    <span *ngIf="field.type !== 'team'; else teamField" class="profile-text-overflow">
                                                        {{propertyFieldValue(user._id, field._id)}}
                                                    </span>
                                                    <ng-template #teamField>
                                                        <span *ngIf="field.type === 'team'" class="profile-text-overflow">
                                                            {{getFieldTeams(field) || 'Not set'}}
                                                        </span>
                                                    </ng-template>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>

                                    <ng-template #notSet>
                                        <span class="profile-text-overflow">
                                            {{'userdetail.not_set' | transloco}}
                                        </span>
                                    </ng-template>

                                    <div *ngIf="!propertyFieldValue(user._id, field._id)">
                                        <div class="user-details">
                                            <p>{{field.label}}</p><span>{{'userdetail.not_set' | transloco}}</span>
                                        </div>
                                    </div>
                                    <div class="edit-field-indicator"
                                        *ngIf="((permissions.isNetworkAdmin || isItMe) && field.filledBy !== 'user' || isItMe) && enableEditProperties">
                                        <mat-icon svgIcon="hailer-settings"></mat-icon>
                                    </div>
                                </div>
                                <div *ngIf="editingField === field._id" class="user-details">
                                    <p>{{field.label}}</p>
                                    <app-network-settings-input-fields (emitSaved)="networkFieldSave($event)"
                                        [userId]="user._id" [fieldId]="field._id">
                                    </app-network-settings-input-fields>
                                </div>
                                <mat-divider></mat-divider>
                            </div>

                            <div data-cy="workspace-owner-field" class="user-details">
                                <ng-container *ngIf="(enableEditProperties && permissions.isNetworkOwner && !isItMe); else defaultContainer">
                                    <!-- possible to edit and {{user?.display_name}} {{(isUserOwner | async) ? 'is owner' : 'is not an owner'}} -->
                                    <p>{{'userdetail.workspace_owner' | transloco}}</p>
                                    <span class="profile-text-overflow">
                                        <mat-checkbox (change)="updateUserOwnerStatus($event, user._id)" [checked]="(isUserOwner | async)">
                                        </mat-checkbox>
                                    </span>
                                </ng-container>
                                <ng-template #defaultContainer>
                                    <ng-container *ngIf="(isUserOwner | async)">
                                        <p>{{'userdetail.workspace_owner' | transloco}}</p>
                                        <span class="profile-text-overflow">{{user?.firstname}}{{'userdetail.is_workspace_owner' | transloco}}</span>
                                    </ng-container>
                                </ng-template>
                            </div>
                            <mat-divider></mat-divider>
                            <div>
                                <div class="user-dialog-header">
                                    <mat-icon>people</mat-icon>
                                    <h3>{{'userdetail.teams' | transloco}}</h3>
                                    <!-- TODO: Team selector -->
                                    <div *ngIf="enableEditProperties" class="add-teams-container">
                                        <mat-form-field class="team-selector" appearance="outline">
                                            <mat-select placeholder="{{'userdetail.teams' | transloco}}" (openedChange)="addUserToTeams($event)" formControlName="teams" [multiple]="true">
                                                <mat-option *ngFor="let team of filteredTeams" [value]="team._id">
                                                    {{team.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>


                                        <!-- <button mat-icon-button (click)="addUserToTeams()">
                                            <mat-icon color="accent">done</mat-icon>
                                        </button> -->
                                    </div>
                                </div>
                                <div class="team-container">
                                    <ng-container *ngFor="let team of userTeams">
                                        <div class="user-dialog-team">
                                            <p class="user-dialog-team-name teams-text-overflow">{{team.name}}</p>
                                            <span>{{team.members.length}}<mat-icon color="accent">person</mat-icon>
                                            </span>
                                            <button
                                                *ngIf="permissions.isNetworkAdmin && enableEditProperties"
                                                mat-icon-button (click)="removeUserTeam(team._id)">
                                                <mat-icon color="warn">clear</mat-icon>
                                            </button>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <button *ngIf="removeUserPermission() && !isManagedUser" style="margin: 32px auto;"
                                mat-flat-button (click)="$event.stopPropagation(); removeUser(user)" color="warn">
                                {{'userdetail.remove_user' | transloco}}
                            </button>
                            </div>
                    </ng-container>
                </mat-tab>
                <mat-tab *ngIf="user.lastLocation" label="Location">
                    <ng-template mat-tab-label>
                        <mat-icon tippy="{{'userdetail.location' | transloco}}">location_on</mat-icon>
                    </ng-template>
                    <ng-container *ngIf="user.lastLocation">
                        <app-gmap #gmap [config]="mapConf"></app-gmap>
                    </ng-container>
                    <div class="big-text" *ngIf="!user.lastLocation">{{'userdetail.no_location_data' | transloco}}</div>
                </mat-tab>
                <ng-container
                    *ngIf="
                        !disableMessenger &&
                        (discussion ||
                            (core.permission.discussion.start.private(
                                user._id,
                                user.networks | workspaceIdsToWorkspaces,
                                teamService.getUserTeamsFromAllWorkspaces(user._id)
                            ) | hasPermission))
                    ">
                    <mat-tab label="Discussion" *ngIf="personalSettings?.id !== user._id">
                        <ng-template mat-tab-label>
                            <mat-icon tippy="{{'userdetail.discussion' | transloco}}" svgIcon="hailer-messenger"></mat-icon>
                        </ng-template>
                        <ng-container>
                            <app-message-view [discussionId]="discussion?._id" [sidenavMode]="true" (newDiscussion)="selectNewDiscussion($event)"></app-message-view>
                        </ng-container>
                    </mat-tab>
                </ng-container>

                <mat-tab label="Files">
                    <ng-template mat-tab-label>
                        <mat-icon data-cy="files-tab" tippy="{{'userdetail.files' | transloco}}" class="attachments-icon">attach_file</mat-icon>
                    </ng-template>
                    <ng-template matTabContent>

                        <div [formGroup]="chatMediaSearchForm" class="default-search-bar">
                            <mat-form-field appearance="outline">
                                <div matPrefix>
                                    <mat-icon>search</mat-icon>
                                </div>
                                <input placeholder="{{'userdetail.search_chat_media' | transloco}}" matInput formControlName="searchInput" [appAutofocus]="false">
                            </mat-form-field>
                        </div>
                        <ng-container *ngIf="!discussionAttachments[0] && !links[0]">
                            <h2>{{'userdetail.no_links_attachments' | transloco}}</h2>
                        </ng-container>
                        <mat-accordion multi="true">
                            <mat-expansion-panel *ngIf="discussionAttachments[0]" [expanded]="true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="flex-row--space-between align-items-center title-text">
                                        <div class="chat-media-header">
                                            <h2>{{'userdetail.chat_media' | transloco}}</h2>
                                            <div class="chat-media-counter">
                                                <mat-icon color="accent">list</mat-icon>
                                                <p>{{filteredAttachments.length}}</p>
                                            </div>
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div *ngIf="discussion !== null">
                                    <mat-list class="file-list">
                                        <mat-list-item data-cy="user-sidenav-files-tab-attachment-item" class="attachment-list-item"
                                            *ngFor="let file of filteredAttachments">
                                            <mat-icon mat-list-icon style=" color: #6F6F6F;">perm_media
                                            </mat-icon>
                                            <div class="file-name-overflow" (click)="openFileSideNav(file._id)">
                                                <span mat-line class="file-name">{{file.name}}</span>
                                                <span mat-line style="font-size: 12px;">{{'userdetail.added_by' | transloco}}
                                                    {{getAttachmentUser(file.uid)}}</span>
                                                <span style="font-size: 12px;">{{'userdetail.date' | transloco}}:
                                                    {{file.created | date: 'short'}}</span>
                                            </div>
                                            <div class="file-options-container">
                                                <span class="file-buttons-container">
                                                    <button mat-icon-button color="accent" class="download-button">
                                                        <a (click)="filesService.download(file)">
                                                            <mat-icon color="accent">file_download</mat-icon>
                                                        </a>
                                                    </button>
                                                </span>
                                                <span style="font-size: 12px;" class="FileBites">{{file.length | filesize}}</span>
                                            </div>
                                            <mat-divider></mat-divider>
                                        </mat-list-item>
                                    </mat-list>
                                </div>
                            </mat-expansion-panel>

                            <mat-expansion-panel *ngIf="links[0]">
                                <mat-expansion-panel-header>
                                    <div class="flex-row--space-between align-items-center title-text">
                                        <div class="chat-media-header">
                                            <h2>{{'userdetail.links' | transloco}}</h2>
                                            <div class="chat-media-counter">
                                                <mat-icon color="accent">list</mat-icon>
                                                <p>{{filteredLinks.length}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </mat-expansion-panel-header>
                                <div *ngFor="let link of filteredLinks" class="link-container"
                                    [ngClass]="link.from === personalSettings?.id ? 'my-message' : 'other-message'">
                                    <p>{{getUserDisplayName(link.from)}}</p>
                                    <a target="_blank" href="{{link.link}}"
                                        title="{{link.date | date: 'dd.MM.yyyy, HH:mm'}}">{{link.link}}</a>
                                    <p>{{link.date | date: 'dd.MM.yyyy'}}</p>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </ng-template>
                </mat-tab>
            </ng-container>
        </mat-tab-group>
    </div>
</div>
<div *ngIf="!userId || error">
    <div class="user-dialog-top-header">
        <div class="items-flex">
            <h4 class="user-dialog-user-name">{{'userdetail.error_opening_user' | transloco}}</h4>
        </div>
        <div class="top-bar-icons">
            <button mat-icon-button class="clear-btn" (click)="clearSidenav()">
                <mat-icon>close</mat-icon>
            </button>
            <button mat-icon-button (click)="popSidenav()" class="header-button back-button clear-btn"
                *ngIf="(stackSize$ | async) > 1">
                <mat-icon>arrow_back</mat-icon>
            </button>
        </div>
    </div>
</div>
