/** @format */

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';

import { environment } from '@app/env';
import { CoreService } from 'app/_services/core.service';
import { User } from '@app/models';
import { PeopleService } from 'app/people/people.service';

// Add alt to img element and tooltip with users name on hover?

@Component({
    selector: 'app-user-profile-picture',
    templateUrl: './user-profile-picture.component.html',
    styleUrls: ['./user-profile-picture.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfilePictureComponent implements OnInit, OnChanges, OnDestroy {
    @Input() userId: string;
    @Input() size: number;
    @Input() betterQuality = false;

    user: User;
    baseUrl = `${environment.wsUrl}/image/square100/`;
    baseUrlQuality = `${environment.wsUrl}/image/square200/`;
    fallbackImg = '../assets/img/default-profile-picture.jpeg';

    private error = false;
    private onDestroy = new Subject<void>();

    constructor(private core: CoreService, private cdr: ChangeDetectorRef, private peopleService: PeopleService) {}

    ngOnInit() {
        if (this.userId) {
            void this.initProfilePicture(this.userId);
        }
        this.core.users.pipe(takeUntil(this.onDestroy)).subscribe({
            next: () => this.detectChanges(),
        });
        this.peopleService.unknownUsers.pipe(takeUntil(this.onDestroy)).subscribe({
            next: () => {
                this.user = this.peopleService.getUser(this.userId);
                this.detectChanges();
            },
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes?.userId?.currentValue) {
            return;
        }

        void this.initProfilePicture(changes.userId.currentValue);
    }

    onError() {
        this.detectChanges(true);
    }

    get imageUrl(): string {
        const url = this.betterQuality ? this.baseUrlQuality : this.baseUrl;
        const id = this.user?.default_profilepic ? this.user.default_profilepic : 'default-profile-picture.jpeg';
        return this.error ? this.fallbackImg : url + id;
    }

    private async initProfilePicture(userId: string) {
        this.user = await this.core.getUserAsync(userId);
        this.detectChanges();
    }

    private detectChanges(error = false) {
        this.error = error;
        this.cdr.detectChanges();
    }
}
