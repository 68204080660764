/** @format */

import { Injectable, OnDestroy } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult, RedirectRequest, AccountInfo, PublicClientApplication, BrowserCacheLocation } from '@azure/msal-browser';
import { CoreService } from './core.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, firstValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';

@Injectable({
    providedIn: 'root',
})
export class AuthService implements OnDestroy {
    entraLoginError: string;
    isMobile: boolean = Capacitor.isNativePlatform();

    // Observable status tracker
    private statusSubject = new Subject<boolean>();
    status$ = this.statusSubject.asObservable();

    private idToken: string | null = null;
    private onDestroy = new Subject<void>();

    constructor(private msalService: MsalService, private auth: CoreService, private router: Router) {}

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    // Only for web
    loginPopup(): Promise<AuthenticationResult | null> {
        const loginRequest = {
            scopes: ['openid', 'profile', 'User.Read'],
        };

        // Convert to Promise using firstValueFrom
        return firstValueFrom(this.msalService.loginPopup(loginRequest));
    }

    // Only for web
    logout(): void {
        this.msalService.logoutPopup();
    }

    isLoggedIn(): boolean {
        return this.msalService.instance.getActiveAccount() !== null;
    }

    async getIdToken(): Promise<string | null> {
        if (!this.idToken) {
            const account: AccountInfo | null = this.msalService.instance.getActiveAccount();
            if (account) {
                try {
                    const tokenResponse: AuthenticationResult = await this.msalService.instance.acquireTokenSilent({
                        account: account,
                        scopes: ['openid', 'profile', 'User.Read'],
                    });
                    this.idToken = tokenResponse.idToken;
                    return this.idToken;
                } catch (error) {
                    console.error('Error acquiring token silently:', error);
                    return null;
                }
            }
        }
        return this.idToken;
    }

    // Used with loginPopup
    async processLogin(result: AuthenticationResult): Promise<void> {
        if (result) {
            this.msalService.instance.setActiveAccount(result.account);
            this.idToken = result.idToken;

            try {
                await this.loginMicrosoft(result.idToken);
            } catch (error) {
                this.entraLoginError = error.msg;
            }
        }
    }

    async loginMicrosoft(idToken: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.auth
                .loginMicrosoft(idToken)
                .pipe(takeUntil(this.onDestroy))
                .subscribe({
                    next: () => {
                        this.clearLocalStorage();
                        resolve(true);
                    },
                    error: error => {
                        this.entraLoginError = error.msg;  // Handle the error
                        resolve(false);
                    },
                });
        });
    }

    async configureMsalInstance(clientId: string, tenantId: string): Promise<void> {
        const msalInstance = new PublicClientApplication({
            auth: {
                clientId,
                authority: `https://login.microsoftonline.com/${tenantId}`,
                redirectUri: this.isMobile ? 'msauth://hailer.com.hailer/VzSiQcXRmi2kyjzcA%2BmYLEtbGVs%3D' : '/',
                postLogoutRedirectUri: this.isMobile ? 'msauth://hailer.com.hailer/VzSiQcXRmi2kyjzcA%2BmYLEtbGVs%3D' : '/',
            },
            cache: {
                cacheLocation: BrowserCacheLocation.LocalStorage,
                storeAuthStateInCookie: false,
            },
        });

        await msalInstance.initialize();
        this.msalService.instance = msalInstance;
    }

    setIsIdTokenSet(isIdTokenSet: string): void {
        localStorage.setItem('isIdTokenSet', isIdTokenSet);
    }

    getIsIdTokenSet(): string | null {
        return localStorage.getItem('isIdTokenSet');
    }

    setIsEntraLoginInProgress(isEntraLoginInProgress: string): void {
        localStorage.setItem('isEntraLoginInProgress', isEntraLoginInProgress);
    }

    getIsEntraLoginInProgress(): string | null {
        return localStorage.getItem('isEntraLoginInProgress');
    }

    clearLocalStorage(): void {
        localStorage.removeItem('isIdTokenSet');
        localStorage.removeItem('isEntraLoginInProgress');
    }

    updateStatus(status: boolean): void {
        this.statusSubject.next(status);
    }
}
