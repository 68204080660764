/** @format */

export const environment = {
    envName: 'awsprod',
    production: true,
    deployed: true,
    wsUrl: 'https://api.hailer.com',
    wsOptions: {
        secure: true,
        transports: ['websocket'],
        reconnectionDelay: 1000,
        reconnectionDelayMax: 3000,
        randomizationFactor: 0.9,
        timeout: 20000,
    },

    googleMapsApiKey: 'AIzaSyA0FiBJmYhXEZ4BIjFxpvrq21tO-DT-kSk',
    googleClientId: '255264651871-v401g75kd86dtbe4sg2hvb6g5k8e2d8f.apps.googleusercontent.com',
    vapidKey: 'BKzb6B5fZ0CrNnO0c8HBvhLoqMDviVD76n6iBkee-6Gq2O5SDcg4_kB4M4L9WP1V9sGZGjytvv8lv4RAKdmxM1A',
    stripeApiKey: 'pk_live_uGaeLYLGSdJoo9SUeiG8fMMl00N1W6Pz8a',
    finndentProcessIds: ['222222222222222222222222'], // Hide in production for now, set it back to: 5587ddb76b9fbf116bbce187
    finndentPhaseIds: ['585926dabb861f031e7534b9', '5587ddcb6b9fbf116bbce188', '5587ddf06b9fbf116bbce189'],
    finndentUrl: 'https://custom.hailer.com/finndent/units/',
    formUrl: 'https://form.hailer.com/',
    jitsiMeetUrl: 'https://meet.jit.si/Hailer_',
    sso: {
        digione: {
            issuer: 'https://id-test-12.kuntien.fi',
            redirectUri: 'https://app.hailer.com/digione-login',
            clientId: '7131150735118902',
            scope: 'openid profile',
        },
    },
};
