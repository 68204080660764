/** @format */

import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FullCalendarModule } from '@fullcalendar/angular';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';

import { OAuthModule } from 'angular-oauth2-oidc';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { AuthGuard } from './_guards/auth.guard';
import { FrontPageGuard } from './_guards/frontpage.guard';

import { DialogModule } from './_dialogs/dialog.module';

import { PublicModule } from './public/public.module';
import { CoreModule } from './core/core.module';
import { GlobalErrorHandler } from './_helpers/error-global';

import { messageReducer } from './redux/reducers/message.reducer';
import { discussionReducer } from './redux/reducers/discussion.reducer';
import { usersReducer } from './redux/reducers/users.reducer';

import { DiscussionEffects } from './redux/effects/discussion.effects';
import { MessageEffects } from './redux/effects/messages.effects';
import { personalSettingsReducer } from './redux/reducers/personal-settings.reducer';
import { PersonalSettingsEffects } from './redux/effects/personal-settings';
import { messageDraftReducer } from './redux/reducers/message-draft.reducer';
import { MessageDraftEffects } from './redux/effects/message-draft.effects';
import { environment } from '@app/env';
import { V3ActivityModule } from './v3-activity/v3-activity.module';

import { PlatformModule } from '@angular/cdk/platform';
import { NgChartsModule } from 'ng2-charts';

import { ButtonsModule } from './buttons/buttons.module';

import { AppModule as AppsModule } from './app/app.module';

import {
    MsalModule,
    MsalService,
    MSAL_INSTANCE,
    MsalGuardConfiguration,
    MsalInterceptorConfiguration,
    MsalInterceptor,
} from '@azure/msal-angular';
import { IPublicClientApplication, PublicClientApplication, InteractionType, LogLevel } from '@azure/msal-browser';
import { Capacitor } from '@capacitor/core';
import { UpdateGuard } from './_guards/update.guard';

export function msalInstanceFactory(): IPublicClientApplication {
    // This will be overwritten in the application from auth.service.ts method configureMsalInstance.
    const clientId = 'bdb3cb25-db3a-4da0-bde9-169c65ff30bc';
    const tenantId = '78fa02b8-023a-48ea-ad04-66965b1e30bg';

    return new PublicClientApplication({
        auth: {
            clientId: clientId,
            authority: `https://login.microsoftonline.com/${tenantId}`,
            redirectUri: Capacitor.isNativePlatform() ? 'msauth://hailer.com.hailer/VzSiQcXRmi2kyjzcA%2BmYLEtbGVs%3D' : '/',
            postLogoutRedirectUri: Capacitor.isNativePlatform() ? 'msauth://hailer.com.hailer/VzSiQcXRmi2kyjzcA%2BmYLEtbGVs%3D' : '/',
        },
        cache: {
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: true,
        },
        system: {
            loggerOptions: {
                loggerCallback: (level, message, containsPii) => {
                    if (containsPii) {
                        return;
                    }
                    switch (level) {
                        case LogLevel.Error:
                            console.error(message);
                            return;
                        case LogLevel.Info:
                            // console.info(message);
                            return;
                        case LogLevel.Verbose:
                            // console.debug(message);
                            return;
                        case LogLevel.Warning:
                            console.warn(message);
                            return;
                        default:
                            break;
                    }
                },
            },
        },
    });
}

export function msalGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: ['openid', 'profile', 'User.Read'],
        },
    };
}

export function msalInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['User.Read']);

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap,
    };
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        AppRoutingModule,
        AppsModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatSnackBarModule,
        DialogModule,
        PublicModule,
        CoreModule,
        NgChartsModule,
        FullCalendarModule,
        MatProgressSpinnerModule,
        StoreModule.forRoot({
            discussions: discussionReducer,
            messages: messageReducer,
            users: usersReducer,
            personalSettings: personalSettingsReducer,
            messageDrafts: messageDraftReducer,
        }),
        EffectsModule.forRoot([DiscussionEffects, MessageEffects, PersonalSettingsEffects, MessageDraftEffects]),
        ...(!environment.production
            ? [
                  StoreDevtoolsModule.instrument({
                      maxAge: 25,
                      logOnly: false, // Restrict extension to log-only mode (set to environment.production)
                  }),
              ]
            : []),
        OAuthModule.forRoot(),
        V3ActivityModule,
        PlatformModule,
        ButtonsModule,
        MsalModule.forRoot(
            msalInstanceFactory(), // Call the function to get the instance
            msalGuardConfigFactory(),
            msalInterceptorConfigFactory()
        ),
    ],
    providers: [
        AuthGuard,
        FrontPageGuard,
        UpdateGuard,
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: MSAL_INSTANCE, useFactory: msalInstanceFactory },
        { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
        MsalService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
