/** @format */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, debounceTime, takeUntil } from 'rxjs';

import { CoreService } from 'app/_services/core.service';
import { Message, Process, Team } from '@app/models';
import { TeamService } from 'app/_services/team.service';

@Component({
    selector: 'app-message-activity-update',
    templateUrl: './message-activity-update.component.html',
    styleUrls: ['./message-activity-update.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageActivityUpdateComponent implements OnInit, OnDestroy {
    @Input() message: Message;
    @Input() process: Process;

    updates = [];
    teamMap: { [teamId: string]: Team } = {};

    private onDestroy = new Subject<void>();
    constructor(private teamService: TeamService, private core: CoreService, private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        this.setUpdates();

        this.core.unknownUsers.pipe(takeUntil(this.onDestroy), debounceTime(200)).subscribe({
            next: () => this.cdr.detectChanges(),
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    getTeamName(teamId: string): string {
        return this.teamService.getTeam(teamId)?.name || 'n/a';
    }

    getFieldFileCount(value: string): number {
        try {
            return JSON.parse(value)?.length || 0;
        } catch {
            return 0;
        }
    }

    async setTeam(data: any) {
        const oldTeamId = data.old;
        const newTeamId = data.new;

        if (oldTeamId) {
            let oldTeam;
            try {
                oldTeam = await this.teamService.loadTeam(oldTeamId);
            } catch (error) {
                console.log('Error loading team: ', error);
            }
            if (oldTeam) {
                this.teamMap[oldTeamId] = oldTeam;
            }
        }

        if (newTeamId) {
            let newTeam;
            try {
                newTeam = await this.teamService.loadTeam(newTeamId);
            } catch (error) {
                console.log('Error loading team: ', error);
            }
            if (newTeam) {
                this.teamMap[newTeamId] = newTeam;
            }
        }
        this.cdr.detectChanges();
    }

    getUserName(userId: string): string {
        return this.core.getUser(userId)?.display_name;
    }

    flagClass(code: string): string {
        return `fi-${code.toLocaleLowerCase()}`;
    }

    hasValue(value: any): boolean {
        return !!value || value === 0;
    }

    private setUpdates() {
        if (!this.process || !this.message?.meta?.fields) {
            return;
        }

        const messageFields = this.message.meta.fields;
        for (const fieldId of this.process.fieldsOrder) {
            const updatedField = messageFields[fieldId];

            if (!fieldId || !updatedField) {
                continue;
            }

            updatedField.field = this.process.fields[fieldId];

            if (updatedField.type === 'teams') {
                this.setTeam(updatedField);
            }

            if (!updatedField.field) {
                return void console.warn('Field not found');
            }

            this.updates.push(updatedField);
        }
    }
}
