/** @format */

import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, Subject, Subscription, combineLatest } from 'rxjs';
import { debounceTime, filter, pluck, take, takeUntil } from 'rxjs/operators';
import { ResizedEvent } from 'angular-resize-event';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';

import { Discussion, Message } from '@app/models';
import { CoreService } from 'app/_services/core.service';
import { UserService } from 'app/_services/user.service';
import { PeopleService } from 'app/people/people.service';
import { SideNavService } from 'app/_services/side-nav.service';
import { WindowListenerService } from '../../_services/window-listener.service';
import { GlobalSearch, MessagesGlobalSearch } from 'app/_models/globalSearch.model';
import { SearchService } from 'app/_services/search.service';
import { environment } from '@app/env';
import { PermissionService } from 'app/_services/permission.service';
import { DialogHelperService } from 'app/_dialogs/dialog-helper.service';
import { DiscussionInputComponent } from '../discussion-input/discussion-input.component';
import { SelectedDiscussionService } from 'app/_services/selected-discussion.service';
import { RoutingService } from 'app/_services/routing.service';
import { UserDetailComponent } from 'app/people-shared/user-detail/user-detail.component';
import { DiscussionTypingUsers, V3DiscussionService } from 'app/_services/v3-discussion.service';
import { ThemeService } from 'app/theme/theme.service';

@Component({
    selector: 'app-message-view',
    templateUrl: './message-view.component.html',
    styleUrls: ['./message-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: TRANSLOCO_SCOPE, useValue: { scope: 'discussion', alias: 'discussion' } }],
})
export class MessageViewComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    private static redrawCount = 0; // This is for dev purposes detecting redraw events

    @ViewChild('discussionInput', { static: false, read: ElementRef }) discussionInput: ElementRef;
    @ViewChild('discussionInput', { static: false }) inputComponent: DiscussionInputComponent;
    @ViewChild('messageView', { static: false }) messageView: ElementRef;

    @Input() discussionId: string;
    @Input() focusMessage = new Subject<Message>();
    /** Is true when discussion is opened in a sidenav */
    @Input() sidenavMode = false;

    @Output() newDiscussion = new EventEmitter<string>();
    @Output() closeDiscussionInputMenuOutput = new EventEmitter<string>();

    newContact = this.selectedDiscussion.newContact;
    messages = this.selectedDiscussion.messages;
    participants = this.selectedDiscussion.participants;
    participantColorMap = this.selectedDiscussion.participantColorMap;
    newestLoaded = this.selectedDiscussion.newestLoaded;
    discussion = this.selectedDiscussion.discussion;
    canBeLinkedFromProcesses = this.selectedDiscussion.canBeLinkedFromProcesses;
    contextMenuMessage = this.selectedDiscussion.contextMenuMessage;
    me = this.core.user;
    messageSearch = new UntypedFormControl();
    searchedMessages: MessagesGlobalSearch[] = [];
    screenWidth = window.innerWidth;
    typingUsers = new BehaviorSubject<string[]>([]);
    dragging = this.selectedDiscussion.dragging;

    private discussionsMapListener = new Subscription();
    private onDestroy = new Subject<void>();
    private inputHeight = 0;

    constructor(
        public viewContainerRef: ViewContainerRef,
        public theme: ThemeService,
        public core: CoreService,
        public selectedDiscussion: SelectedDiscussionService,
        private cdr: ChangeDetectorRef,
        private user: UserService,
        private people: PeopleService,
        private sideNav: SideNavService,
        private discussionService: V3DiscussionService,
        private windowListener: WindowListenerService,
        private search: SearchService,
        private permission: PermissionService,
        private dialogHelper: DialogHelperService,
        private router: RoutingService,
        private renderer: Renderer2,
        private translocoService: TranslocoService
    ) {}

    // TODO: Get rid of ngOnChanges
    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (!changes.discussionId?.currentValue && !this.discussion) {
            return;
        }

        this.selectedDiscussion.selectDiscussion(changes.discussionId.currentValue, this.sidenavMode);
        this.clearDiscussionVariables(true);
        this.setTypingUsers(this.discussionService.typingUsers.value);
        this.discussionsMapListener = this.discussionService.discussionsUpdated
            .pipe(
                takeUntil(this.onDestroy),
                filter(discussions => discussions.includes(changes.discussionId.currentValue))
            )
            .subscribe({
                next: () => {
                    this.selectedDiscussion.selectDiscussion(changes.discussionId.currentValue, this.sidenavMode);
                },
            });
    }

    async ngOnInit(): Promise<void> {
        this.people.unknownUsers.pipe(takeUntil(this.onDestroy), debounceTime(100)).subscribe({
            next: () => {
                this.selectedDiscussion.updateParticipants();
                this.cdr.detectChanges();
            },
        });

        this.messageSearch.valueChanges.pipe(debounceTime(300)).subscribe({
            next: value => this.searchMessages(value),
        });

        this.windowListener.size.pipe(takeUntil(this.onDestroy), debounceTime(100)).subscribe({
            next: ({ x }) => {
                this.screenWidth = x;
                this.cdr.detectChanges();
            },
        });

        this.participants.pipe(takeUntil(this.onDestroy)).subscribe({ next: () => this.checkUsers() });

        combineLatest([
            this.discussion,
            this.core.network,
            this.people.unknownUsers,
            this.newContact,
            this.selectedDiscussion.focusing,
            this.contextMenuMessage,
        ])
            .pipe(takeUntil(this.onDestroy), debounceTime(100))
            .subscribe({
                next: () => this.cdr.detectChanges(),
            });

        this.setTypingUsers(this.discussionService.typingUsers.value);
        this.discussionService.typingUsers.pipe(takeUntil(this.onDestroy)).subscribe({
            next: (typingUsers) => this.setTypingUsers(typingUsers),
        });
    }

    async ngAfterViewInit(): Promise<void> {
        // Catch browser back navigation event on mobile
        window.onpopstate = () => {
            if (!this.inMobileDiscussionView) {
                return;
            }
            this.closeDiscussion();
        };

        this.inputHeight = this.discussionInput.nativeElement.offsetHeight;
        document.documentElement.style.setProperty('--padding-bottom', `${this.inputHeight}px`);

        // Listeners for drag & drop files as attachments
        this.renderer.listen(this.messageView.nativeElement, 'dragover', event => {
            event.preventDefault();
            event.stopPropagation();
            event.dataTransfer.dropEffect = 'copy';

            if (this.selectedDiscussion.dragging.value) {
                return;
            }

            this.selectedDiscussion.dragging.next(true);
        });

        this.renderer.listen(this.messageView.nativeElement, 'dragleave', event => {
            event.preventDefault();
            event.stopPropagation();

            const buttonsPressed = event.buttons;
            const dragging = this.selectedDiscussion.dragging.value;

            if (!dragging || buttonsPressed) {
                return;
            }

            this.selectedDiscussion.dragging.next(false);
        });

        this.renderer.listen(this.messageView.nativeElement, 'drop', event => {
            event.preventDefault();
            event.stopPropagation();
            this.handleFileDrop(event);
        });
    }

    async ngOnDestroy() {
        this.clearDiscussionVariables(true);
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    /* Closes either forward or reply dialog depending on which one we are opening when calling the function
       path: message-container -> message-view -> discussion-input */
    closeDiscussionInputMenu(closeContext: 'reply' | 'forward' | 'link') {
        this.inputComponent.closeDiscussionInputMenu(closeContext);
    }

    setTypingUsers(typingUsers: DiscussionTypingUsers): void {
        this.typingUsers.next(typingUsers[this.discussionId]?.map(({ userId }) => userId) || []);
    }

    async checkUsers() {
        if (!this.participants.value || !Object.keys(this.participants.value).length) {
            return;
        }

        Object.keys(this.participants.value).forEach(async userId => {
            if (this.participants.value[userId].display_name === 'Unknown User') {
                try {
                    const user = await this.people.getUserAsync(userId);
                    if (!user) {
                        return;
                    }
                    this.participants.value[userId] = user;
                } catch (error) {
                    console.log('Error loading user: ', error);
                }
            }
        });
        this.cdr.detectChanges();
    }

    onInputResize(event: ResizedEvent) {
        if (event?.oldRect?.height === event?.newRect?.height) {
            // Height not changed, don't need to do anything
            return;
        }

        const offsetHeight = this.discussionInput?.nativeElement?.offsetHeight;

        if (!offsetHeight) {
            return;
        }

        if (this.selectedDiscussion.scrollIsAtBottom && offsetHeight !== this.inputHeight) {
            this.scrollToBottom();
        }

        this.inputHeight = offsetHeight;
        document.documentElement.style.setProperty('--padding-bottom', `${this.inputHeight}px`);
    }

    redraw() {
        MessageViewComponent.redrawCount += 1;
        console.log(`message view redrawed: ${MessageViewComponent.redrawCount} times`);
    }

    handleFileDrop(event: any) {
        const files = event.dataTransfer.files;

        for (const file of files) {
            this.inputComponent.uploadFile(file);
        }

        this.dragging.next(false);
        this.cdr.detectChanges();
    }

    getUserName(uid: string): string {
        return this.people.getUser(uid)?.display_name;
    }

    scrollToBottom() {
        this.selectedDiscussion.scrollTo.next('scrollToBottom');
    }

    jumpToBottom() {
        this.selectedDiscussion.scrollTo.next('jumpToBottom');
    }

    jumpToSentMessage() {
        this.selectedDiscussion.scrollTo.next('jumpToSentMessage');
    }

    openActivity() {
        this.selectedDiscussion.openActivity();
    }

    openEvent() {
        this.selectedDiscussion.openEvent();
    }

    openGroup() {
        this.selectedDiscussion.openGroup();
    }

    toggleStarred() {
        if (!this.discussion) {
            return;
        }
        this.user.toggleDiscussionStarred(this.discussion.value._id);
    }

    closeDiscussion() {
        localStorage.removeItem('selectedDiscussion');
        this.clearDiscussionVariables(true);
        this.router.navigate(['discussions']);
    }

    get discussionType(): string {
        if (this.newContact.value) {
            return 'user';
        }

        return this.discussionService.getDiscussionType(this.discussion.value);
    }

    focusToMessage(message: Message) {
        this.selectedDiscussion.focusToMessage(message);
    }

    async addParticipants() {
        this.selectedDiscussion.addParticipants();
    }

    leaveDiscussion() {
        if (this.discussion.value?.private) {
            return;
        }

        const confirm = this.translocoService.translate('discussion.message-view.leave.confirm');
        const content = this.translocoService.translate('discussion.message-view.leave.content');
        const title = this.translocoService.translate('discussion.message-view.leave.title');

        this.dialogHelper
            .showConfirm(confirm, content, title)
            .pipe(takeUntil(this.onDestroy))
            .subscribe({
                next: confirmed => {
                    if (confirmed) {
                        this.discussionService
                            .leave(this.discussion.value._id)
                            .pipe(takeUntil(this.onDestroy))
                            .subscribe({
                                next: () => this.discussionService.refreshViewport.next(),
                                error: err => console.error('Unable to leave discussion: ', err),
                            });
                    }
                },
            });
    }

    /**
     * Right click menu for messages
     */
    openContextMenu(event: MouseEvent, message: Message) {
        this.selectedDiscussion.openContextMenu.next({ event, message });
    }

    closeContextMenu() {
        this.selectedDiscussion.closeContextMenu.next();
    }

    openUser(userId: string) {
        this.sideNav.create(UserDetailComponent, {
            userId,
        });
    }

    getProfilePictureURL(uid: string): string {
        const baseUrl = `${environment.wsUrl}/image/square100/`;

        const participant = this.participants?.value?.[uid];
        if (participant) {
            return baseUrl + participant.default_profilepic;
        }

        return baseUrl + this.people.getUser(uid).default_profilepic;
    }

    getDiscussionSubject(discussion: Discussion): string {
        if (this.newContact.value) {
            return this.getUserName(this.newContact.value);
        }

        if (!discussion) {
            return;
        }

        if (discussion.private) {
            const filteredParticipants = this.participantsWithoutMe;
            return filteredParticipants[0] ? this.people.getUser(filteredParticipants[0]).display_name : 'Unknown User';
        }
        return discussion.subject;
    }

    get participantsWithoutMe(): string[] {
        if (this.newContact.value) {
            return [this.newContact.value];
        }

        return this.discussion.value.participants.filter(uid => uid !== this.me.value._id);
    }

    getParticipantName(userId: string): string {
        if (this.newContact.value) {
            return this.getUserName(this.newContact.value);
        }

        return this.participants.value[userId]?.display_name;
    }

    getUsernames(userIds: string[]): string {
        return userIds.map(uid => this.participants.value[uid]?.display_name || this.people.getUser(uid)?.display_name).join(', ');
    }

    async selectNewDiscussion(discussionId: string) {
        if (!discussionId) {
            return;
        }

        this.newDiscussion.emit(discussionId);
        this.cdr.detectChanges();
    }

    /* We need to handle scrolling a bit differently on mobile, rather than scrolling a container element we scroll the whole document.
       This affects positioning (position: fixed to get header and input to stay in place) and scrolling positions */
    get inMobileDiscussionView(): boolean {
        return this.screenWidth <= 600 && !this.sidenavMode;
    }

    get isUserGuest(): boolean {
        return this.permission.isNetworkGuest;
    }

    clearDiscussionVariables(changeDiscussion: boolean) {
        this.selectedDiscussion.clearDiscussionVariables(changeDiscussion);

        if (changeDiscussion) {
            this.searchedMessages = [];
            this.discussionsMapListener.unsubscribe();
        }
    }

    private searchMessages(value: string) {
        if (value?.length < 3) {
            this.searchedMessages = [];
            this.cdr.detectChanges();
            return;
        }

        this.search
            .globalSearch(value, ['messages'], this.discussionId)
            .pipe(take(1))
            .subscribe({
                next: (searchData: GlobalSearch) => {
                    this.searchedMessages = this.search.overflowMessages(searchData.messages, value, 100);
                    this.cdr.detectChanges();
                },
                error: err => {
                    console.error(err);
                    this.cdr.detectChanges();
                },
            });
    }
}
