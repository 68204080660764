import { PermitKeys } from '../permission-keys';
import { PermissionCheckReturn, PermissionError, PermissionErrorCodes, PermissionMap, WorkspacePermissions } from '../permission-models';


export class DiscussionPermission {
    constructor(
        private userId: string,
        private permissions: PermissionMap,
    ) { }

    /** Workspace owners and admins are discussion admins */
    isAdmin(workspaceId: string): PermissionCheckReturn {
        const [error, workspacePermissions] = this.workspacePermissions(workspaceId);
        if (error) {
            return [error];
        }

        const isOwner = workspacePermissions?.workspace?.isOwner;
        const isAdmin = workspacePermissions?.workspace?.isAdmin;
        if (!isOwner && !isAdmin) {
            return [{ name: 'Invalid permissions', message: 'User needs to be an admin' }];
        }

        return [undefined, true];
    }

    /** Discussion permissions */
    discussion = {
        /** Discussion participants can load it */
        load: (discussion: { cid: string, participants: string[], _id: string  }): PermissionCheckReturn => {
            if (discussion.participants.includes(this.userId)) {
                return [undefined, true];
            }

            return [{ name: '', message: 'No discussion found!' }];
        },
        /** Discussion participants can star discussions */
        star: (discussion: { cid: string, participants: string[], _id: string  }): PermissionCheckReturn => {
            return this.discussion.load(discussion);
        },
        /** Discussion participants can load files */
        files: (discussion: { cid: string, participants: string[], _id: string  }): PermissionCheckReturn => {
            return this.discussion.load(discussion);
        },
        /** Discussion participants can load messages */
        messages: (discussion: { cid: string, participants: string[], _id: string  }): PermissionCheckReturn => {
            return this.discussion.load(discussion);
        },
        /** Discussion participants can mute discussion */
        mute: (discussion: { cid: string, participants: string[], _id: string  }): PermissionCheckReturn => {
            return this.discussion.load(discussion);
        },
        /** Discussion participants can start meets */
        start: (discussion: { cid: string, participants: string[], _id: string  }): PermissionCheckReturn => {
            return this.discussion.load(discussion);
        },
    }

    /** Message permission */
    message = {
        /** Discussion participants can load single message */
        get: (message: { uid: string, discussion: string}, discussion: { cid: string, participants: string[], _id: string }): PermissionCheckReturn => {
            if (message.discussion === discussion._id && this.discussion.load(discussion)) {
                return [undefined, true];
            }

            return [{ name: '', message: 'No message found!' }];
        },
        /** Message sender can edit it */
        edit: (message: { uid: string, discussion: string}): PermissionCheckReturn => {
            if (message.uid === this.userId) {
                return [undefined, true];
            }

            return [{ name: '', message: 'No message found!' }];
        },
        /** Message sender can delete it */
        delete: (message: { uid: string, discussion: string}): PermissionCheckReturn => {
            if (message.uid === this.userId) {
                return [undefined, true];
            }

            return [{ name: '', message: 'No message found!' }];
        },
        /** Discussion participants can react to message */
        react: (message: { uid: string, discussion: string}, discussion: { cid: string, participants: string[], _id: string }): PermissionCheckReturn => {
            if (message.discussion === discussion._id && this.discussion.load(discussion)) {
                return [undefined, true];
            }

            return [{ name: '', message: 'No message found!' }];
        }
    }

    start = {
        private: (
            recipientId: string,
            recipientWorkspaces: { [workspaceId: string]: { _id: string, settings: { isolatedTeams?: boolean } } },
            recipientTeams: { cid: string, members: string[], public?: boolean }[]
        ): PermissionCheckReturn => {
            let any: boolean | null = null;
            let publicTeam: boolean | null = null;
            const noCustomRole: { [workspaceId: string]: boolean } = {};

            if (!Object.keys(recipientWorkspaces || {}).length) {
                return [{ name: 'Permission denied', message: 'Recipient workspaces not found.' }];
            }

            for (const [workspaceId, workspace] of Object.entries(recipientWorkspaces)) {
                if (!this.permissions[workspaceId]) {
                    continue;
                }

                noCustomRole[workspaceId] = true;

                const [canStartAnyError, canStartAny] = this.hasCustomRolePermit(workspaceId, 'discussion.start.private.any');
                if (canStartAnyError) {
                    // No permission to start any private discussion in specified workspace
                    any = false;
                    noCustomRole[workspaceId] = false;
                }

                if (canStartAny) {
                    // Has permission to start private discussions in specified workspace
                    any = true;
                    noCustomRole[workspaceId] = false;
                    // Loop can be broken here since user has permission so no need to check other workspaces
                    break;
                }

                const [canStartPublicTeamError, canStartPublicTeam] = this.hasCustomRolePermit(workspaceId, 'discussion.start.private.publicTeam');
                if ((canStartPublicTeamError || canStartPublicTeam) && !workspace.settings.isolatedTeams) {
                    // No permission to start private discussions since user has custom role but isolated teams are off in this workspace and user does not have "any" permit
                    publicTeam = false;
                    noCustomRole[workspaceId] = false;
                    continue;
                }

                if (canStartPublicTeamError) {
                    // No permission to start any private discussion in specified workspace
                    publicTeam = false;
                    noCustomRole[workspaceId] = false;
                    continue;
                }

                if (canStartPublicTeam) {
                    noCustomRole[workspaceId] = false;
                    publicTeam = !!recipientTeams.find(team => (team.cid === workspaceId) && team.public && team.members.includes(recipientId));
                    if (publicTeam) {
                        // Loop can be broken here since user has permission to start a private discussion
                        break;
                    }

                    continue;
                }

                if (noCustomRole) {
                    // Loop can be broken here since user does not have custom role in specified workspace so they have permission to start the discussion
                    break;
                }
            }

            if ((any === null && publicTeam === null) || Object.values(noCustomRole).includes(true)) {
                return [undefined, true];
            }

            if (any || publicTeam) {
                return [undefined, true];
            }

            if (!any && publicTeam) {
                return [{ name: 'Permission denied', message: 'User can only start discussion with members from public teams'}];
            }

            return [{ name: 'Permission denied', message: 'User cannot start private discussions'}];
        }
    }

    private workspacePermissions(workspaceId: string): [error?: PermissionError, permissions?: WorkspacePermissions] {
        if (!this.permissions) {
            return [{ name: 'Invalid permissions', message: 'User permissions are undefined', code: PermissionErrorCodes.missing, workspaceId }];
        }

        const workspacePermissions = this.permissions[workspaceId];
        if (!workspacePermissions) {
            return [{ name: 'Invalid permissions', message: 'Workspace permissions are undefined', code: PermissionErrorCodes.missing, workspaceId }];
        }

        return [undefined, workspacePermissions];
    }

    private hasCustomRolePermit(workspaceId: string, permitKey: PermitKeys): PermissionCheckReturn {
        const [workspacePermissionError, workspacePermissions] = this.workspacePermissions(workspaceId);
        if (workspacePermissionError) {
            return [workspacePermissionError];
        }

        const customRole = workspacePermissions?.workspace.custom;
        if (!customRole) {
            return [];
        }

        if (!customRole.permits?.includes(permitKey)) {
            return [{ name: 'Permission denied', message: `User role does not have ${permitKey} permit in feed` }];
        }

        return [undefined, true];
    }
}