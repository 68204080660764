/** @format */
/* ToDo: Delete when DigiOne changed url from /#/redirect/digione to /digione-login */

import { Injectable, OnDestroy } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { CoreService } from './core.service';

import { authConfigMap } from '../../../sso-config';

export type Provider = 'digione' | 'digione2';

export interface OAuthCallbackParams {
    code: string;
    state: string;
    scope?: string;
    [key: string]: string | undefined; // This allows for any additional parameters
}

@Injectable({
    providedIn: 'root',
})
export class OidcService implements OnDestroy {
    private onDestroy = new Subject<void>();
    private jwtHelper: JwtHelperService = new JwtHelperService();

    private loginSuccessSubject = new BehaviorSubject<boolean>(false);
    loginSuccess$ = this.loginSuccessSubject.asObservable();

    constructor(private oauthService: OAuthService, private auth: CoreService) {
        void this.initializeLogin('digione');
    }

    /**
     * Handles the OAuth2 callback after the authentication process.
     *
     * - Ensures the discovery document is loaded if not already done.
     * - Attempts to log in using the discovery document and processes the ID token.
     * - Validates the ID token and initiates the login process with Hailer if valid.
     * - Returns a boolean indicating whether the login was successful.
     *
     * @returns A promise resolving to `true` if login was successful, otherwise `false`.
     * @throws Error if any step in the callback handling process fails.
     */
    async handleCallback(): Promise<boolean> {
        try {
            // Ensure discovery document is loaded
            if (!this.oauthService.discoveryDocumentLoaded) {
                await this.oauthService.loadDiscoveryDocument();
            }

            await this.oauthService.loadDiscoveryDocumentAndTryLogin();

            // Check if we have valid token after the login attempt
            const hasValidIdToken = this.hasValidIdToken();
            const idToken = this.getIdToken();

            if (idToken && hasValidIdToken) {
                await this.loginDigiOne(idToken);
                return true;
            }

            console.log('Login failed: idToken not valid or missing');
            console.log('hasValidIdToken:', hasValidIdToken);
            return false;
        } catch (error) {
            console.error('Error handling callback:', error);
            return false;
        }
    }

    // Log in user in backend and set session token
    async loginDigiOne(idToken: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.auth
                .loginOpenId(idToken)
                .pipe(takeUntil(this.onDestroy))
                .subscribe({
                    next: () => {
                        resolve(true);
                    },
                    error: error => {
                        console.log('Error in loginDigiOne: ', error.msg);
                        resolve(false);
                    },
                });
        });
    }

    /**
     * Initializes the login process for the specified authentication provider.
     *
     * - Configures the OAuth service using the provider's auth configuration.
     * - Sets up token validation using the JwksValidationHandler.
     * - Loads the provider's discovery document for endpoints and metadata.
     *
     * @param providerId - The ID of the authentication provider to initialize.
     * @throws Error if the provider configuration is not found or the discovery document fails to load.
     */
    private async initializeLogin(providerId: Provider): Promise<void> {
        const authConfig = authConfigMap[providerId];
        if (!authConfig) {
            throw new Error(`No AuthConfig found for provider: ${providerId}`);
        }

        this.oauthService.configure(authConfig);
        this.oauthService.tokenValidationHandler = new JwksValidationHandler();

        try {
            await this.oauthService.loadDiscoveryDocument();
        } catch (error) {
            console.error('Error loading discovery document:', error);
            throw error;
        }
    }

    /**
     * Handles the sign-in process for the specified authentication provider.
     *
     * - Initializes the login process by configuring the OAuth service.
     * - Stores the provider ID in the session storage.
     * - Initiates the OAuth2 code flow for authentication.
     * - Emits a failure signal if an error occurs during the sign-in process.
     *
     * @param providerId - The ID of the authentication provider to sign in with.
     * @throws Error if the login initialization or code flow initiation fails.
     */
    async signIn(providerId: Provider): Promise<void> {
        try {
            await this.initializeLogin(providerId);

            sessionStorage.setItem('providerId', providerId);
            console.log('Initiating code flow. Redirect URI:', this.oauthService.redirectUri);

            this.oauthService.initCodeFlow();
        } catch (error) {
            console.error('Error during sign-in:', error);
            this.loginSuccessSubject.next(false);
        }
    }

    signOut(): void {
        sessionStorage.removeItem('providerId');
        this.oauthService.logOut(true);
    }

    getIdentityClaims() {
        return this.oauthService.getIdentityClaims();
    }

    getIdToken(): string {
        return this.oauthService.getIdToken();
    }

    getClientId(): string | undefined {
        return this.oauthService.clientId;
    }

    getAccessToken(): string {
        return this.oauthService.getAccessToken();
    }

    getDecodedIdToken(): string {
        return this.jwtHelper.decodeToken(this.oauthService.getIdToken());
    }

    hasValidIdToken(): boolean {
        return this.oauthService.hasValidIdToken();
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }
}
