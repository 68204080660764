<!-- @format -->

<div
    *ngIf="message"
    class="message-body"
    [ngClass]="message.uid === (me | async)?.id && message.type === 'user' ? 'own-message' : 'user-message'">
    <app-user-picture
        *ngIf="canShowProfilePicture"
        [url]="getProfilePictureURL(message.uid)"
        (click)="openUser(message.uid)"
        [diameter]="screenWidth > 600 ? '40' : '36'"
        class="profile-picture">
    </app-user-picture>

    <span *ngIf="message.isConsecutive" class="left-timestamp">{{ message.created | date : 'HH:mm' }}</span>

    <div
        class="flexbox-column"        
        [class.large-emoji]="checkForEmoji(message)">
        <div *ngIf="!message.isConsecutive" class="container message-header" [ngClass]="{ 'edited': message.edited }">
            <app-user-link
                [userId]="message.uid"
                [color]="(participantColorMap | async)[message.uid] || 'var(--user-link-default)'"
                [whiteLink]="(discussion | async)?.private && message.uid === (me | async)?.id && !checkForEmoji(message)"
                [darkLink]="
                    theme.getActiveTheme().name === 'light' &&
                    !(discussion | async)?.private &&
                    (message.uid !== (me | async)?.id || checkForEmoji(message))
                "
                [lightFocus]="message.uid === (me | async)?.id"
                [ownName]="message.uid === (me | async)?.id"
                *ngIf="!((discussion | async)?.private && (sidenavMode || inMobileDiscussionView))">
            </app-user-link>

            <span class="timestamp">{{ message.created | date : 'HH:mm' }}</span>
            <span data-cy="discussion-message-edited-tag" *ngIf="message.edited && !message.removed" class="edited-message">
                <mat-icon svgIcon="hailer-edit"></mat-icon>
                {{ 'discussion.message_user.edited' | transloco }} ({{ message.edited | todayDate | async }})
            </span>
        </div>

        <span
            data-cy="discussion-message-edited-tag"
            *ngIf="message.isConsecutive && message.edited && !message.removed"
            class="edited-message">
            <mat-icon svgIcon="hailer-edit"></mat-icon>
            {{ 'discussion.message_user.edited' | transloco }} ({{ message.edited | todayDate | async }})
        </span>

        <!--Shown inside replied message-->
        <div
            data-cy="discussion-reply-message"
            *ngIf="message.replyTo && !message.removed"
            class="reply-message"
            (click)="focusToMessage(message)">
            <div class="reply-message-wrapper">
                <span class="reply-header">
                    <mat-icon class="reply-icon" svgIcon="hailer-reply-fill"></mat-icon>
                    <app-user-link
                        [userId]="message.replyMessage?.uid"
                        [ownName]="message.replyMessage?.uid === (me | async)?.id"
                        [color]="
                            message.uid === (me | async)?.id && theme.getActiveTheme().name === 'light'
                                ? 'var(--white)'
                                : 'var(--user-link-default)'
                        "
                        [lightFocus]="message.uid === (me | async)?.id">
                    </app-user-link>
                </span>
                <span
                    data-cy="discussion-message-edited-tag"
                    *ngIf="message.replyMessage?.edited && !message.replyMessage?.removed"
                    class="edited-message">
                    <mat-icon svgIcon="hailer-edit"></mat-icon>
                    {{ 'discussion.message_user.edited' | transloco }} ({{ message.replyMessage?.edited | todayDate | async }})
                </span>
                <div
                    #replyMessageText
                    class="message-content"
                    appExtMarkdown
                    [content]="message.replyMessage?.msg"
                    [class.deleted]="message.replyMessage?.removed"
                    style="overflow: hidden"></div>
            </div>
        </div>

        <!--Shown inside forwarded message-->
        <div
            data-cy="discussion-forward-header"
            *ngIf="message.forwardMessage?._id && !message.removed"
            class="forward-message"
            (click)="focusToMessage(message)">
            <div class="forward-message-wrapper">
                <!--shows who the message is from-->
                <span class="forward-header">
                    <mat-icon class="forward-icon" svgIcon="hailer-forward-fill"></mat-icon>
                    <span class="from-text">{{ 'discussion.messages_container.from' | transloco }}:&nbsp;</span>
                    <app-user-link
                        [userId]="message.forwardMessage?.uid"
                        [ownName]="message.forwardMessage?.uid === (me | async)?.id"
                        [lightFocus]="message.uid === (me | async)?.id"
                        [color]="
                            message.uid === (me | async)?.id && theme.getActiveTheme().name === 'light'
                                ? 'var(--white)'
                                : 'var(--user-link-default)'
                        ">
                    </app-user-link>
                </span>
                <!--if the forwarded message was edited-->
                <span
                    data-cy="discussion-message-edited-tag"
                    *ngIf="message.forwardMessage?.edited && !message.forwardMessage?.removed"
                    class="edited-message">
                    <mat-icon inline svgIcon="hailer-edit"></mat-icon> Edited ({{ message.forwardMessage?.edited | todayDate | async }})
                </span>
                <!--if forwarded message has a file-->
                <app-attachments
                    *ngIf="message.forwardMessage?.files?.length"
                    [attachments]="filesMap(message.forwardMessage?.files || [])"></app-attachments>
                <!--forwarded message text-->
                <div
                    *ngIf="message.forwardMessage.msg && !(editingMessage | async)[message.forwardMessage._id]?.editing"
                    #forwardMessageText
                    class="message-content hide-overflow"
                    appExtMarkdown
                    [content]="message.forwardMessage?.msg"
                    [class.deleted]="message.forwardMessage?.removed"></div>
            </div>
        </div>

        <ng-container *ngIf="message.linkedData">
            <app-message-link *ngFor="let link of message.linkedData" [link]="link" [messageId]="message._id!" [class.own-message-tags]="message.uid === (me | async)?.id"></app-message-link>
        </ng-container>

		<!--normal message file-->
		<app-attachments data-cy="message-attachment" *ngIf="message.files?.length" [attachments]="filesMap(message.files || [])"></app-attachments>

        <!--normal message text-->
        <div
            *ngIf="message.msg && !(editingMessage | async)[message._id]?.editing"
            appExtMarkdown
            [content]="message.msg"
            class="message-content"
            data-cy="message-text"
            [class.deleted]="message.removed"></div>
        <ng-container *ngIf="(editingMessage | async)[message._id]?.editing">
            <div
                #editTextarea
                role="textbox"
                (focus)="onEditTextareaFocus()"
                data-cy="discussion-edit-input"
                [formControl]="(editingMessage | async)[message._id].editMessageForm"
                class="edit-textarea"
                contenteditable="true"
                spellcheck="false"
                name="text"></div>
            <div class="container edit-buttons">
                <app-button
                    dataCy="discussion-cancel-edit"
                    size="small"
                    type="text"
                    color="tertiary-soft"
                    [label]="'discussion.message_user.cancel' | transloco"
                    [ariaLabel]="'discussion.message_user.cancel-aria' | transloco"
                    (clickEvent)="stopEditing()"></app-button>
                <app-button
                    dataCy="discussion-save-edit"
                    size="small"
                    type="text-with-icon"
                    color="primary-soft"
                    [label]="'discussion.message_user.save' | transloco"
                    [ariaLabel]="'discussion.message_user.save-aria' | transloco"
                    hailerIconName="hailer-tick"
                    [isDisabled]="saveEditsDisabled(message)"
                    (clickEvent)="saveEdits(message)"></app-button>
            </div>
        </ng-container>

        <ng-container *ngIf="hasReactions() || message.isStarred">
            <div
                class="actions-container"
                [ngClass]="message.uid === (me | async)?.id && message.type === 'user' ? 'own-message' : 'user-message'">
                <ng-container *ngIf="message.reactions">
                    <ng-container *ngTemplateOutlet="reactionsContainer; context: { $implicit: message }"> </ng-container>
                </ng-container>
                <ng-container *ngIf="message.isStarred">
                    <button
                        data-cy="discussion-starred-message"
                        class="star-message no-button"
                        (click)="starMessage.emit({ message })"
                        [tippy]="('discussion.list.unstar' | transloco)"
                        [attr.aria-label]="('discussion.message_user.unstar-aria' | transloco)">
                        <mat-icon class="starred-icon" svgIcon="hailer-star-fill"></mat-icon>
                    </button>
                </ng-container>
            </div>
        </ng-container>
    </div>
</div>

<ng-template #reactionsContainer let-message>
    <div class="reactions">
        <button
            class="no-button"
            data-cy="thumbs-up-reacted"
            *ngIf="message.reactions.like && message.reactions.like.length > 0"
            (click)="reactToMessage.emit({message, reaction: 'like'});"
            [tippy]="getUsernames(message.reactions.like)"
            [attr.aria-label]="'👍' + ('discussion.message_user.reaction-given' | transloco) + getUsernames(message.reactions.like) + ('discussion.message_user.react' | transloco) + '👍'">
            👍 {{ message.reactions.like.length }}
        </button>
        <button
            class="no-button"
            data-cy="thumbs-down-reacted"
            *ngIf="message.reactions.dislike && message.reactions.dislike.length > 0"
            (click)="reactToMessage.emit({message, reaction: 'dislike'})"
            [tippy]="getUsernames(message.reactions.dislike)"
            [attr.aria-label]="'👎' + ('discussion.message_user.reaction-given' | transloco) + getUsernames(message.reactions.dislike) + ('discussion.message_user.react' | transloco) + '👎'">
            👎 {{ message.reactions.dislike.length }}
        </button>
        <button
            class="no-button"
            data-cy="laughing-reacted"
            *ngIf="message.reactions.funny && message.reactions.funny.length > 0"
            (click)="reactToMessage.emit({message, reaction: 'funny'})"
            [tippy]="getUsernames(message.reactions.funny)"
            [attr.aria-label]="'😂' + ('discussion.message_user.reaction-given' | transloco) + getUsernames(message.reactions.funny) + ('discussion.message_user.react' | transloco) + '😂'">
            😂 {{ message.reactions.funny.length }}
        </button>
        <button
            class="no-button"
            data-cy="thinking-reacted"
            *ngIf="message.reactions.thinking && message.reactions.thinking.length > 0"
            (click)="reactToMessage.emit({message, reaction: 'thinking'})"
            [tippy]="getUsernames(message.reactions.thinking)"
            [attr.aria-label]="'🤔' + ('discussion.message_user.reaction-given' | transloco) + getUsernames(message.reactions.thinking)+ ('discussion.message_user.react' | transloco) + '🤔'">
            🤔 {{ message.reactions.thinking.length }}
        </button>
        <button
            class="no-button"
            data-cy="cookie-reacted"
            *ngIf="message.reactions.cookie && message.reactions.cookie.length > 0"
            (click)="reactToMessage.emit({message, reaction: 'cookie'})"
            [tippy]="getUsernames(message.reactions.cookie)"
            [attr.aria-label]="'🍪' + ('discussion.message_user.reaction-given' | transloco) + getUsernames(message.reactions.cookie) + ('discussion.message_user.react' | transloco) + '🍪'">
            🍪 {{ message.reactions.cookie.length }}
        </button>
    </div>
</ng-template>
