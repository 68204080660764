/** @format */

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject, of, zip } from 'rxjs';
import { map, take } from 'rxjs/operators';

import {
    Discussion,
    DiscussionFileResponse,
    DiscussionLink,
    DiscussionMap,
    File,
    Message,
    MessageDraft,
    MessageDraftMap,
    MessageMap,
    MuteDuration,
} from '@app/models';
import { syncDiscussions } from 'app/redux/actions/discussion.actions';
import { clearMessages, getMessageSuccess, loadNextMessagesSuccess } from 'app/redux/actions/message.actions';
import { RPCService } from 'app/_services/rpc.service';
import {
    StoreDiscussionMessages,
    V3DiscussionMessageResponse,
    V3DiscussionMessageResponseMap,
    V3MessagesService,
} from './v3-messages.service';

@Injectable({
    providedIn: 'root',
})
export class V3DiscussionService {
    replyMessageMap = new BehaviorSubject<DiscussionReplyMessageMap>({});
    forwardMessageMap = new BehaviorSubject<DiscussionForwardMessageMap>({});
    messageDrafts = new BehaviorSubject<MessageDraftMap>({});
    discussionsUpdated = new Subject<string[]>();
    discussionsRemoved = new Subject<string[]>();
    messageDraftUpdated = new Subject<{ discussionId: string; messageDraft: MessageDraft }>();
    closeCreateMenu = new Subject<void>();
    typingUsers = new BehaviorSubject<DiscussionTypingUsers>({});
    refreshViewport = new BehaviorSubject<void>(null);

    openQuickAddMenu = new Subject<'activity' | 'event' | 'group' | 'private'>();
    unreadDiscussions = new BehaviorSubject<DiscussionUnreadCountsMap>({});

    starredMessages = new BehaviorSubject<Message[]>([]);

    /** True when already making sync request and we want to make another one. */
    private syncQueue = false;

    /** True when syncing discussions */
    private syncing = false;
    private lastSynced = new BehaviorSubject<number>(null);
    private discussions = new BehaviorSubject<DiscussionMap>({});
    private typingUsersTimeoutMap: { [discussionAndUid: string]: NodeJS.Timeout } = {};
    private firstSync = true;

    private allSubjects: BehaviorSubject<any>[] = [
        this.lastSynced,
        this.discussions,
        this.messageDrafts,
        this.unreadDiscussions,
        this.replyMessageMap,
        this.forwardMessageMap,
    ];

    constructor(
        public v3Message: V3MessagesService,
        private rpc: RPCService,
        private store: Store<{ messages: StoreDiscussionMessages; discussions: DiscussionMap }>
    ) {
        this.initService();
    }

    /** Loads all discussions */
    load(): Promise<DiscussionMap> {
        this.getStarredMessages();
        return new Promise((resolve, reject) => {
            this.syncing = true;
            this.rpc.request('messenger.load_discussions', []).subscribe({
                next: res => {
                    const sortedDiscussionsMap = this.discussionArrayToMap(res);
                    this.discussions.next(sortedDiscussionsMap);

                    this.syncing = false;

                    resolve(this.discussions.value);
                },
                error: error => {
                    this.syncing = false;
                    reject(error);
                },
            });
        });
    }

    /** Returns all changes in discussions after given timestamp */
    async sync(): Promise<{ discussions: DiscussionMap; removed: string[] }> {
        const timestamp = this.lastSynced.value || Date.now() - 1000 * 60 * 60 * 24 * 7; // Week from now if no timestamp
        const emptyCache = !Object.keys(this.discussions.value)?.length;

        if (emptyCache) {
            // If no discussions then load all discussions
            try {
                await this.load();
            } catch (error) {
                console.error('Failed to load discussions: ', error);
            }
        }

        this.getStarredMessages();

        return new Promise((resolve, reject) => {
            if (this.syncing) {
                // Do not make a request if already making one
                this.syncQueue = true;
                return void resolve({ discussions: {}, removed: [] });
            }

            this.syncing = true;
            this.rpc.request('v2.discussion.sync', [{ timestamp }]).subscribe({
                next: async (res: V2DiscussionSyncResponse) => {
                    const syncResponse: {
                        discussions: DiscussionMap;
                        removed: string[];
                    } = {
                        discussions: {},
                        removed: res.removed,
                    };

                    try {
                        await this.cacheDiscussionSync(res);
                    } catch (error) {
                        console.error('Something went wrong while trying to cache discussion sync', error);
                    }

                    if (!this.firstSync && !emptyCache) {
                        // If this is not first sync and cache is not empty, then return changes in discussions
                        syncResponse.discussions = this.discussionArrayToMap(res.discussions);
                    } else {
                        // If this is first sync or cache is empty, then return all discussions
                        this.firstSync = false;
                        syncResponse.discussions = this.discussions.value;
                    }

                    this.syncing = false;

                    if (this.syncQueue) {
                        // If there was a try to request while already making one, lets make that now
                        this.syncQueue = false;
                        this.store.dispatch(syncDiscussions());
                    }
                    resolve(syncResponse);
                },
                error: error => {
                    this.syncing = false;
                    if (this.syncQueue) {
                        // If there was a try to request while already making one, lets make that now
                        this.syncQueue = false;
                        this.store.dispatch(syncDiscussions());
                    }

                    reject(error);
                },
            });
        });
    }

    starMessage(messageId: string) {
        this.rpc.request('v3.discussion.message.star', [messageId]).subscribe({
            next: () => this.getStarredMessages(),
            error: error => console.error('Failed to star message: ', error),
        });
    }
    async getStarredMessages() {
        await this.rpc.request('v3.discussion.message.stars', []).subscribe({
            next: (data: any) => {
                this.starredMessages.next(data);
            },
        });
    }

    get(discussionId: string): Observable<Discussion> {
        return new Observable(observer => {
            if (!discussionId) {
                observer.error(new Error('No discussion id!'));
                return void observer.complete();
            }

            const cachedDiscussion = this.discussions.value[discussionId];

            if (cachedDiscussion) {
                observer.next(cachedDiscussion);
                return void observer.complete();
            }

            this.rpc.request('messenger.load_discussions', [discussionId]).subscribe({
                next: async res => {
                    observer.next(Array.isArray(res) ? res[0] : res);
                    const resAsArray = Array.isArray(res) ? res : res ? [res] : [];
                    await this.cacheDiscussions(resAsArray);
                    observer.complete();
                },
                error: error => {
                    observer.error(error);
                    observer.complete();
                },
            });
        });
    }

    /** Sets last seen value for given discussions */
    setLastSeen(discussionIds: string[]): Observable<string[]> {
        return this.rpc.request('v3.discussion.touch', [discussionIds]);
    }

    /** Finds or creates a discussion with provided userId */
    findUserDiscussion(userId: string): Observable<Discussion> {
        return this.rpc.request('messenger.find_user_discussion', [userId]);
    }

    setMessageDraftCache(discussionId: string, messageDraft: MessageDraft): Observable<MessageDraftMap> {
        this.messageDraftUpdated.next({ discussionId, messageDraft });
        return new Observable(observer => {
            if (!discussionId) {
                observer.next(this.messageDrafts.value);
                return void observer.complete();
            }

            const messageDraftMap = { ...this.messageDrafts.value };

            if (!messageDraft?.msg && !messageDraft?.files?.length && !messageDraft.replyTo) {
                delete messageDraftMap[discussionId];
            } else {
                messageDraftMap[discussionId] = { ...messageDraft };
            }

            this.messageDrafts.next(messageDraftMap);
            observer.next(messageDraftMap);
            observer.complete();
        });
    }

    // Load links from discussion messages
    links(discussionId: string): Observable<DiscussionLink[]> {
        return this.rpc.request('messenger.discussion_links', [discussionId]);
    }

    loadFiles(
        discussionId: string,
        opts?: { limit?: number; skip?: number; reverse?: boolean; sortBy?: 'created' | 'name' }
    ): Observable<DiscussionFileResponse> {
        return this.rpc.request('v3.discussion.files', [discussionId, opts ? opts : {}]);
    }

    invite(discussionId: string, userIds: string[]): Observable<Discussion> {
        return this.rpc.request('messenger.invite_users', [discussionId, userIds]);
    }

    mute(discussionId: string, duration: MuteDuration): Observable<boolean> {
        return this.rpc.request('v2.discussion.mute', [discussionId, duration]);
    }

    getPrivate(userId: string): Discussion[] {
        const discussions: Discussion[] = Object.values(this.discussions.value);
        return discussions.filter(discussion => discussion.private && discussion.participants.includes(userId));
    }

    saveSettings(updateData: any): Observable<void> {
        return this.rpc.request('messenger.save_discussion_settings', [updateData]);
    }

    leave(discussionId: string): Observable<void> {
        return this.rpc.request('messenger.leave_discussion', [discussionId]);
    }

    forward(messageToForward: Message, forwardedToDiscussionId: string, isReplyPrivately: boolean): void {
        // When forwarding to another discussion, this makes sure to close the reply draft container if it's open
        this.removeReply(forwardedToDiscussionId);
        // True or false depending on if we are forwarding or replying privately
        messageToForward.replyPrivately = isReplyPrivately;
        const forwardMessageMap = this.forwardMessageMap.value || {};
        forwardMessageMap[forwardedToDiscussionId] = messageToForward;
        this.forwardMessageMap.next(forwardMessageMap);
    }

    removeForward(discussionId: string): void {
        const forwardMessageMap = this.forwardMessageMap.value || {};
        delete forwardMessageMap[discussionId];
        this.forwardMessageMap.next(forwardMessageMap);
    }

    addReply(message: Message): void {
        // When replying, this makes sure to close the forwarding draft container if it's open
        this.removeForward(message.discussion);
        const replyMessageMap = this.replyMessageMap.value || {};
        Object.assign(replyMessageMap, { [message.discussion]: message });
        this.replyMessageMap.next(replyMessageMap);
    }

    removeReply(discussionId: string): void {
        const replyMessageMap = this.replyMessageMap.value || {};
        delete replyMessageMap[discussionId];
        this.replyMessageMap.next(replyMessageMap);
    }

    setTypingState(discussionId: string, typing: boolean): Observable<boolean> {
        if (!discussionId) {
            return of(false);
        }
        return this.rpc.request('messenger.set_discussion_typing_state', [discussionId, typing]);
    }

    getDiscussionType(discussion: Discussion): string {
        if (!discussion) {
            return '';
        }
        if (discussion.private) {
            return 'user';
        } else if (discussion.linked_activity) {
            return 'activity';
        } else if (discussion.linked_event) {
            return 'event';
        } else if (!discussion.linked_activity && !discussion.linked_event && !discussion.private) {
            return 'group';
        }
        return '';
    }

    private async cacheDiscussions(discussions: Discussion[], removedDiscussionIds?: string[]): Promise<void> {
        if (!discussions?.length && !removedDiscussionIds?.length) {
            // Don't do anything if the discussions was not updated
            return;
        }

        let cachedDiscussions = Object.values(this.discussions.value) || [];

        // Update existing discussions and remove existing ones from response
        cachedDiscussions = cachedDiscussions.map(discussion => {
            const updatedDiscussion = discussions?.find(({ _id }) => _id === discussion?._id);
            return updatedDiscussion ? updatedDiscussion : discussion;
        });

        const newDiscussions = discussions.filter(({ _id }) => !this.discussions.value[_id]);
        const combinedDiscussions = cachedDiscussions.concat(newDiscussions);
        const updatedDiscussionMap = this.discussionArrayToMap(combinedDiscussions);

        if (removedDiscussionIds?.length) {
            // Remove discussions if needed
            for (const discussionId of removedDiscussionIds) {
                delete updatedDiscussionMap[discussionId];
            }
        }

        this.discussions.next(updatedDiscussionMap);
        this.discussionsUpdated.next(discussions.map(({ _id }) => _id));
        this.discussionsRemoved.next(removedDiscussionIds);
    }

    private async cacheDiscussionSync(syncResponse: V2DiscussionSyncResponse): Promise<void> {
        this.lastSynced.next(syncResponse.checked);
        this.unreadDiscussions.next(syncResponse.unread_counts);
        await this.cacheDiscussions(syncResponse.discussions, syncResponse.removed);
        await this.cacheMessages(syncResponse.messages, syncResponse.removed);
    }

    private async cacheMessages(messages: Message[], removedDiscussionIds: string[]): Promise<void> {
        return new Promise(resolve => {
            if (!messages?.length && !removedDiscussionIds?.length) {
                // Don't do anything if the messages or discussions was not updated
                return void resolve();
            }
            const discussionMessageMap: { [discussionId: string]: Message[] } = {};

            // Create a discussion message map
            for (const message of messages) {
                if (!discussionMessageMap[message.discussion]?.length) {
                    discussionMessageMap[message.discussion] = [message];
                    continue;
                }

                discussionMessageMap[message.discussion]?.push(message);
            }

            if (!Object.values(discussionMessageMap)?.length && removedDiscussionIds?.length) {
                this.v3Message.cacheDiscussionMessages(removedDiscussionIds[0], { messages: [] }, removedDiscussionIds);
            }

            for (const discussionMessages of Object.values(discussionMessageMap)) {
                const args: V3DiscussionMessageResponse = {
                    messages: discussionMessages,
                };

                this.v3Message.cacheDiscussionMessages(discussionMessages[0].discussion, args, removedDiscussionIds);
            }

            resolve();
            this.updateStoreMessages(discussionMessageMap, removedDiscussionIds);
        });
    }

    private updateStoreMessages(updatedMessageMap: MessageMap, removedDiscussionIds?: string[]) {
        this.store.pipe(take(1)).subscribe({
            next: storeState => {
                const storeMessagesMap = storeState.messages || {};

                for (const storeDiscussionId in storeMessagesMap) {
                    if (!storeDiscussionId) {
                        continue;
                    }

                    if (removedDiscussionIds?.includes(storeDiscussionId)) {
                        this.store.dispatch(clearMessages({ discussionId: storeDiscussionId }));
                        continue;
                    }

                    if (!updatedMessageMap[storeDiscussionId]) {
                        continue;
                    }

                    const storeMessages = storeMessagesMap[storeDiscussionId].messages || [];
                    const newestStoreMessageTimestamp = storeMessages[0]?.created;
                    const storeReplyMessagesMap = {};

                    // Filter reply messages
                    const storeReplys = storeMessages?.filter(message => message.replyTo);
                    for (const message of storeReplys) {
                        storeReplyMessagesMap[message.replyTo] = message;
                    }

                    // Update existing messages
                    this.store.dispatch(getMessageSuccess({ messages: { [storeDiscussionId]: updatedMessageMap[storeDiscussionId] } }));

                    // Process new and reply messages
                    updatedMessageMap[storeDiscussionId].forEach(message => {
                        this.updateStoreReplyMessages(message._id, storeDiscussionId, storeReplyMessagesMap);
                        this.updateStoreNewMessages(newestStoreMessageTimestamp, storeDiscussionId, storeMessagesMap, message);
                    });
                }
            },
        });
    }

    private updateStoreReplyMessages(updatedMessageId: string, discussionId: string, replyMessagesMap: { [messageId: string]: Message }) {
        if (!replyMessagesMap[updatedMessageId]) {
            return;
        }

        this.v3Message
            .get({ messageId: replyMessagesMap[updatedMessageId]._id })
            .pipe(
                take(1),
                map(data => data.messages[0])
            )
            .subscribe({
                next: message => this.store.dispatch(getMessageSuccess({ messages: { [discussionId]: [message] } })),
            });
    }

    private updateStoreNewMessages(
        newestStoreMessageTimestamp: number,
        storeDiscussionId: string,
        storeMessagesMap: V3DiscussionMessageResponseMap,
        message: Message
    ) {
        if (!(message.created > newestStoreMessageTimestamp) || !storeMessagesMap[storeDiscussionId]?.newestLoaded) {
            return;
        }

        this.store.dispatch(
            loadNextMessagesSuccess({
                messages: {
                    [storeDiscussionId]: [message],
                },
                newestLoaded: true,
            })
        );
    }

    private discussionArrayToMap(discussions: Discussion[]): DiscussionMap {
        if (!discussions?.length) {
            return {};
        }

        const starredMessages = this.starredMessages.value;

        const discussionMap: DiscussionMap = {};
        for (const discussion of discussions) {
            if (!discussion) {
                continue;
            }

            if (discussion.stars) {
                starredMessages[discussion._id] = discussion.stars;
            }
            discussionMap[discussion._id] = discussion;
        }

        this.starredMessages.next(starredMessages);
        return discussionMap;
    }

    private async initService() {
        // Clearing syncQueue if browser was disconnected
        this.rpc.disconnectListener.subscribe({
            next: () => {
                this.syncQueue = false;
                this.syncing = false;
            },
        });

        // Subscribe for discussion changes and the update discussions
        this.rpc.subscribe('discussion.sync', () => {
            this.store.dispatch(syncDiscussions());
        });

        // TODO: This can be removed once discussion.sync informs that the user was kicked from the discussion
        this.rpc.subscribe('discussion.remove', () => {
            this.store.dispatch(syncDiscussions());
        });

        // Subscribe for discussion changes and the update discussions
        this.rpc.subscribe('discussion.typing', (res: DiscussionTypingResponse) => {
            const discussionId = res.discussion;
            const userId = res.uid;
            const typingState = res.typing;

            if (!discussionId || !userId) {
                return;
            }

            const currentlyTyping = this.typingUsers.value || {};
            currentlyTyping[discussionId] = currentlyTyping[discussionId] || [];

            if (typingState) {
                const index = currentlyTyping[discussionId]?.findIndex((typing) => typing.userId === userId);
                if (typeof index === 'number' && index >= 0) {
                    currentlyTyping[discussionId]![index]!.updated = Date.now();
                    this.typingUsers.next(currentlyTyping);
                    return;
                }

                currentlyTyping[discussionId]?.push({ userId, updated: Date.now() });
                this.typingUsers.next(currentlyTyping);
                return;
            }

            currentlyTyping[discussionId] = currentlyTyping[discussionId]?.filter(typing => typing.userId !== userId) || [];
            if (!currentlyTyping[discussionId]?.length) {
                delete currentlyTyping[discussionId];
            }

            this.typingUsers.next(currentlyTyping);
        });

        /** 5 seconds */
        const interval = 5000;
        setInterval(() => {
            const now = Date.now();
            const currentlyTyping = this.typingUsers.value;
            for (const discussionId in currentlyTyping) {
                if (!currentlyTyping[discussionId]) {
                    continue;
                }

                currentlyTyping[discussionId] = currentlyTyping[discussionId]?.filter(({ updated }) => (now - updated) < interval) || [];

                if (!currentlyTyping[discussionId]?.length) {
                    delete currentlyTyping[discussionId];
                }
            }

            this.typingUsers.next(currentlyTyping);
        }, interval);
    }
}

export interface V2DiscussionSyncResponse {
    starred: string[];
    discussions: Discussion[];
    messages: Message[];
    removed: string[];
    last_seen: DiscussionLastSeenMap;
    muted: string[];
    // eslint-disable-next-line @typescript-eslint/naming-convention
    unread_counts: DiscussionUnreadCountsMap;
    checked: number;
}

export interface DiscussionLastSeenMap {
    [userId: string]: number;
}

export interface DiscussionUnreadCountsMap {
    [discussionId: string]: number;
}

export interface DiscussionReplyMessageMap {
    [discussionId: string]: Message;
}

export interface DiscussionForwardMessageMap {
    [discussionId: string]: Message;
}

export interface DiscussionTypingUsers {
    [discussionId: string]: {
        /** User id of who is typing */
        userId: string;
        /** Last time typing state was updated */
        updated: number;
    }[];
}

export interface DiscussionTypingResponse {
    discussion: string;
    uid: string;
    typing: boolean;
}
